import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { commentValidation, tagValidation } from "../../utils/inputValidations";
import { useState } from "react";

export const RenderInput = ({ validation, value, setValue, methods }) => {
  const {
    name,
    label,
    type,
    id,
    validationRules,
    placeholder,
    multiline,
    icon,
  } = validation;
  const { register, formState, trigger } = methods;
  const inputErrors = formState.errors[name] || [];
  // const isInvalid = inputErrors.length > 0;

  const toUpperCase = (e) => {
    setValue(e.target.value.toUpperCase());
    trigger(name);
  };

  return (
    <div className="auction-form-input">
      <label htmlFor={name}>{label}</label>
      {multiline ? (
        <textarea
          type={type}
          id={id}
          name={name}
          placeholder={"Enter short description"}
          {...register(name, validationRules)}
        ></textarea>
      ) : (
        <div className="icon-input-contain">
          {icon && (
            <div className="iicd">
              {icon && <FontAwesomeIcon icon={icon} className="icon-input-c" />}
            </div>
          )}
          <input
            type={type}
            id={id}
            name={name}
            value={value}
            style={{ paddingLeft: `${icon ? "3rem" : ""}` }}
            placeholder={placeholder}
            // {...register(name, validationRules)}
            {...register(name, {
              ...validationRules,
              onChange: toUpperCase,
            })}
          />
        </div>
      )}
      {inputErrors.message && (
        <div className="inputErrors">{inputErrors?.message}</div>
      )}
    </div>
  );
};

const TagsCreationForm = ({ methods }) => {
  const [value, setValue] = useState("");
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div>
        {
          <RenderInput
            validation={tagValidation}
            value={value}
            setValue={setValue}
            methods={methods}
          />
        }
      </div>
      <div>
        <div>
          {<RenderInput validation={commentValidation} methods={methods} />}
        </div>
      </div>
    </form>
  );
};

export default TagsCreationForm;
