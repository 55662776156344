import ReactDOM from "react-dom";
import "../../styles/tagDetailsModalStyles.css";

const TagDetailModal = ({ tag, onClose }) => {
  return ReactDOM.createPortal(
    <div className="auctionItemDetailsModal">
      {/* <div className="a-btns">
        <div className="heading-aidm">
          <h2>Tag Details</h2>
          <button className="reject-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div> */}
      <div className="auctionItemDetailsModal-content">
        <div className="heading-aidm">
          <h2>Tag Details</h2>
          <button className="reject-button" onClick={onClose}>
            Close
          </button>
        </div>
        <div className="title-aidm">
          <h3>Title</h3>
          <p>{tag.name}</p>
        </div>
        <div className="title-aidm">
          <h3>Description</h3>
          <p>{tag.tagComment ? tag.tagComment : "No Description"}</p>
        </div>
      </div>
    </div>,
    document.querySelector("#tagDetailsModal")
  );
};

export default TagDetailModal;
