import axios from "axios";
import { db } from "../config/firebaseSetup";

import { addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

// get email From firebase and make it ready for user


// get email From firebase and make it ready for user
const fetchAndSendEmailTemplate = async (label, user, route) => {
  try {
    const emailsColRef = collection(db, "emailNotifications");
    const q = query(emailsColRef, where("label", "==", label));
    const userDocRef = doc(db, "users", user.uid);
    const querySnapshot = await getDocs(q);
    const userDocSnap = await getDoc(userDocRef);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (item) => {
        const emailTemplate = item.data();
        const currentDate = new Date();
        const loginDateTime = currentDate.toLocaleString();
        // Construct emailBody using placeholders in the email template
        const emailBody = constructEmailBody(
          emailTemplate.emailEnglish,
          user.email,
          "BID AND GO",
          loginDateTime,
          "BIDANDGO",
          "SUPPORT",
          "BIDS"
        );
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const notifications = userData.notifications || []; // If notifications field doesn't exist, create an empty array

          // Add the new notification to the beginning of the array (to display it first)
          const newNotification = {
            message: emailTemplate.userProfileMsg,
            timestamp: new Date(),
            read: false,
            id: uuidv4(),
          };
          notifications.unshift(newNotification);

          // Update the user document with the new notifications array
          await updateDoc(userDocRef, {
            notifications: notifications,
          });

          await updateAdminNotification(userData.uid, userData.email, newNotification);

          // Also send sms to user
          // TODO: SMS SENDING HERE
          if (userData.emailNotifications) {
            // Send email via backend
            try {
              await axios.post(route, {
                emailBody,
                emailTo: user.email,
                emailSubject: emailTemplate.subjectEnglish,
              });
            } catch (error) {
              console.log(error);
              return;
            }
          }
          if (userData.phoneNotifications && userData.phoneNumber) {
            fetchAndSendSMSTemplate(label, user, "https://stripe-server-production-3c2c.up.railway.app/sms-send", userData.phoneNumber);
          }
        }
      });
    } else {
      console.error(`No email template found with label '${ label }'`);
    }
  } catch (error) {
    console.error("Error fetching email template:", error);
  }
};


// Function to construct the email body with placeholders replaced
const constructEmailReasonBody = (
  emailTemplate,
  recipientName,
  senderName,
  appName,
  loginDateTime,
  companyName,
  reason
) => {
  // Replace placeholders in the email template
  let emailBody = emailTemplate
    .replace('{{recipientName}}', recipientName)
    .replace('{{senderName}}', senderName)
    .replace('{{appName}}', appName)
    .replace('{{loginDateTime}}', loginDateTime)
    .replace('{{companyName}}', companyName)
    .replace('{{rejectionReason}}', reason);

  return emailBody;
};

// get reject email From firebase  and make it ready for user with reason
export const fetchAndSendEmailReasonTemplate = async (label, user, route, reason) => {
  try {
    const emailsColRef = collection(db, "emailNotifications");
    const q = query(emailsColRef, where("label", "==", label));
    const userDocRef = doc(db, "users", user.uid);
    const querySnapshot = await getDocs(q);
    const userDocSnap = await getDoc(userDocRef);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (item) => {
        const emailTemplate = item.data();
        const currentDate = new Date();
        const loginDateTime = currentDate.toLocaleString();
        // Construct emailBody using placeholders in the email template
        const emailBody = constructEmailReasonBody(
          emailTemplate.emailEnglish,
          user.email,
          'BID AND GO',
          loginDateTime,
          'BIDANDGO',
          'SUPPORT',
          reason
        );
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const notifications = userData.notifications || []; // If notifications field doesn't exist, create an empty array

          // Add the new notification to the beginning of the array (to display it first)
          const newNotification = {
            message: emailTemplate.userProfileMsg,
            timestamp: new Date(),
            read: false,
            id: uuidv4(),
          };
          notifications.unshift(newNotification);

          // Update the user document with the new notifications array
          await updateDoc(userDocRef, {
            notifications: notifications,
          });

          await updateAdminNotification(userData.uid, userData.email, newNotification);

          // Also send sms to user
          // TODO: SMS SENDING HERE
          if (userData.emailNotifications) {
            // Send email via backend
            try {
              await axios.post(route, {
                emailBody,
                emailTo: user.email,
                emailSubject: emailTemplate.subjectEnglish,
              });
            } catch (error) {
              console.log(error);
              return;
            }
          }
          if (userData.phoneNotifications && userData.phoneNumber) {
            fetchAndSendSMSTemplate(label, user, "https://stripe-server-production-3c2c.up.railway.app/sms-send", userData.phoneNumber);
          }
        } else {
          console.log('error');
        }
      });
    } else {
      console.error(`No email template found with label '${ label }'`);
    }
  } catch (error) {
    console.error("Error fetching email template:", error);
  }
};

export default fetchAndSendEmailTemplate;


const updateAdminNotification = async (userId, userEmail, notificationMessage) => {
  try {
    const adminNotificationsColRef = collection(db, "adminNotifications");
    const timestamp = new Date();

    // Add a new document to the "adminNotifications" collection
    await addDoc(adminNotificationsColRef, {
      userId: userId,
      userEmail,
      notificationMessage,
      timestamp: timestamp,
    });
  } catch (error) {
    return;
  }
};

const constructEmailBody = (
  emailTemplate,
  recipientName,
  appName,
  loginDateTime,
  senderName,
  senderPosition,
  companyName
) => {
  // Define placeholders
  const placeholders = {
    "{{recipientName}}": recipientName,
    "{{appName}}": appName,
    "{{loginDateTime}}": loginDateTime,
    "{{senderName}}": senderName,
    "{{senderPosition}}": senderPosition,
    "{{companyName}}": companyName,
  };

  // Regular expression to match placeholders in the email template
  const placeholderRegex = new RegExp(
    Object.keys(placeholders).join("|"),
    "g"
  );

  // Replace placeholders with actual values
  const emailBody = emailTemplate.replace(placeholderRegex, (match) => {
    return placeholders[ match ];
  });

  return emailBody;
};



// get email From firebase and make it ready for user
const fetchAndSendSMSTemplate = async (label, user, route, number) => {
  try {
    const smsColRef = collection(db, "smsNotifications");
    const q = query(smsColRef, where("label", "==", label));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        const smsTemplate = doc.data();
        // Construct smsBody using placeholders in the sms template
        const smsBody = constructSMSBody(
          smsTemplate.smsEnglish,
          user.email,
        );
        // console.log(smsBody);
        // Send sks via backend
        try {
          await axios.post(route, {
            messageBody: smsBody,
            userNumber: number,
            // userNumber: '923410040209',
          });
        } catch (error) {
          console.log(error);
        }
      });
    } else {
      console.error(`No sms template found with label '${ label }'`);
    }
  } catch (error) {
    console.error("Error fetching sms template:", error);
  }
};

// Construct SMS body using placeholders
const constructSMSBody = (smsTemplate, recipientName) => {
  return smsTemplate.replace('{{recipientName}}', recipientName);
};


