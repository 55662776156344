import {
  faCheckCircle,
  faClose,
  faFolderPlus,
  faGavel,
  faLayerGroup,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import IconBtn from "../userAuctionsDashboard/IconBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Overlay from "../Overlay";
import Modal from "../Modal";
import { FormProvider, useForm } from "react-hook-form";
import { renderInput } from "../userAuctionsDashboard/AuctionForm";
import {
  categoryValidation,
  comissionValidation,
  subCategoryValidation,
} from "../../utils/inputValidations";
import Loading from "../Loader";
import { toast } from "react-toastify";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import MoreMenu from "./ActionMenu";
import "../../styles/catManagement.css";
import CategoriesDetails from "./CategoriesDetails";
import CategoriesCreationForm from "./CategoriesCreationForm";

const CategoriesManagement = () => {
  const [showCreateFormModal, setShowCreateFormModal] = useState(false);
  const [showCreateSubCatFormModal, setShowCreateSubCatFormModal] =
    useState(false);
  const [showCategoryDetailsModal, setShowCategoryDetailsModal] =
    useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);
  const [editingTagId, setEditingTagId] = useState(null);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState();
  const methods = useForm();
  const inputErrors = methods.formState.errors[categoryValidation.name] || [];
  const [adminData, setAdminData] = useState(null);
  let adminActions;
  if (adminData) {
    adminActions = adminData.categoriesActions;
  }

  const admin = auth.currentUser;
  const getUser = async () => {
    try {
      const userDocRef = doc(db, "admins", admin.uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        setAdminData(docSnap.data());
      } else {
        setAdminData(null);
      }
    } catch (error) {
      setAdminData(null);
    }
  };

  // const fetchCategories = async () => {
  //   try {
  //     const q = query(collection(db, "categories"), orderBy("created", "desc"));
  //     const querySnapshot = await getDocs(q);

  //     const data = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     // Use 'data' as needed (e.g., set it to state in a React component)
  //     setCategories(data);
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //     setCategories([]);
  //   }
  // };

  // useEffect(() => {
  //   fetchCategories();
  // }, []);

  const fetchCategories = () => {
    try {
      const categoriesCollection = collection(db, "categories");
      const q = query(categoriesCollection, orderBy("created", "desc"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(data);
      });

      return unsubscribe;
    } catch (error) {
      setCategories([]);
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = fetchCategories();
    return () => unsubscribe(); // Clean up the subscription when the component unmounts
  }, []);

  useEffect(() => {
    getUser();
  }, [admin]);

  if (!categories) {
    return <Loading />;
  }
  if (!adminData) {
    return <Loading />;
  }

  const createCategoryHandler = methods.handleSubmit(async (formData) => {
    // This function will be used to create tag in database
    // const user = auth.currentUser;
    setShowLoader(true);
    try {
      // Reference to the "Auction" collection
      const tagsColRef = collection(db, "categories");
      // console.log(formData);

      // Create a new document with the entire formData
      const docRef = await addDoc(tagsColRef, {
        created: serverTimestamp(),
        name: formData.categoryName,
        subcategories: [],
      });
      toast.success("Category Created");
      setShowCreateFormModal(false);
      methods.reset();
      // await fetchCategories();
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      setShowCreateFormModal(false);
      console.log(error);
      toast.error("An Error Occurred During Creating Your Category");
    }
  });

  const handleEdit = (cat) => {
    // This function just shows the edit form to the user
    setEditingTagId(cat.id);
    setIsEditing(true);
    methods.setValue(categoryValidation.name, cat.name);
    methods.setValue(comissionValidation.name, cat.categoryCommission);
    // console.log(cat.id);
  };

  const submitEditHandler = methods.handleSubmit(async (formData) => {
    // This function will be used to create the category in the database

    const catName = formData.categoryName;
    const categoryCommission = formData.categoryCommission;

    if (!catName) {
      // Handle empty or undefined catName
      toast.error("Category name cannot be empty");
      return;
    }
    setShowLoader(true);
    try {
      const catDocRef = doc(db, "categories", editingTagId);
      await updateDoc(catDocRef, { name: catName, categoryCommission });

      toast.success("Category updated successfully!");
      // await fetchCategories();
      setIsEditing(false);
      setShowLoader(false);
      methods.reset();
    } catch (error) {
      setIsEditing(false);
      setShowLoader(false);
      toast.error("Error updating Category");
    }
  });

  const handleDelete = (cat) => {
    // This function just show the modal to the user
    setShowDeleteTagModal(true);
    setCategory(cat);
  };

  const deleteTagHandler = async () => {
    // This function will be used to deleted the category
    setShowLoader(true);
    try {
      if (category) {
        const auctionDocRef = doc(db, "categories", category.id);
        await deleteDoc(auctionDocRef);
        toast.success("Category successfully deleted!");
        setShowDeleteTagModal(false);
        // await fetchCategories();
        if (showCategoryDetailsModal) {
          setShowCategoryDetailsModal(false);
        }
        setShowLoader(false);
      }
    } catch (error) {
      toast.error("Error deleting Category");
      setShowDeleteTagModal(false);
      setShowLoader(false);
    }
  };

  const handleSubCategory = (cat) => {
    // This function just show the modal to the user
    setShowCreateSubCatFormModal(true);
    setCategory(cat);
  };

  const createSubCategoryHandler = methods.handleSubmit(async (formData) => {
    // This function will be used to create subcategory in the database
    const subcategoryName = formData.subCategoryName;
    setShowLoader(true);

    try {
      const categoryRef = doc(db, "categories", category.id);
      const categorySnapshot = await getDoc(categoryRef);
      const subcategoryId = uuidv4();

      if (categorySnapshot.exists()) {
        const categoryData = categorySnapshot.data();
        const updatedSubcategories = [
          ...(categoryData.subcategories || []),
          { name: subcategoryName, id: subcategoryId },
        ];
        await updateDoc(categoryRef, { subcategories: updatedSubcategories });

        toast.success(`Added subcategory to ${category.name}`);
        // await fetchCategories();
        setShowCreateSubCatFormModal(false);
        methods.reset();
        setShowLoader(false);
      }
    } catch (error) {
      toast.success(`Error adding subcategory to ${category.name}`);
      setShowCreateSubCatFormModal(false);
      setShowLoader(false);
    }
  });

  const actionMenuCategories = [
    adminActions.canEdit && {
      onClick: handleEdit,
      icon: faPen,
      color: "#80ed99",
      text: "Edit",
    },
    adminActions.canCreate && {
      onClick: handleSubCategory,
      icon: faFolderPlus,
      color: "#57cc99",
      text: "Subcategory",
    },
    adminActions.canDelete && {
      onClick: handleDelete,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Delete",
    },
  ].filter(Boolean);

  return (
    <div>
      {showLoader && <Loading />}
      <h2 className="headi">Categories Management</h2>
      <div className="create-new-auction" style={{ marginLeft: "10px" }}>
        {adminActions.canCreate && (
          <IconBtn
            onClick={() => {
              // setActiveIcon(faGavel);
              setShowCreateFormModal(true);
            }}
            iconName={faGavel}
            // activeIcon={activeIcon}
            activeIcon={""}
          >
            <div
              className="icon-div-con"
              style={{ backgroundColor: "#e2cfea" }}
            >
              <FontAwesomeIcon icon={faLayerGroup} color="purple" />
            </div>
            <span>Create</span>
          </IconBtn>
        )}
      </div>
      <ul className="tagsList-tagMa">
        {categories.length ? (
          categories.map((category, index) => (
            // <li className="li">
            <div className="div" key={category.id}>
              {isEditing && editingTagId === category.id ? (
                <FormProvider {...methods}>
                  <form
                    className="tagFlexCont tagFlexContCat"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <span className="a">current value: {category.name}</span>
                    <div className="flex">
                      <input
                        type={categoryValidation.type}
                        id={categoryValidation.id}
                        name={categoryValidation.name}
                        // defaultValue={tag.name}
                        placeholder={`Edit Category`}
                        {...methods.register(
                          categoryValidation.name,
                          categoryValidation.validationRules
                        )}
                      />
                      {inputErrors && (
                        <div className="ier">{inputErrors?.message}</div>
                      )}

                      <span className="a">
                        current commission: {category.categoryCommission}
                      </span>
                      <div className="flex">
                        <input
                          type={comissionValidation.type}
                          id={comissionValidation.id}
                          name={comissionValidation.name}
                          // defaultValue={tag.name}
                          placeholder={`Edit Commission`}
                          {...methods.register(
                            comissionValidation.name,
                            comissionValidation.validationRules
                          )}
                        />
                        {inputErrors && (
                          <div className="ier">{inputErrors?.message}</div>
                        )}
                      </div>

                      <div className="fl">
                        <button className="s" onClick={submitEditHandler}>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            color="#57cc99"
                          />
                          Save
                        </button>
                        <button
                          className="c"
                          onClick={() => setIsEditing(false)}
                        >
                          <FontAwesomeIcon icon={faClose} color="#ef233c" />
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              ) : (
                <li
                  className="li"
                  onClick={() => {
                    setCategory(category);
                    setShowCategoryDetailsModal(true);
                  }}
                >
                  <h2>{category.name}</h2>
                  <span
                    className={`gItem aspn ${category.name.toLowerCase()}Status`}
                  >
                    {/* {tag.status} */}
                  </span>
                  <span className="gItem">
                    <MoreMenu
                      actionMenu={actionMenuCategories}
                      tag={category}
                    />
                  </span>
                </li>
              )}
            </div>
            // </li>
          ))
        ) : (
          <h2>No Items Yet</h2>
        )}
      </ul>
      {/* <div className="main-conta">
        {categories.map((category) => (
          <div key={category.id} className="cat-flex-conta">
            <h2>{category.name}</h2>
            <MoreMenu actionMenu={actionMenuCategories} />
          </div>
        ))}
      </div> */}
      {showCreateFormModal && (
        <>
          <Overlay onClose={() => setShowCreateFormModal(false)} />
          <Modal
            onCancel={() => {
              setShowCreateFormModal(false);
              methods.setValue(categoryValidation.name, "");
            }}
            successText={"Create Category"}
            cancelText={"Cancel"}
            onAgree={createCategoryHandler}
          >
            <h2>Create Category</h2>
            <FormProvider {...methods}>
              <CategoriesCreationForm
                methods={methods}
                validation={categoryValidation}
              />
              {/* {renderInput(categoryValidation, methods)} */}
              {/* {renderInput(comissionValidation, methods)} */}
            </FormProvider>
          </Modal>
        </>
      )}

      {showCreateSubCatFormModal && (
        <>
          <Overlay onClose={() => setShowCreateFormModal(false)} />
          <Modal
            onCancel={() => {
              setShowCreateSubCatFormModal(false);
              // methods.setValue(subCategoryValidation.name, "");
            }}
            successText={"Create Sub Category"}
            cancelText={"Cancel"}
            // onAgree={() => {}}
            onAgree={createSubCategoryHandler}
          >
            <h2>Create SubCategory</h2>
            <FormProvider {...methods}>
              <CategoriesCreationForm
                methods={methods}
                validation={subCategoryValidation}
              />
              {/* {renderInput(subCategoryValidation, methods)} */}
            </FormProvider>
          </Modal>
        </>
      )}

      {showDeleteTagModal && (
        <>
          <Overlay onClose={() => setShowDeleteTagModal(false)} />
          <Modal
            onCancel={() => {
              setShowDeleteTagModal(false);
            }}
            successText={"Delete Category"}
            cancelText={"Cancel"}
            onAgree={deleteTagHandler}
          >
            <h2>Delete Category</h2>
            <p>
              Are you sure you want to delete{" "}
              <span style={{ fontWeight: "bold" }}>{category.name}</span>{" "}
              Category. All it's subcategories will also be deleted
            </p>
          </Modal>
        </>
      )}
      {showCategoryDetailsModal && (
        <CategoriesDetails
          categoryId={category.id}
          categories={categories}
          deleteCategory={handleDelete}
          createSubCategory={handleSubCategory}
          onClose={() => setShowCategoryDetailsModal(false)}
          setShowLoader={setShowLoader}
          // fetchCategories={fetchCategories}
          adminActions={adminActions}
        />
      )}
    </div>
  );
};

export default CategoriesManagement;
