import ReactDOM from "react-dom";
import "../../styles/userDetailsModalStyles.css";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebaseSetup";
import { useEffect, useState } from "react";
import Loading from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import slugify from "slugify";

const UserDetailsModal = ({ user, onClose, btns, auctions }) => {
  const [useBids, setUserBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showOverviewTab, setOverViewTab] = useState(true);
  const [showbidderTab, setBidderTab] = useState(false);
  const [showBidderPaymentTab, setShowBidderPaymentTab] = useState(false);
  const [showItemsTab, setShowItemsTab] = useState(false);
  const [showUserFormTab, setShowUserFormTab] = useState(false);

  const fetchUserBids = async () => {
    try {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const myBids = userDoc.data().myBids || [];
        const auctionsCollectionRef = collection(db, "Auction");
        const userBidsDetails = [];

        for (const bidId of myBids) {
          const bidDocRef = doc(auctionsCollectionRef, bidId);
          const bidSnapshot = await getDoc(bidDocRef);

          if (bidSnapshot.exists()) {
            userBidsDetails.push({
              id: bidSnapshot.id,
              ...bidSnapshot.data(),
            });
          }
        }

        return userBidsDetails;
      }

      return [];
    } catch (error) {
      console.error("Error fetching user bids:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchUserBidsData = async () => {
      const userBidsData = await fetchUserBids(user.uid);
      setUserBids(userBidsData);
      setLoading(false);

      // console.log(userBidsData);
    };

    fetchUserBidsData();
  }, []);

  return ReactDOM.createPortal(
    <div
      style={{ backgroundColor: "#081225" }}
      className="auctionItemDetailsModal"
    >
      {loading && <Loading />}
      <div className="details-container">
        <div className="details-nav-container">
          <div className="icon-container">
            <FontAwesomeIcon icon={faUser} /> <h2>User</h2>
          </div>
          <div className="tabs-container">
            <button
              onClick={() => {
                setOverViewTab(true);
                setBidderTab(false);
                setShowItemsTab(false);
                setShowUserFormTab(false);
                setShowBidderPaymentTab(false);
              }}
              className={`${showOverviewTab && "active-btn"}`}
            >
              Overview
            </button>
            <button
              className={`${showbidderTab && "active-btn"}`}
              onClick={() => {
                setOverViewTab(false);
                setBidderTab(true);
                setShowItemsTab(false);
                setShowUserFormTab(false);
                setShowBidderPaymentTab(false);
              }}
            >
              Bids
            </button>
            <button
              className={`${showBidderPaymentTab && "active-btn"}`}
              onClick={() => {
                setOverViewTab(false);
                setBidderTab(false);
                setShowItemsTab(false);
                setShowUserFormTab(false);
                setShowBidderPaymentTab(true);
              }}
            >
              Payment Info
            </button>
            {user.status.toLowerCase() === "seller".toLowerCase() && (
              <button
                className={`${showItemsTab && "active-btn"}`}
                onClick={() => {
                  setOverViewTab(false);
                  setBidderTab(false);
                  setShowItemsTab(true);
                  setShowUserFormTab(false);
                  setShowBidderPaymentTab(false);
                }}
              >
                Items
              </button>
            )}
            {user.userForm && (
              <button
                className={`${showUserFormTab && "active-btn"}`}
                onClick={() => {
                  setOverViewTab(false);
                  setBidderTab(false);
                  setShowItemsTab(false);
                  setShowUserFormTab(true);
                  setShowBidderPaymentTab(false);
                }}
              >
                User Form
              </button>
            )}
          </div>
          <button className="close-button" onClick={onClose}>
            <FontAwesomeIcon icon={faClose} />
          </button>
        </div>
        {showOverviewTab && (
          <div className="overview-container">
            <h2>Overview</h2>
            <div className="user-overview">
              <div>
                <h3>Name</h3>
                <p>{user.realName || "No Real Name"}</p>
              </div>
              <div className="">
                <h3>User Type</h3>
                <p>{user.status.toUpperCase()}</p>
              </div>
              <div className="">
                <h3>Email</h3>
                <p>{user.email || "No Email"}</p>
              </div>
              {user.shippingAddressSameAsBilling ? (
                <div className="user-span">
                  <h3 style={{ color: "white" }}>
                    {console.log(user.shippingAddressSameAsBilling)}
                    Shipping address same as billing address
                  </h3>
                </div>
              ) : (
                <div className="user-span">
                  <h3 style={{ color: "white" }}>Shipping Address</h3>
                  <section className="address">
                    <div>
                      <h3>Name</h3>
                      <p>{user["shippingAddressSurname"] || "No name"}</p>
                    </div>
                    <div>
                      <h3>Surname</h3>
                      <p>{user["shippingAddressName"] || "No Surname"}</p>
                    </div>
                    <div>
                      <h3>Address</h3>
                      <p>{user.addressShipping || "No Address"}</p>
                    </div>
                    <div>
                      <h3>Apartment</h3>
                      <p>
                        {user.apartmentShipping || "No Shipping Apartment "}
                      </p>
                    </div>

                    <div>
                      <h3>City</h3>
                      <p>{user.cityShipping || "No Shipping City"}</p>
                    </div>
                    <div>
                      <h3>Country</h3>
                      <p>{user.countryShipping || "No Shipping Country"}</p>
                    </div>
                    <div>
                      <h3>State</h3>
                      <p>
                        {user["state-provinceShipping"] || "No Shipping State"}
                      </p>
                    </div>
                    <div>
                      <h3>Postal Code</h3>
                      <p>
                        {user["postal-codeShipping"] ||
                          "No Shipping Postal Code"}
                      </p>
                    </div>
                  </section>
                </div>
              )}
              <div className="user-span">
                <h3 style={{ color: "white" }}>
                  Billing information and address
                </h3>
                <section className="address">
                  <div>
                    <h3>Name</h3>
                    <p>{user.billingInfoName || "No Name"}</p>
                  </div>
                  <div>
                    <h3>Surname</h3>
                    <p>{user.billingInfoSurname || "No Surname"}</p>
                  </div>
                  <div>
                    <h3>Address</h3>
                    <p>{user.addressBillTo || "No Address"}</p>
                  </div>
                  <div>
                    <h3>Address Bill To</h3>
                    <p>{user.billToAddress || "No Address Bill To"}</p>
                  </div>
                  <div>
                    <h3>City</h3>
                    <p>{user.cityBillTo || "No Bill To City"}</p>
                  </div>
                  <div>
                    <h3>Country</h3>
                    <p>{user.countryBillTo || "No Bill To Country"}</p>
                  </div>
                  <div>
                    <h3>State</h3>
                    <p>
                      {user["state-provinceBillTo"] || "No Bill To Province"}
                    </p>
                  </div>
                  <div>
                    <h3>Postal Code</h3>
                    <p>
                      {user["postal-codeBillTo"] || "No Bill To Postal Code"}
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )}
        {showbidderTab && (
          <div className="overview-container">
            <h2>User Bids</h2>
            {!user.myBids ? (
              <p>No Bids Yet</p>
            ) : (
              <div className="user-actions-div">
                {useBids.map((auction) => (
                  <AuctionCard
                    key={auction.id}
                    auction={auction}
                    userbids={useBids}
                    user={user}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {user.status.toLowerCase() === "seller".toLowerCase() &&
          showItemsTab && (
            <div className="overview-container">
              <h2>User Created Auctions</h2>
              <div className="user-actions-div">
                {auctions.map((auction) => {
                  if (auction.uid === user.uid) {
                    return <AuctionCard key={auction.id} auction={auction} />;
                  }
                  return null;
                })}
              </div>
            </div>
          )}
        {showUserFormTab && (
          <div className="overview-container">
            <h2>User Request Form</h2>
            {user.userForm[0] &&
              Object.keys(user.userForm[0]).map((question, index) => (
                <div className="user-overview" key={index}>
                  <div className="user-span">
                    <section className="address-2">
                      <div className="">
                        <h2>Q: {question}</h2>
                        <p>Ans: {user.userForm[0][question]}</p>
                      </div>
                    </section>
                  </div>
                </div>
              ))}
          </div>
        )}
        {showBidderPaymentTab && (
          <div className="overview-container">
            <h2>Payment Info</h2>
            <div>
              {user.userPaymentRecords && user.userPaymentRecords.length ? (
                <div className="paymentContainer">
                  {user.userPaymentRecords.map((paymentInfo, index) => {
                    const auction = useBids.find(
                      (auction) => auction.id === paymentInfo.auctionId
                    );
                    const content = auction ? (
                      <div className="payment-item">
                        <img
                          src={auction.images[0].fileimage}
                          alt="auctionItem"
                        />
                        <div>
                          <h3>Auction Title</h3>
                          <p>{auction.auctionTitle}</p>
                        </div>
                        <div>
                          <h3>Email</h3>
                          <p>{paymentInfo.userEmail}</p>
                        </div>
                        <div>
                          <h3>Payment Status</h3>
                          <p>{paymentInfo.status}</p>
                        </div>
                      </div>
                    ) : null;

                    return <div key={index}>{content}</div>;
                  })}
                </div>
              ) : (
                <h2>No Payment History</h2>
              )}
            </div>
          </div>
        )}
        {btns}
      </div>
    </div>,
    document.querySelector("#userDetailsModal")
  );
};

export default UserDetailsModal;

const AuctionCard = ({ auction, userbids, user }) => {
  const navigate = useNavigate();
  let highestBidAmount;
  if (user) {
    const userBids = auction.bidHistory.filter(
      (bid) => bid.userId === user.uid
    );
    highestBidAmount =
      userBids.length > 0
        ? Math.max(...userBids.map((bid) => bid.bidAmount))
        : null;
  }

  const title = auction.auctionTitleItalian;
  // Replace spaces and special characters with hyphens (-)
  const formattedTitle = title.replace(/\s+/g, "-").replace(/\W/g, "-");

  // Generate a slug from the formatted title, replacing special characters with hyphens
  const slug = slugify(formattedTitle, {
    lower: true,
    remove: /[$*_+~.()'"!\-:@]/g,
  });
  const encodedTitle = encodeURIComponent(slug);
  const encodedId = encodeURIComponent(auction.id);
  // navigate(`/product/it/${encodedTitle}/${encodedId}`);

  const link = `https://join.bidandgo.it/product/it/${encodedTitle}/${encodedId}`;

  return (
    <div className="product-card">
      <div className="product-tumb">
        <img src={auction.images[0]?.fileimage} alt="" />
      </div>
      <div className="product-details">
        <h4>{auction.auctionTitle}</h4>
        <p>{auction.descriptionEnglish}</p>
        {user && <p>User bid ${highestBidAmount}</p>}
        <div className="product-bottom-details">
          <div className="product-price">
            <span>${auction.auctionReservePrice}</span>
          </div>
          <Link to={link} target="_blank" className="link">
            Visit
          </Link>
        </div>
      </div>
    </div>
  );
};
