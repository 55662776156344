import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../config/firebaseSetup";
import Loading from "../Loader";
import "../../styles/notificationsStyle.css";

const NotificationsLogs = () => {
  const [notificationsFromFirebase, setNotificationsFromFirebase] = useState(
    []
  );

  const fetchNotifications = () => {
    try {
      const notificationsCollection = collection(db, "adminNotifications");
      const unsubscribe = onSnapshot(
        query(notificationsCollection, orderBy("timestamp", "desc")),
        (querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setNotificationsFromFirebase(data);
        }
      );

      return unsubscribe;
    } catch (error) {
      setNotificationsFromFirebase([]);
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = fetchNotifications();
    return () => unsubscribe();
  }, []);

  if (!notificationsFromFirebase.length) {
    return <Loading />;
  }

  const notificationLogs = notificationsFromFirebase.map(
    (notifications, index) => (
      <div
        key={notifications.notificationMessage.id + " " + index}
        className="notifications"
      >
        <h2>User Email: {notifications.userEmail}</h2>
        <p>Notification: {notifications.notificationMessage.message}</p>
        <p>Time: {formatTimestamp(notifications.timestamp)}</p>
      </div>
    )
  );

  return (
    <div>
      <h2 className="headi">Notifications Logs</h2>
      <div className="notifications-container">{notificationLogs}</div>
    </div>
  );
};

export default NotificationsLogs;

// Function to format the timestamp
const formatTimestamp = (timestamp) => {
  if (timestamp) {
    const date = timestamp.toDate();
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
    const formattedDate = date.toLocaleDateString("en-GB", dateOptions);
    return `${formattedTime} ${formattedDate}`;
  } else {
    return "";
  }
};
