import React from "react";
import { Controller } from "react-hook-form";
import Button from "../../Button";
import "../../../styles/subAdminStyles.css";

const RenderInput = ({
  control,
  title,
  required,
  name,
  type = "checkbox",
  errors,
  defaultValue,
}) => {
  // console.log(name + " " + defaultValue);
  const emailValidation = {
    required: "Email is required",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "Invalid email address",
    },
  };

  const passwordValidation = {
    required: "Password is required",
    minLength: {
      value: 6,
      message: "Password must be at least 6 characters long",
    },
  };

  const validationRules =
    type === "email"
      ? emailValidation
      : type === "password"
      ? passwordValidation
      : {};

  const isCheckbox = type === "checkbox";

  return (
    <>
      <label className={` ${type === "checkbox" && "checkBox"}`}>
        <Controller
          name={name}
          control={control}
          rules={validationRules}
          // rules={{ required: required }}
          defaultValue={defaultValue || ""}
          render={({ field }) =>
            isCheckbox ? (
              <input
                type="checkbox"
                {...field}
                checked={field.value ? true : false}
              />
            ) : (
              <input className="adminInput" type={type} {...field} />
            )
          }
        />
        {type === "checkbox" && title}
      </label>
      {errors && errors[name] ? (
        <p className="input-field-error">{errors[name].message}</p>
      ) : null}
      {/* {errors && errors.password ? <p>{errors.password.message}</p> : null} */}
      {/* {errors && errors.email ? <p>{errors.email.message}</p> : null} */}
    </>
  );
};

const SubAdminForm = ({
  initialFormData,
  control,
  onSubmit,
  errors,
  isEditing,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <form onSubmit={(e) => e.preventDefault()} className="subAdminForm">
        {isEditing ? null : (
          <div>
            <h3>Email</h3>
            <RenderInput
              name="email"
              title="Email"
              control={control}
              type="email"
              required={"Email is required"}
              errors={errors}
              // defaultValue={initialFormData.email}
            />
          </div>
        )}

        {isEditing ? null : (
          <div>
            <h3>Password</h3>
            <RenderInput
              name="password"
              title="Password"
              control={control}
              type="password"
              required={"Password is required"}
              errors={errors}
              // defaultValue={initialFormData.password}
            />
          </div>
        )}
        <div>
          <h3>Auctions Actions</h3>
          {Object.entries(initialFormData.auctionsActions).map(
            ([key, value]) => (
              <RenderInput
                key={key}
                name={`auctionsActions.${key}`}
                title={`Sub admin ${key
                  .split("can")
                  .join("can ")
                  .toLowerCase()} auctions`}
                control={control}
                required={false}
                defaultValue={initialFormData.auctionsActions[key]}
              />
            )
          )}
        </div>
        <div>
          <h3>Users Actions</h3>
          {Object.entries(initialFormData.usersActions).map(([key, value]) => (
            <RenderInput
              key={key}
              name={`usersActions.${key}`}
              title={`Sub admin ${key
                .split("can")
                .join("can ")
                .toLowerCase()} users`}
              control={control}
              required={false}
              defaultValue={initialFormData.usersActions[key]}
            />
          ))}
        </div>
        <div>
          <h3>Tags Actions</h3>
          {Object.entries(initialFormData.tagsActions).map(([key, value]) => (
            <RenderInput
              key={key}
              name={`tagsActions.${key}`}
              title={`Sub admin ${key
                .split("can")
                .join("can ")
                .toLowerCase()} tags`}
              control={control}
              required={false}
              defaultValue={initialFormData.tagsActions[key]}
            />
          ))}
        </div>
        <div>
          <h3>Categories Actions</h3>
          {Object.entries(initialFormData.categoriesActions).map(
            ([key, value]) => (
              <RenderInput
                key={key}
                name={`categoriesActions.${key}`}
                title={`Sub admin ${key
                  .split("can")
                  .join("can ")
                  .toLowerCase()} categories`}
                control={control}
                required={false}
                defaultValue={initialFormData.categoriesActions[key]}
              />
            )
          )}
        </div>
        <div>
          <h3>Sub Admin Actions</h3>
          {Object.entries(initialFormData.adminActions).map(([key, value]) => (
            <RenderInput
              key={key}
              name={`adminActions.${key}`}
              title={`Sub admin ${key
                .split("can")
                .join("can ")
                .toLowerCase()} sub admins`}
              control={control}
              required={false}
              defaultValue={initialFormData.adminActions[key]}
            />
          ))}
        </div>
      </form>
      <Button className={"btn-submit"} onClick={onSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default SubAdminForm;
