import { faComment, faEuro, faTag } from "@fortawesome/free-solid-svg-icons";

const usernameValidation = {
  required: {
    value: true,
    message: "username is required",
  },
};

const addressValidation = {
  required: {
    value: true,
    message: "address is required",
  },
};

const cityValidation = {
  required: {
    value: true,
    message: "address is required",
  },
};


const provinceValidation = {
  required: {
    value: true,
    message: "address is required",
  },
};

const emailValidation = {
  required: {
    value: true,
    message: "email is required",
  },
  pattern: {
    value:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "not valid email",
  },
};

const passwordValidation = {
  required: {
    value: true,
    message: "password required",
  },
  minLength: {
    value: 6,
    message: "min 6 characters",
  },
};

export const otpValidation = {
  required: {
    value: true,
    message: "required",
  },
  minLength: {
    value: 6,
    message: "min 6 characters",
  },
  maxLength: {
    value: 6,
    message: "max 6 characters",
  },
};

const currencyValidation = {
  required: {
    value: true,
    message: "currency is required",
  },
  min: {
    value: 10,
    message: "Minimum value is $10",
  },
  pattern: {
    value: /^\d+(\.\d+)?$/,
    message: "Please enter a valid positive number greater than or equal to 10",
  },
  // pattern: {
  //   value: /^(?!-)\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/,
  //   message: "Negative values not allowed",
  // },
};

const postalCodeValidation = {
  required: {
    value: true,
    message: "Postal code is required",
  },
  pattern: {
    // value: /^\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/,
    value: /^[\w\d\s-]+$/,
    message: "Invalid postal code",
  },
};

const creditCardValidation = {
  validate: {
    validCard: (cardNumber) => {
      const sanitizedCardNumber = cardNumber.replace(/[-\s]/g, "");

      if (/^\d{13,19}$/.test(sanitizedCardNumber)) {
        return true;
      }

      return "card number must be between 13, 19 digits";
    },
  },
};

const expirationDateValidation = {
  validate: {
    validExpirationDate: (expirationDate) => {
      const expirationDatePattern = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;

      if (!expirationDatePattern.test(expirationDate)) {
        return "Invalid expiration date. Please use the format MM/YY.";
      }

      const [ month, year ] = expirationDate.split('/');

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() % 100;
      const expirationYear = parseInt(year, 10);

      if (expirationYear < currentYear) {
        return "The card has expired.";
      } else if (expirationYear === currentYear && parseInt(month, 10) < currentDate.getMonth() + 1) {
        return "The card has expired.";
      }

      return true;
    },
  },
  required: {
    value: true,
    message: "expiration date is required",
  },
};

const cvvValidation = {
  validate: {
    validCvv: (cvv) => {
      const cvvPattern = /^\d{3,4}$/;

      if (!cvvPattern.test(cvv)) {
        return "Invalid CVV/CVC. Please enter 3 or 4 digits.";
      }

      return true;
    },
  },
  required: {
    value: true,
    message: "CVV/CVC is required"
  }
};


export const titleInput = {
  name: "auctionTitle",
  label: "Auction Title",
  type: "text",
  validationRules: {
    required: {
      value: true,
      message: "Title is required",
    },
    id: "auctionTitle",
    placeholder: "My Item Name",
  },
};


export const titleInputItalian = {
  name: "auctionTitleItalian",
  label: "Auction Title Italian",
  type: "text",
  validationRules: {
    required: {
      value: true,
      message: "Title is required",
    },
    id: "auctionTitleItalian",
    placeholder: "My Item Name",
  },
};

export const subjectInput = {
  name: "reportSubject",
  label: "Subject",
  type: "text",
  validationRules: {
    required: {
      value: true,
      message: "Subject is required",
    },
    id: "reportSubject",
    placeholder: "Subject",
  },
};

export const descriptionEnglish = {
  label: "Description English",
  type: "text",
  id: "description-english",
  name: "descriptionEnglish",
  placeholder: "Write Description in English",
  validationRules: {
    required: {
      value: true,
      message: "Description english is required",
    },
  },
  multiline: true,
};
export const reportDescription = {
  label: "Report Description",
  type: "text",
  id: "reportDescription",
  name: "reportDescription",
  placeholder: "Write Description",
  validationRules: {
    required: {
      value: true,
      message: "Report Description Required",
    },
  },
  multiline: true,
};

export const descriptionItalian = {
  label: "Description Italian",
  type: "text",
  id: "description-italian",
  name: "descriptionItalian",
  placeholder: "Write Description in Italian",
  validationRules: {
    required: {
      value: true,
      message: "Description italian is required",
    },
  },
  multiline: true,
};

export const reservePrice = {
  type: "number",
  label: "Auction Reserve Price",
  id: "auctionReservePrice",
  name: "auctionReservePrice",
  placeholder: "100",
  icon: faEuro,
  validationRules: {
    required: "Auction Reserve Price is required",
    validate: {
      isNumber: (value) => !isNaN(value) || "Please enter a valid number",
      minValue: (value) =>
        value >= 0 || "Auction Reserve Price cannot be negative",
    },
  },
};


export const startingPrice = {
  type: "number",
  label: "Auction Starting Price",
  id: "auctionStartingPrice",
  name: "auctionStartingPrice",
  placeholder: "100",
  icon: faEuro,
  validationRules: {
    required: "Auction Starting Price is required",
    validate: {
      isNumber: (value) => !isNaN(value) || "Please enter a valid number",
      minValue: (value) =>
        value >= 0 || "Auction Starting Price cannot be negative",
    },
  },
};



export const commentValidation = {
  type: "text",
  label: "Short Description",
  id: "commnetTag",
  multiline: true,
  name: "commentTag",
  placeholder: "Please provide a brief description or any additional details about the tag you'd like to request.",
  icon: faComment,
  validationRules: {
    required: "Description is required",
    maxLength: {
      value: 300,
      message: "Comment cannot exceed 300 characters",
    },
  },
};


export const categoryValidation = {
  type: "text",
  label: "Category",
  id: "categoryInput",
  name: "categoryName",
  placeholder: "Enter Category",
  icon: faTag,
  validationRules: {
    required: "Category is required",
    minLength: {
      value: 3,
      message: "Category must be at least 3 characters long",
    },
    maxLength: {
      value: 30,
      message: "Category cannot exceed 30 characters",
    },
    pattern: {
      value: /^[A-Za-z0-9\s]+$/,
      message: "Category can only contain letters, numbers, and spaces",
    },
  },
};

export const comissionValidation = {
  type: "number",
  label: "Category Commission",
  id: "commissionInput",
  name: "categoryCommission",
  placeholder: "Enter Commission",
  icon: faTag,
  validationRules: {
    required: "Commission is required",
    min: {
      value: 0,
      message: "Commission must be a positive value",
    },
  },
};



export const userComissionValidation = {
  type: "number",
  label: "User Commission",
  id: "commissionInput",
  name: "userCommission",
  placeholder: "Enter User Commission",
  icon: faTag,
  validationRules: {
    required: "Commission is required",
    min: {
      value: 0,
      message: "Commission must be a positive value",
    },
  },
};


export const sellerComissionValidation = {
  type: "number",
  label: "Seller Commission",
  id: "commissionInput",
  name: "sellerCommission",
  placeholder: "Enter Seller Commission",
  icon: faTag,
  validationRules: {
    required: "Commission is required",
    min: {
      value: 0,
      message: "Commission must be a positive value",
    },
  },
};



export const subCategoryValidation = {
  type: "text",
  label: "Sub Category",
  id: "subCategoryInput",
  name: "subCategoryName",
  placeholder: "Enter Sub Category",
  icon: faTag,
  validationRules: {
    required: "Sub Category is required",
    minLength: {
      value: 3,
      message: "Category must be at least 3 characters long",
    },
    maxLength: {
      value: 30,
      message: "Category cannot exceed 30 characters",
    },
    pattern: {
      value: /^[A-Za-z0-9\s]+$/,
      message: "Category can only contain letters, numbers, and spaces",
    },
  },
};


export const tagValidation = {
  type: "text",
  id: "tagInput",
  name: "editTagInput",
  placeholder: "Enter tag",
  validationRules: {
    required: "Tag is required",
    minLength: {
      value: 2,
      message: "Tag must be at least 2 characters long",
    },
    maxLength: {
      value: 30,
      message: "Tag cannot exceed 30 characters",
    },
    pattern: {
      value: /^[A-Z0-9\s]+$/,
      message: "Tag can only contain uppercase letters, numbers",
    },
  },
};


export const editTagValidation = {
  type: "text",
  id: "tagInput",
  name: "editTagInput",
  placeholder: "Enter tag",
  validationRules: {
    required: "Tag is required",
    minLength: {
      value: 2,
      message: "Tag must be at least 2 characters long",
    },
    maxLength: {
      value: 30,
      message: "Tag cannot exceed 30 characters",
    },
    pattern: {
      value: /^[A-Z0-9\s]+$/,
      message: "Tag can only contain uppercase letters, numbers",
    },
  },
};



export const emailSubjectValidation = {
  type: "text",
  label: "Subject English",
  id: "emailSubjectEndlishInput",
  name: "emailSubjectEnglish",
  placeholder: "Enter Subject English",
  // icon: faBoo,
  validationRules: {
    required: "Subject is required",
    minLength: {
      value: 3,
      message: "Subject must be at least 3 characters long",
    },
  },
};

export const tosValidation = {
  type: "text",
  label: "Term of Service",
  id: "termOfService",
  name: "termOfService",
  placeholder: "Term Of Service",
  multiline: true,
  // icon: faBoo,
  validationRules: {
    required: "Content is required",
    minLength: {
      value: 3,
      message: "Content must be at least 3 characters long",
    },
  },
};


export const ppValidation = {
  type: "text",
  label: "Privacy Policy",
  id: "privacyPolicy",
  name: "privacyPolicy",
  placeholder: "Privacy Policy",
  multiline: true,
  // icon: faBoo,
  validationRules: {
    required: "Content is required",
    minLength: {
      value: 3,
      message: "Content must be at least 3 characters long",
    },
  },
};


export const emailSubjectItValidation = {
  type: "text",
  label: "Subject Italian",
  id: "emailSubjectItalianInput",
  name: "emailSubjectItalian",
  placeholder: "Enter Subject Italian",
  // icon: faBoo,
  validationRules: {
    required: "Subject is required",
    minLength: {
      value: 3,
      message: "Subject must be at least 3 characters long",
    },
  },
};


export const emailMessageValidation = {
  type: "text",
  label: "Message Body English",
  id: "emailMessageEnglishInput",
  name: "emailMessageEnglish",
  placeholder: "Enter Message English",
  multiline: true,
  // icon: faTag,
  validationRules: {
    required: "Message body is required",
    minLength: {
      value: 3,
      message: "Mesage must be at least 3 characters long",
    },
  },
};


export const emailMessageItValidation = {
  type: "text",
  label: "Message Body Italian",
  id: "emailMessageItalianInput",
  name: "emailMessageItalian",
  placeholder: "Enter Message Italian",
  multiline: true,
  // icon: faTag,
  validationRules: {
    required: "Message body is required",
    minLength: {
      value: 3,
      message: "Mesage must be at least 3 characters long",
    },
  },
};


export const smsMessageValidation = {
  type: "text",
  label: "Message Body English",
  id: "messageBodyEnglishInput",
  name: "smsMessageEnglish",
  placeholder: "Enter Message English",
  // icon: faBoo,
  validationRules: {
    required: "Message is required",
    minLength: {
      value: 3,
      message: "Message must be at least 3 characters long",
    },
  },
};


export const smsMessageItValidation = {
  type: "text",
  label: "Message Body Italian",
  id: "messageBodyItalianInput",
  name: "smsMessageItalian",
  placeholder: "Enter Message Italian",
  // icon: faBoo,
  validationRules: {
    required: "Message is required",
    minLength: {
      value: 3,
      message: "Message must be at least 3 characters long",
    },
  },
};

export const rejectReasonValidation = {
  type: "text",
  label: "Reject Reason",
  id: "rejectReason",
  name: "rejectReason",
  placeholder: "Enter Reject Reason",
  // icon: faBoo,
  validationRules: {
    required: "Reject Reason is required",
    minLength: {
      value: 3,
      message: "Reject Reason must be at least 3 characters long",
    },
  },
};

export const userProfileMSGValidation = {
  type: "text",
  label: "User Profle Notification",
  id: "userProfileMsg",
  name: "userProfileMsg",
  placeholder: "Notification for User Profile",
  // icon: faBoo,
  validationRules: {
    required: "Notification for user profile is required",
  },
};

export { emailValidation, passwordValidation, cvvValidation, expirationDateValidation, creditCardValidation, postalCodeValidation, provinceValidation, usernameValidation, currencyValidation, addressValidation, cityValidation };