import { isFormInvalid } from "../utils/isFormValid";
import { findInputError } from "../utils/findInputError";
import { useFormContext } from "react-hook-form";
import "../styles/inputFieldStyles.css";

const InputField = ({
  icon,
  type,
  name,
  id,
  placeholder,
  // value,
  // setValue,
  validationRules,
  multiline,
  label,
  disabled,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  // const { t } = useTranslation(["inputFields", "errors"]);
  // coninputErrors.error.message));
  return (
    <div className="input-field">
      {/* {label && <label htmlFor={id}>{t(`inputFields:${name}`)}</label>} */}
      {label && <label htmlFor={id}>{label}</label>}
      <div className={`input-field-container ${isInvalid && "field-errors"}`}>
        {multiline ? (
          <textarea
            id={id}
            type={type}
            className={"text-area"}
            placeholder={placeholder}
            disabled={disabled}
            {...register(name, validationRules)}
          ></textarea>
        ) : (
          <input
            type={type}
            name={name}
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete="off"
            min={0}
            {...register(name, validationRules)}
          />
        )}
      </div>
      {isInvalid && (
        <h2 className="input-field-error">
          {/* {t(`${inputErrors.error.message}`, { ns: "errors" })} */}
          {inputErrors.error.message}
        </h2>
      )}
    </div>
  );
};

export default InputField;
