import axios from "axios";
import createDateContext from "./createDataContext";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../config/firebaseSetup";

const auctionReducer = (state, action) => {
  switch (action.type) {
    case 'get_auctions':
      return { ...state, auctions: action.payload.auctions };
    case 'getAllAuctions':
      return { ...state, allAuctions: action.payload };
    default:
      return state;
  }
};

const getAuctions = (dispatch) => {
  return async () => {
    const { data } = await axios.get('/auctions.json');
    dispatch({ type: 'get_auctions', payload: data });
  };
};

const getAllAuctions = (dispatch) => {
  return async () => {
    try {
      const auctionsColRef = collection(db, "Auction");
      const q = query(auctionsColRef,
        where("status", "==", "approved"),
        orderBy("created", "desc"));
      const querySnapshot = await getDocs(q);

      const auctionsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch({ type: 'getAllAuctions', payload: auctionsData });
    } catch (error) {
      console.error("Error getting documents: ", error);
      throw error;
    }
  };
};
export const { Context, Provider } = createDateContext(
  auctionReducer,
  { getAuctions, getAllAuctions },
  [],
);