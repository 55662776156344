import { useEffect, useState } from "react";
import IconBtn from "../userAuctionsDashboard/IconBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faEnvelope,
  faMailBulk,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebaseSetup";
import EmailNotifications from "./EmailNotifications";
import SMSNotifications from "./SMSNotifications";
import Loading from "../Loader";
import { FormProvider, useForm } from "react-hook-form";
import ComposeEmail from "./ComposeEmail";
import Modal from "../Modal";
import Overlay from "../Overlay";
import ComposeSMS from "./ComposeSMS";
import { toast } from "react-toastify";

const NotificationsManagement = () => {
  const [showEmailFormModal, setShowEmailFormModal] = useState(false);
  const [showSMSFormModal, setShowSMSFormModal] = useState(false);
  const [activeIcon, setActiveIcon] = useState(faEnvelope);
  const [activeTab, setActiveTab] = useState("Emails");
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editEmail, setEditEmail] = useState(null);
  const [showEditEmailModal, setShowEditEmailModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [editSMS, setEditSMS] = useState(null);
  const [showSMSEditModal, setShowSMSEditModal] = useState(false);
  const [showSMSDelete, setSMSDeleteModal] = useState(false);

  useEffect(() => {
    fetchNotifications();

    // Set up real-time listener for email notifications
    const emailsCollection = collection(db, "emailNotifications");
    let emailListener;
    if (activeTab === "Emails") {
      setLoading(true);
      emailListener = onSnapshot(emailsCollection, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNotifications(data);
        setLoading(false);
      });
    }

    // Set up real-time listener for SMS notifications
    const smsCollection = collection(db, "smsNotifications");
    let smsListener;
    if (activeTab === "SMS") {
      setLoading(true);
      smsListener = onSnapshot(smsCollection, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNotifications(data);
        setLoading(false);
      });
    }

    return () => {
      if (emailListener) emailListener(); // Unsubscribe from email listener if it exists
      if (smsListener) smsListener(); // Unsubscribe from SMS listener if it exists
    };
  }, [activeTab]);

  // Fetch notifications based on active tab
  const fetchNotifications = async () => {
    try {
      const collectionName =
        activeTab === "Emails" ? "emailNotifications" : "smsNotifications"; // collection based on active tab
      const querySnapshot = await getDocs(collection(db, collectionName)); // Query the collection
      const notificationsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      showEditEmailModal ||
      showEmailFormModal ||
      showSMSEditModal ||
      showSMSFormModal
    ) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [
    showEditEmailModal,
    showEmailFormModal,
    showSMSEditModal,
    showSMSFormModal,
  ]);

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Create Email in Firebase
  const createEmailTemplate = async (formData, selectedOption) => {
    setLoading(true);
    try {
      // Reference to the "emailNotifications" collection
      const emailsColRef = collection(db, "emailNotifications");

      // Check if a document with the same label already exists
      const querySnapshot = await getDocs(
        query(emailsColRef, where("label", "==", selectedOption.label))
      );

      if (!querySnapshot.empty) {
        // A document with the same label already exists
        setLoading(false);
        return toast.error(
          "An email template with this label already exists. Try deleteing it or editing it"
        );
      }

      // Create a new document with the entire formData
      const docRef = await addDoc(emailsColRef, {
        created: serverTimestamp(),
        emailEnglish: formData.emailMessageEnglish,
        subjectEnglish: formData.emailSubjectEnglish,
        emailItalian: formData.emailMessageItalian,
        subjectItalian: formData.emailSubjectItalian,
        label: selectedOption.label,
        userProfileMsg: formData.userProfileMsg,
      });
      toast.success("Email Template Created Successfully");
      setShowEmailFormModal(false);
      // methods.reset();
      setLoading(false);
    } catch (error) {
      // Handle error
      setLoading(false);
      toast.error("An Error Occurred During Creating Your Email");
    }
  };

  const createEmailHandler = (formdata, selectedOption) => {
    createEmailTemplate(formdata, selectedOption);
  };

  // Edit Email
  const handleEmailEdit = (email) => {
    setEditEmail(email);
    setShowEditEmailModal(true);
  };

  // Edit Email
  const editEmailHandler = async (formData, selectedOption) => {
    setLoading(true);
    try {
      // Reference to the "emailNotifications" collection
      const emailsColRef = collection(db, "emailNotifications");

      // Check if the label has changed
      if (editEmail.label !== selectedOption.label) {
        // Label has changed, check if a document with the new label already exists
        const querySnapshot = await getDocs(
          query(emailsColRef, where("label", "==", selectedOption.label))
        );

        if (!querySnapshot.empty) {
          // A document with the new label already exists
          setLoading(false);
          return toast.error(
            "An email template with this label already exists. Try deleting it or editing it"
          );
        }
      }

      // Label either hasn't changed or it has changed but no existing document with the new label exists
      // Proceed with updating the document
      const emailDocRef = doc(db, "emailNotifications", editEmail.id);
      await updateDoc(emailDocRef, {
        emailEnglish: formData.emailMessageEnglish,
        subjectEnglish: formData.emailSubjectEnglish,
        emailItalian: formData.emailMessageItalian,
        subjectItalian: formData.emailSubjectItalian,
        label: selectedOption.label,
        userProfileMsg: formData.userProfileMsg,
      });

      // Display success message
      toast.success("Email updated successfully!");

      // Close the modal, reset form, etc.
      setShowEditEmailModal(false);
      setLoading(false);
    } catch (error) {
      // Handle any errors
      console.error("Error updating email template:", error);
      setLoading(false);
      toast.error("An error occurred while updating the email template");
    } finally {
      // Regardless of success or failure, stop loading indicator
      setLoading(false);
    }
  };

  // delete email
  const handleDelete = (email) => {
    setEditEmail(email);
    setDeleteModal(true);
  };

  // delete email
  const deleteEmailHandler = async (emailId) => {
    setLoading(true);
    try {
      // Reference to the document to be deleted
      const emailDocRef = doc(db, "emailNotifications", emailId);
      // Delete the document
      await deleteDoc(emailDocRef);
      // Display success message
      toast.success("Email template deleted successfully!");
      setLoading(false);
      setDeleteModal(false);
    } catch (error) {
      // Handle any errors
      console.error("Error deleting email template:", error);
      setLoading(false);
      toast.error("An error occurred while deleting the email template");
    }
  };

  // Create SMS in Firebase
  const createSMSTemplate = async (formData, selectedOption) => {
    setLoading(true);
    try {
      // Reference to the "smsNotifications" collection
      const smsColRef = collection(db, "smsNotifications");

      // Check if a document with the same label already exists
      const querySnapshot = await getDocs(
        query(smsColRef, where("label", "==", selectedOption.label))
      );

      if (!querySnapshot.empty) {
        // A document with the same label already exists
        setLoading(false);
        return toast.error(
          "An SMS template with this label already exists. Try deleteing it or editing it"
        );
      }

      // Create a new document with the entire formData
      const docRef = await addDoc(smsColRef, {
        created: serverTimestamp(),
        smsEnglish: formData.smsMessageEnglish,
        smsItalian: formData.smsMessageItalian,
        label: selectedOption.label,
      });
      toast.success("SMS Template Created Successfully");
      setShowSMSFormModal(false);
      // methods.reset();
      setLoading(false);
    } catch (error) {
      // Handle error
      setLoading(false);
      toast.error("An Error Occurred During Creating Your SMS");
    }
  };
  // Create SMS
  const createSMSHandler = (formdata, selectedOption) => {
    createSMSTemplate(formdata, selectedOption);
  };

  // Edit SMS
  const handleSMSEdit = (sms) => {
    setEditSMS(sms);
    setShowSMSEditModal(true);
  };

  // Edit sms
  const editSMSHandler = async (formData, selectedOption) => {
    setLoading(true);
    try {
      // Reference to the "emailNotifications" collection
      const smsColRef = collection(db, "smsNotifications");

      // Check if the label has changed
      if (editSMS.label !== selectedOption.label) {
        // Label has changed, check if a document with the new label already exists
        const querySnapshot = await getDocs(
          query(smsColRef, where("label", "==", selectedOption.label))
        );

        if (!querySnapshot.empty) {
          // A document with the new label already exists
          setLoading(false);
          return toast.error(
            "An sms template with this label already exists. Try deleting it or editing it"
          );
        }
      }

      // Label either hasn't changed or it has changed but no existing document with the new label exists
      // Proceed with updating the document
      const smsDocRef = doc(db, "smsNotifications", editSMS.id);
      await updateDoc(smsDocRef, {
        smsEnglish: formData.smsMessageEnglish,
        smsItalian: formData.smsMessageItalian,
        label: selectedOption.label,
      });

      // Display success message
      toast.success("SMS updated successfully!");

      // Close the modal, reset form, etc.
      setShowSMSEditModal(false);
      setLoading(false);
    } catch (error) {
      // Handle any errors
      console.error("Error updating sms template:", error);
      setLoading(false);
      toast.error("An error occurred while updating the sms template");
    } finally {
      // Regardless of success or failure, stop loading indicator
      setLoading(false);
    }
  };

  // Delete SMS
  const handleDeleteSMS = async (sms) => {
    setEditSMS(sms);
    setSMSDeleteModal(true);
  };

  // Delete SMS
  const deleteSMSHandler = async (smsId) => {
    setLoading(true);
    try {
      // Reference to the document to be deleted
      const smsDocRef = doc(db, "smsNotifications", smsId);
      // Delete the document
      await deleteDoc(smsDocRef);
      // Display success message
      toast.success("SMS template deleted successfully!");
      setLoading(false);
      setDeleteModal(false);
      setSMSDeleteModal(false);
    } catch (error) {
      // Handle any errors
      console.error("Error deleting SMS template:", error);
      setLoading(false);
      toast.error("An error occurred while deleting the sms template");
    }
  };

  const actionMenuEmail = [
    {
      onClick: handleEmailEdit,
      icon: faPen,
      color: "#80ed99",
      text: "Edit",
    },

    {
      onClick: handleDelete,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Delete",
    },
  ];

  const actionMenuSMS = [
    {
      onClick: handleSMSEdit,
      icon: faPen,
      color: "#80ed99",
      text: "Edit",
    },

    {
      onClick: handleDeleteSMS,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Delete",
    },
  ];

  return (
    <div>
      {loading && <Loading />}
      <h2 className="headi">Notifications Management</h2>
      <div
        className="create-new-auction"
        // style={{ marginLeft: "10px" }}
      >
        <IconBtn
          onClick={() => {
            // setActiveIcon(faGavel);
            if (activeTab === "Emails") {
              setShowEmailFormModal(true);
            }
            if (activeTab === "SMS") {
              setShowSMSFormModal(true);
            }
          }}
          iconName={faMailBulk}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#e2cfea" }}>
            <FontAwesomeIcon icon={faMailBulk} color="purple" />
          </div>
          <span>
            {activeTab === "Emails" ? "Compose Email" : "Compose SMS"}{" "}
          </span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faEnvelope);
            // setShowCreateFormModal(true);
            handleTabChange("Emails");
          }}
          iconName={faEnvelope}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#e2cfea" }}>
            <FontAwesomeIcon icon={faEnvelope} color="purple" />
          </div>
          <span>Emails</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faComment);
            handleTabChange("SMS");
          }}
          iconName={faComment}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#c7f9cc" }}
          >
            <FontAwesomeIcon icon={faComment} color="#25a18e" />
          </div>
          <span>SMS</span>
        </IconBtn>
      </div>
      <div>
        {activeTab === "Emails" ? (
          <EmailNotifications
            actionMenuEmail={actionMenuEmail}
            notifications={notifications}
          />
        ) : (
          <SMSNotifications
            notifications={notifications}
            actionMenuSMS={actionMenuSMS}
          />
        )}
      </div>

      {showEmailFormModal && (
        <div className="formModal-container">
          <div className="auction-from">
            <ComposeEmail
              // methods={methods}
              defaultValues={{}}
              onClose={() => setShowEmailFormModal(false)}
              onSubmit={createEmailHandler}
            />
          </div>
        </div>
      )}

      {showEditEmailModal && (
        <div className="formModal-container">
          <div className="auction-from">
            <ComposeEmail
              // methods={methods}
              defaultValues={{
                emailMessageEnglish: editEmail.emailEnglish,
                emailSubjectEnglish: editEmail.subjectEnglish,
                emailMessageItalian: editEmail.emailItalian,
                emailSubjectItalian: editEmail.subjectItalian,
                userProfileMsg: editEmail.userProfileMsg,
                label: { label: editEmail.label },
              }}
              onClose={() => setShowEditEmailModal(false)}
              onSubmit={editEmailHandler}
            />
          </div>
        </div>
      )}

      {showDeleteModal && (
        <>
          <Overlay onClose={() => setDeleteModal(false)} />
          <Modal
            onCancel={() => {
              setDeleteModal(false);
            }}
            successText={"Delete Email"}
            cancelText={"Cancel"}
            onAgree={() => deleteEmailHandler(editEmail.id)}
          >
            <h2>Delete Email</h2>
            <p>Are you sure you want to delete?</p>
          </Modal>
        </>
      )}

      {showSMSFormModal && (
        <div className="formModal-container">
          <div className="auction-from">
            <ComposeSMS
              defaultValues={{}}
              onClose={() => setShowSMSFormModal(false)}
              onSubmit={createSMSHandler}
            />
          </div>
        </div>
      )}
      {showSMSEditModal && (
        <div className="formModal-container">
          <div className="auction-from">
            <ComposeSMS
              defaultValues={{
                label: editSMS.label,
                smsMessageEnglish: editSMS.smsEnglish,
                smsMessageItalian: editSMS.smsItalian,
              }}
              onClose={() => setShowSMSEditModal(false)}
              onSubmit={editSMSHandler}
            />
          </div>
        </div>
      )}
      {showSMSDelete && (
        <>
          <Overlay onClose={() => setSMSDeleteModal(false)} />
          <Modal
            onCancel={() => {
              setSMSDeleteModal(false);
            }}
            successText={"Delete SMS"}
            cancelText={"Cancel"}
            onAgree={() => deleteSMSHandler(editSMS.id)}
          >
            <h2>Delete SMS</h2>
            <p>Are you sure you want to delete?</p>
          </Modal>
        </>
      )}
    </div>
  );
};

export default NotificationsManagement;
