import React, { useEffect, useState } from "react";
import MoreMenu from "./ActionMenu";
import NotificationViewModel from "./NotificationViewModel";

const SMSNotifications = ({ notifications, actionMenuSMS }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (showNotification) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [showNotification]);

  const renderNotifications = notifications.map((notification) => (
    <div
      key={notification.id}
      className="sms-notification"
      onClick={() => {
        setNotification(notification);
        setShowNotification(true);
      }}
    >
      <p>{notification.smsItalian}</p>
      <h2>{notification.label}</h2>
      <p>{notification.smsEnglish}</p>
      <span className="gItem">
        <MoreMenu actionMenu={actionMenuSMS} tag={notification} />
      </span>
    </div>
  ));
  return (
    <div className="email-contain">
      {renderNotifications}
      {showNotification && (
        <NotificationViewModel
          notification={notification}
          onClose={() => {
            setShowNotification(false);
            setNotification(null);
          }}
          isSms
        />
      )}
    </div>
  );
};

export default SMSNotifications;
