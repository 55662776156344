import ReactDOM from "react-dom";
import { FormProvider, useForm } from "react-hook-form";
import { subCategoryValidation } from "../../utils/inputValidations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClose,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import MoreMenu from "./ActionMenu";
import { toast } from "react-toastify";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebaseSetup";
import Overlay from "../Overlay";
import Modal from "../Modal";

const CategoriesDetails = ({
  categoryId,
  categories,
  onClose,
  setShowLoader,
  // fetchCategories,
  deleteCategory,
  createSubCategory,
  adminActions,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteSubCatModal, setShowDeleteSubCatModal] = useState(false);
  const [subCategory, setSubCategory] = useState(null);
  const [editingSubCatId, setEditingSubCatId] = useState(null);

  const methods = useForm();
  const inputErrors =
    methods.formState.errors[subCategoryValidation?.name] || [];

  const handleEdit = (cat) => {
    // This function just shows the edit form to the user
    setEditingSubCatId(cat.id);
    setIsEditing(true);
    methods.setValue(subCategoryValidation?.name, cat?.name);
  };

  const submitEditHandler = methods.handleSubmit(async (formData) => {
    // This function will be used to edit the subcategory in the database

    const newSubcategoryName = formData.subCategoryName;

    // const catId = categoryId; // Replace this with your actual category ID
    const subcategoryId = editingSubCatId; // Replace this with your actual subcategory ID
    setShowLoader(true);
    try {
      const categoryDocRef = doc(db, "categories", categoryId);
      const categoryDoc = await getDoc(categoryDocRef);

      if (categoryDoc.exists()) {
        const categoryData = categoryDoc.data();
        const subcategories = categoryData.subcategories || [];

        // Find the index of the subcategory within the array
        const subcategoryIndex = subcategories.findIndex(
          (subcategory) => subcategory.id === subcategoryId
        );
        if (subcategoryIndex !== -1) {
          // Update the name of the subcategory
          subcategories[subcategoryIndex].name = newSubcategoryName;

          // Update the category document with the modified subcategories array
          await updateDoc(categoryDocRef, { subcategories });

          toast.success("Subcategory updated successfully!");
          // await fetchCategories();
          setIsEditing(false);
          setShowLoader(false);
        } else {
          toast.error("Subcategory not found in the category.");
          setShowLoader(false);
        }
      } else {
        toast.error("Category not found.");
        setShowLoader(false);
      }
    } catch (error) {
      setIsEditing(false);
      setShowLoader(false);
      toast.error("Error updating Subcategory");
      console.log(error);
    }
  });

  const handleDelete = (cat) => {
    // This function just show the modal to the user
    setShowDeleteSubCatModal(true);
    setSubCategory(cat);
  };

  const handleSubCatDelete = async () => {
    setShowLoader(true);
    try {
      const categoryDocRef = doc(db, "categories", categoryId);
      const categoryDoc = await getDoc(categoryDocRef);

      if (categoryDoc.exists()) {
        const categoryData = categoryDoc.data();
        let subcategories = categoryData.subcategories || [];

        // Filter out the subcategory to delete
        subcategories = subcategories.filter(
          (subcategory) => subcategory.id !== subCategory.id
        );

        // Update the category document with the modified subcategories array
        await updateDoc(categoryDocRef, { subcategories });

        toast.success("Subcategory deleted successfully!");
        // await fetchCategories();
        setShowLoader(false);
        setShowDeleteSubCatModal(false);
      } else {
        toast.error("Category not found.");
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Error deleting Subcategory");
      // console.log(error);
    }
  };

  const actionMenuCategories = [
    adminActions.canEdit && {
      onClick: handleEdit,
      icon: faPen,
      color: "#80ed99",
      text: "Edit",
    },

    adminActions.canDelete && {
      onClick: handleDelete,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Delete",
    },
  ].filter(Boolean);

  const filteredCategory = categories.find((cat) => cat.id === categoryId);
  const subcategories = filteredCategory ? filteredCategory.subcategories : [];

  return ReactDOM.createPortal(
    <div className="auctionItemDetailsModal">
      <div
        className="auctionItemDetailsModal-content"
        style={{ maxWidth: "1000px" }}
      >
        <div className="heading-aidm">
          <h2>Category Details</h2>
          <button className="reject-button" onClick={onClose}>
            Close
          </button>
        </div>
        <div className="title-aidm">
          <h3>Title</h3>
          <p>{filteredCategory?.name}</p>
        </div>
        <div className="heading-aidm">
          <h2>Sub Category Details</h2>
        </div>

        <ul className="tagsList-tagMa">
          {subcategories.length ? (
            subcategories.map((category, index) => (
              // <li className="li">
              <div className="div" key={category.id}>
                {isEditing && editingSubCatId === category.id ? (
                  <FormProvider {...methods}>
                    <form
                      className="tagFlexCont"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <span className="a">current value: {category.name}</span>
                      <div className="flex">
                        <input
                          type={subCategoryValidation.type}
                          id={subCategoryValidation.id}
                          name={subCategoryValidation?.name}
                          // defaultValue={tag.name}
                          placeholder={`Edit Category`}
                          {...methods.register(
                            subCategoryValidation?.name,
                            subCategoryValidation?.validationRules
                          )}
                        />
                        {inputErrors && (
                          <div className="ier">{inputErrors?.message}</div>
                        )}

                        <div className="fl">
                          {adminActions.canEdit && (
                            <button className="s" onClick={submitEditHandler}>
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                color="#57cc99"
                              />
                              Save
                            </button>
                          )}
                          <button
                            className="c"
                            onClick={() => setIsEditing(false)}
                          >
                            <FontAwesomeIcon icon={faClose} color="#ef233c" />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                ) : (
                  <li
                    className="li"
                    onClick={() => {
                      setSubCategory(category);
                      // setShowCategoryDetailsModal(true);
                    }}
                  >
                    <h2>{category.name}</h2>
                    <span
                      className={`gItem aspn ${category?.name?.toLowerCase()}Status`}
                    >
                      {/* {tag.status} */}
                    </span>
                    <span className="gItem">
                      <MoreMenu
                        actionMenu={actionMenuCategories}
                        tag={category}
                      />
                    </span>
                  </li>
                )}
              </div>
              // </li>
            ))
          ) : (
            <h2>No SubCategories</h2>
          )}
        </ul>
      </div>
      <div className="a-btns">
        {adminActions.canCreate && (
          <button
            className="approve-button"
            onClick={() => createSubCategory(filteredCategory)}
          >
            Add Subcategory
          </button>
        )}
        {adminActions.canDelete && (
          <button
            className="reject-button"
            onClick={() => deleteCategory(filteredCategory)}
          >
            Delete
          </button>
        )}
        <button className="reject-button" onClick={onClose}>
          Close
        </button>
      </div>
      {showDeleteSubCatModal && (
        <>
          <Overlay onClose={() => setShowDeleteSubCatModal(false)} />
          <Modal
            onCancel={() => {
              setShowDeleteSubCatModal(false);
            }}
            successText={"Delete Subcategory"}
            cancelText={"Cancel"}
            onAgree={handleSubCatDelete}
          >
            <h2>Delete Subcategory</h2>
            <p>
              Are you sure you want to delete{" "}
              <span style={{ fontWeight: "bold" }}>{subCategory.name}</span>{" "}
              Subcategory
            </p>
          </Modal>
        </>
      )}
    </div>,
    document.querySelector("#catDetailsModal")
  );
};

export default CategoriesDetails;
