import ReactDOM from "react-dom";
import "../styles/modalStyles.css";

const Modal = ({ children, onCancel, onAgree, cancelText, successText }) => {
  return ReactDOM.createPortal(
    <div className="modal-container">
      <div>{children}</div>
      <div className="modal-btns">
        <button onClick={onAgree} className="success">
          {successText}
        </button>
        <button onClick={onCancel} className="cancel">
          {cancelText}
        </button>
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

export default Modal;
