import ReactDOM from "react-dom";
import "../../styles/formModalStyles.css";
import AuctionForm from "./AuctionForm";
import { useState } from "react";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebaseSetup";
import { toast } from "react-toastify";

const EditAuction = ({
  onClose,
  editAuction,
  getAllAuctions,
  setAuctionItem,
}) => {
  const [edit, setEdit] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const defaultValues = {
    auctionReservePrice: editAuction.auctionReservePrice,
    auctionTitle: editAuction.auctionTitle,
    auctionTitleItalian: editAuction.auctionTitleItalian,
    descriptionEnglish: editAuction.descriptionEnglish,
    descriptionItalian: editAuction.descriptionItalian,
    endDate: editAuction.endDate,
    images: editAuction.images,
    startDate: editAuction.startDate,
    tags: editAuction.tags,
    auctionStartingPrice: editAuction.auctionStartingPrice,
    startTime: editAuction.startTime,
    endTime: editAuction.endTime,
    category: editAuction.category,
    subcategory: editAuction.subcategory,
  };

  const handleSubmit = async (formData) => {
    try {
      const endDateTime = new Date(`${formData.endDate}T${formData.endTime}`);
      const startDateTime = new Date(
        `${formData.startDate}T${formData.startTime}`
      );
      const endDateAndTime = Timestamp.fromDate(endDateTime);
      const startDateAndTime = Timestamp.fromDate(startDateTime);

      const auctionDocRef = doc(db, "Auction", editAuction.id);
      // Perform the update operation
      await updateDoc(auctionDocRef, {
        ...formData,
        // status: editAuction.status,
        status: "pending",
        serverEndTimeForAuction: endDateAndTime,
        serverStartTimeForAuction: startDateAndTime,
      });
      const updatedAuctionSnapshot = await getDoc(auctionDocRef);
      const updatedAuctionData = updatedAuctionSnapshot.data();
      const updatedAuctionWithId = {
        ...updatedAuctionData,
        id: updatedAuctionSnapshot.id,
      };
      if (setAuctionItem) {
        setAuctionItem(updatedAuctionWithId);
      }
      toast.success("Auction updated successfully!");
      if (getAllAuctions) {
        await getAllAuctions();
      }
      setShowLoader(false);
      onClose();
    } catch (error) {
      setShowLoader(false);
      console.error("Error updating auction:", error);
      toast.error("Error updating auction.");
    }
  };
  // console.log(defaultValues);

  return ReactDOM.createPortal(
    <div className="formModal-container">
      <div className="formModal-content">
        <h2>Edit Your Auction</h2>
        <button onClick={onClose} className="cancel-auction-creation">
          Close
        </button>
      </div>
      <AuctionForm
        handleSubmit={handleSubmit}
        defaultValues={defaultValues}
        onCancel={onClose}
        edit={edit}
        showLoader={showLoader}
        setShowLoader={setShowLoader}
      />
    </div>,
    document.querySelector("#auctionEditFormModal")
  );
};

export default EditAuction;
