import React, { useState } from "react";
import "../styles/dropdown.css";

const Dropdown = ({ items, selectedText, setSelectedText }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedText(item);
    setIsOpen(false);
  };

  return (
    <div
      className={isOpen ? "dropdown active" : "dropdown"}
      onClick={handleDropdownClick}
    >
      <div className="dropdown__text">
        {selectedText ? selectedText.label : "Select Filter"}
      </div>
      {isOpen && (
        <div className="dropdown__items">
          {items.map((item) => (
            <div
              key={item.key}
              className="dropdown__item"
              onClick={() => handleItemClick(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
