import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../config/firebaseSetup";
import { useFormContext } from "react-hook-form";

const CategoriesSelections = ({ defaultValues }) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  // values
  // const [category, setCategory] = useState(null);
  // const [subCategory, setSubCategory] = useState(null);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(data);
      } catch (error) {
        setCategories([]);
        console.error("Error fetching data: ", error);
      }
    };

    fetchTags();
  }, [defaultValues]);

  useEffect(() => {
    if (defaultValues && categories.length) {
      const selectedCategory = categories.find(
        (cat) => cat.name === defaultValues.category
      );

      if (selectedCategory) {
        setValue("category", defaultValues.category);
        setValue("subcategory", defaultValues.subcategory);
        setSubCategories(selectedCategory.subcategories || []);
      }
    }
  }, [defaultValues, categories, setValue]);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;

    // Filter subcategories based on the selected category ID
    const selectedCategoryData = categories.find(
      (cat) => cat.name === selectedCategory
    );

    if (selectedCategoryData) {
      setValue("category", selectedCategoryData.name);
      setSubCategories(selectedCategoryData.subcategories || []);

      // Set the first subcategory as default if available
      if (
        selectedCategoryData.subcategories &&
        selectedCategoryData.subcategories.length > 0
      ) {
        setValue("subcategory", selectedCategoryData.subcategories[0].name);
      } else {
        setValue("subcategory", "");
      }
    }
  };

  const handleSubCat = (e) => {
    const selectedSubcategoryName = e.target.value;
    setValue("subcategory", selectedSubcategoryName);
    // setSubCategory(selectedSubcategoryName);
  };

  return (
    <>
      <div className="input-tag ">
        <div className="catSlection">
          <div className="card-form">
            <label htmlFor="cardCategory">Select Category</label>
            <div className="pcf">
              <select
                className="month-input"
                style={{ width: "100%" }}
                id="cardCategory"
                // onChange={handleCategoryChange}
                name="category"
                // value={categoryName}
                // ref={register({
                //   required: "select one option",
                // })}
                {...register("category", {
                  required: true,
                  onChange: handleCategoryChange,
                  // value: subCategory,
                })}
              >
                <option disabled>Categories</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="card-form">
            <label htmlFor="cardSubCategory">Select Subcategory</label>
            {subCategories.length ? (
              <div className="pcf">
                <select
                  className="month-input"
                  id="cardSubCategory"
                  style={{ width: "100%" }}
                  name="subcategory"
                  // onChange={handleSubCat}
                  // ref={register({
                  //   required: "select one option",
                  // })}
                  {...register("subcategory", {
                    required: true,
                    onChange: handleSubCat,
                    // value: category,
                  })}
                >
                  <option disabled>Subcategories</option>
                  {subCategories.map((subCat) => (
                    <option key={subCat.id} value={subCat.name}>
                      {subCat.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <h2 style={{ paddingTop: "1rem" }}>No SubCategories</h2>
            )}
          </div>
        </div>
        {errors.category && (
          <span className="inputErrors">Category is required.</span>
        )}
        {errors.subcategory && (
          <span className="inputErrors">Subcategory is required.</span>
        )}
      </div>
    </>
  );
};

export default CategoriesSelections;
