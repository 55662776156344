import { auth } from "../config/firebaseSetup";
import Button from "./Button";

const Logout = ({ setUser }) => {
  // const { logout } = useUser();
  return (
    <div className="logout-tab" style={{ height: "90vh" }}>
      <h3>Are you sure you want to logout?</h3>
      <Button
        onClick={() => {
          localStorage.removeItem("loginAdmin");
          // logout();
          setUser(null);
          auth.signOut();
        }}
      >
        Log out
      </Button>
    </div>
  );
};

export default Logout;
