import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  setDoc,
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBg3nh6dphlyfUUxT1ACGLA4qdWiUrEdIo",
  authDomain: "auction-99162.firebaseapp.com",
  projectId: "auction-99162",
  storageBucket: "auction-99162.appspot.com",
  messagingSenderId: "331264878211",
  appId: "1:331264878211:web:bb58753b48e2382d931db6",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    localStorage.setItem("loginUser", JSON.stringify(user));

    if (docs.docs.length === 0) {
      const userDocRef = doc(db, "users", user.uid); // Create a reference to the user document using the user's UID

      await setDoc(userDocRef, {
        uid: user.uid,
        // name: user.displayName,
        authProvider: "google",
        email: user.email,
        isASeller: false,
        isAdmin: false,
        status: 'normal',
      });

    }
  } catch (err) {
    console.log(err);
    alert(err);
  }
};

const mySignInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  } catch (err) {
    console.error(err);
    throw err; // Re-throw the error to handle it in the Login component
  }
};

const registerWithEmailAndPassword = async (username, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    console.log("register sucessfully Done");
    // alert("Registraion sucessfully");
    // navigate("/activation");

    const user = res.user;
    console.log("create user in firestore :=>", user);
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      username: username,
      authProvider: "local",
      email: email,
      isAdmin: "false",
    });
    // localStorage.setItem("loginUser", JSON.stringify(user));
  } catch (err) {
    console.error("error in registration", err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  signInWithGoogle,
  mySignInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
