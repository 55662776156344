import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import Overlay from "../Overlay";
import { useFormContext } from "react-hook-form";
import "../../styles/imageUploadStyles.css";
import { v4 as uuidv4 } from "uuid";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { toast } from "react-toastify";
import { v4 as uuid4 } from "uuid";
import Compressor from "compressorjs";
import { useDrag, useDrop } from "react-dnd";

const ImageUpload = ({
  clearErrors,
  selectedfile,
  setSelectedFile,
  setShowLoader,
}) => {
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);

  const {
    setValue,
    setError,
    formState: { errors },
  } = useFormContext();
  const [imgDelete, setImgDelete] = useState(null);
  const [showDeleteModal, setDeleteModal] = useState(false);

  const moveImage = (dragIndex, hoverIndex) => {
    const dragImage = selectedfile[dragIndex];
    setSelectedFile((prevState) => {
      const newState = [...prevState];
      newState.splice(dragIndex, 1);
      newState.splice(hoverIndex, 0, dragImage);
      return newState;
    });
  };

  const [{ isOver }, drop] = useDrop({
    accept: "IMAGE",
    drop: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = calculateHoverIndex(monitor, dragIndex);
      moveImage(dragIndex, hoverIndex);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const calculateHoverIndex = (monitor, dragIndex) => {
    const hoverBoundingRect = monitor.getClientOffset();
    const dropTargetRects = Array.from(
      document.querySelectorAll(".file-atc-box")
    ).map((el) => el.getBoundingClientRect());

    const draggedItemMiddleX =
      (dropTargetRects[dragIndex].left + dropTargetRects[dragIndex].right) / 2;
    const draggedItemMiddleY =
      (dropTargetRects[dragIndex].top + dropTargetRects[dragIndex].bottom) / 2;

    const closestRect = dropTargetRects.reduce(
      (prevClosest, rect, index) => {
        const distanceX = Math.abs(hoverBoundingRect.x - rect.left);
        const distanceY = Math.abs(hoverBoundingRect.y - rect.top);
        const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);

        if (distance < prevClosest.distance) {
          return { index, distance };
        }
        return prevClosest;
      },
      { index: dragIndex, distance: Infinity }
    );

    if (closestRect.index === dragIndex) {
      return dragIndex;
    }
    return closestRect.index;
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const storage = getStorage();
  const inputChange = async (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];

      if (file.type.startsWith("image/")) {
        setShowLoader(true);
        new Compressor(file, {
          quality: 0.2,
          success: async (compressedFile) => {
            // const storageRef = ref(storage, `images/${compressedFile.name}`);
            const uniqueFileName = `${uuidv4()}_${compressedFile.name}`;
            const storageRef = ref(storage, `images/${uniqueFileName}`);
            const snapshot = await uploadBytes(storageRef, compressedFile);
            // console.log("Image uploaded:", snapshot.metadata.fullPath);
            const downloadURL = await getDownloadURL(snapshot.ref);
            setShowLoader(false);
            // console.log(downloadURL);
            setSelectedFile((preValue) => {
              return [
                ...preValue,
                {
                  id: uuid4(),
                  filename: uniqueFileName,
                  filetype: compressedFile.type,
                  fileimage: downloadURL,
                  datetime:
                    compressedFile.lastModifiedDate.toLocaleString("en-IN"),
                  filesize: filesizes(compressedFile.size),
                  bucket: "images",
                },
              ];
            });
          },
        });
      } else {
        toast.error(`File ${file.name} is not an image and won't be uploaded.`);
      }
    }
  };

  useEffect(() => {
    setValue("images", selectedfile);
  }, [selectedfile]);

  const deleteSelectFile = async (id, filename, destination) => {
    try {
      setShowLoader(true);
      const result = selectedfile.filter((data) => data.id !== id);
      const imgRef = ref(storage, `${destination}/${filename}`);

      await deleteObject(imgRef);
      setSelectedFile(result);
      setDeleteModal(false);
      setShowLoader(false);
      setValue(
        "images",
        result.map((image) => image.fileimage)
      );
      toast.success("Image Deleted Successfully");
    } catch (error) {
      toast.success("Image Deleted Successfully");
      const result = selectedfile.filter((data) => data.id !== id);
      setSelectedFile(result);
      setDeleteModal(false);
      setShowLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedfile.length > 24) {
      setError("images", {
        type: "manual",
        message: "You can select a maximum of 24 images",
        ref: {
          name: "images",
        },
      });
    } else if (!selectedfile.length) {
      setError("images", {
        type: "required",
        message: "Please select at least 1 image",
        ref: {
          name: "images",
        },
      });
    } else {
      clearErrors("images");
    }
  }, [selectedfile, setError, clearErrors]);

  const moveImgUp = (index) => {
    if (index > 0) {
      setSelectedFile((prevState) => {
        const newState = [...prevState];
        [newState[index], newState[index - 1]] = [
          newState[index - 1],
          newState[index],
        ];
        return newState;
      });
    }
  };

  const moveImgDown = (index) => {
    if (index < selectedfile.length - 1) {
      setSelectedFile((prevState) => {
        const newState = [...prevState];
        [newState[index], newState[index + 1]] = [
          newState[index + 1],
          newState[index],
        ];
        return newState;
      });
    }
  };
  return (
    <>
      <div className="fileupload-view">
        <div className="img-card">
          <div className="card-body">
            <div className="kb-data-box">
              <div className="kb-modal-data-title">
                <div className="kb-data-title">
                  <h6>Showcase Your Item (Max 24 images)</h6>
                </div>
              </div>
              <div className="kb-file-upload">
                <div className="file-upload-box">
                  <input
                    type="file"
                    id="fileupload"
                    className="file-upload-input"
                    onChange={inputChange}
                    multiple
                    accept="image/*"
                  />
                  <span>
                    Drag and drop or
                    <span className="file-link">Choose your files</span>
                  </span>
                </div>
              </div>
              <div ref={drop} className="kb-attach-box mb-3">
                {selectedfile.map((data, index) => (
                  <Image
                    key={index}
                    data={data}
                    index={index}
                    setDeleteModal={setDeleteModal}
                    setImgDelete={setImgDelete}
                    moveImgDown={moveImgDown}
                    moveImgUp={moveImgUp}
                  />
                ))}
              </div>
            </div>
            {errors.images && (
              <span className="inputErrors">{errors.images.message}</span>
            )}
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <>
          <Overlay onClose={() => setDeleteModal(false)} />
          <Modal
            onCancel={() => setDeleteModal(false)}
            onAgree={() => {
              console.log(imgDelete.bucket);
              deleteSelectFile(
                imgDelete.id,
                imgDelete.filename,
                imgDelete.bucket
              );
            }}
            cancelText={"Cancel"}
            successText={"Delete"}
          >
            Are you sure you want to delete {imgDelete.filename}?
          </Modal>
        </>
      )}
    </>
  );
};

export default ImageUpload;

const Image = ({
  data,
  index,
  setDeleteModal,
  setImgDelete,
  moveImgDown,
  moveImgUp,
}) => {
  const { id, filename, filetype, fileimage, datetime, filesize } = data;

  const [{ isDragging }, drag] = useDrag({
    type: "IMAGE",
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging,
    }),
  });

  return (
    <div
      ref={drag}
      // style={{ opacity: isDragging ? 0.5 : 1 }}
      className="file-atc-box"
      key={id}
    >
      {filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ? (
        <div className="file-image">
          <img src={fileimage} alt="" />
        </div>
      ) : (
        <div className="file-image">
          <i className="far fa-file-alt"></i>
        </div>
      )}
      <div className="file-detail">
        {index === 0 ? (
          <p>
            <span style={{ fontWeight: "bold" }}>
              The first image will be used as your thumbnail for your item
            </span>
          </p>
        ) : (
          ""
        )}
        <h6>{filename}</h6>
        <p></p>
        <p>
          <span>Size : {filesize}</span>
          <span className="ml-2">Modified Time : {datetime}</span>
        </p>
        <div className="file-actions">
          <button
            type="button"
            className="file-action-btn"
            onClick={() => {
              setDeleteModal(true);
              setImgDelete(data);
            }}
          >
            Delete
          </button>
          <button
            type="button"
            className="file-action-btn"
            onClick={() => {
              moveImgUp(index);
            }}
          >
            Move Up
          </button>
          <button
            type="button"
            className="file-action-btn"
            onClick={() => {
              moveImgDown(index);
            }}
          >
            Move Down
          </button>
        </div>
      </div>
    </div>
  );
};
