import ReactDOM from "react-dom";
import ImageSlider from "../ImageSlider";

const ReportedAuctionDetailModal = ({ auction, onClose }) => {
  // console.log(auction);
  return ReactDOM.createPortal(
    <div className="auctionItemDetailsModal">
      <div className="auctionItemDetailsModal-content">
        <div className="heading-aidm">
          <h2>Auction Details</h2>
          <button className="reject-button" onClick={onClose}>
            Close
          </button>
        </div>
        <div className="img-slider">
          <ImageSlider images={auction.images} />
          {/* <img src={item.images[0].fileimage} alt="" /> */}
        </div>
        <div className="title-aidm">
          <h3>Title</h3>
          <p>{auction.auctionTitle}</p>
        </div>
        <div className="title-aidm">
          <p>Reports</p>
        </div>
        <div className="title-aidm">
          {auction.reports.reverse().map((report, index) => (
            <div key={report.id} className="main-reps">
              <h3>Report Subject</h3>
              <div className="rep-flex-con">
                <span>{index + 1}.</span> <p>{report.reportSubject}</p>
              </div>
              <h3>Reporter Email</h3>
              <p className="rp">Report Email:{report.userEmail}</p>
              <h3>Report Description</h3>
              <p className="rp">{report.reportReason}</p>
            </div>
          ))}
        </div>
      </div>
    </div>,
    document.querySelector("#tagDetailsModal")
  );
};

export default ReportedAuctionDetailModal;
