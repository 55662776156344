import { ToastContainer } from "react-toastify";
import AppRouter from "./routes/AppRouter";
import 'react-toastify/dist/ReactToastify.css';
import AdminDashboardSidebar from "./components/dashboardComponents/AdminDashboardSidebar";
import { faBell, faBellConcierge, faEnvelope, faExclamationTriangle, faFileEdit, faGavel, faLayerGroup, faPen, faSignOutAlt, faTags, faUserSecret, faUsers } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { useEffect, useState } from "react";
import { auth, db } from "./config/firebaseSetup";
import { doc, getDoc } from "firebase/firestore";
import Loading from "./components/Loader";


function App () {
  const navigate = useNavigate();
  const [ user, setUser ] = useState(localStorage.getItem('loginAdmin') || null);
  const [ adminData, setAdminData ] = useState();
  const [ loading, setLoading ] = useState(true);
  // const [ rerun, setRerun ] = useState(false);

  const [ authUser, setAuthUser ] = useState(null);


  useEffect(() => {
    // Firebase listener for auth state changes
    const unsubscribe = auth.onAuthStateChanged((loggedInUser) => {
      if (loggedInUser) {
        // User is signed in
        setAuthUser(loggedInUser);
      } else {
        // No user is signed in
        setAuthUser(null);
        // navigate('/');
        // setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const getUser = async () => {
      try {
        const admin = auth.currentUser;
        // console.log(admin);
        if (admin) {
          const userDocRef = doc(db, "admins", admin.uid);
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            setAdminData(docSnap.data());
          } else {
            setAdminData(null);
            // setUser(null);
          }
        } else {
          setAdminData(null);
          // setUser(null);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching admin data:", error);
        setAdminData(null);
        setLoading(false);
      }
    };

    getUser();
  }, [ authUser ]);

  if (loading) {
    return <Loading />;
  }

  // if (!adminData) {
  //   return <Loading />;
  // }

  const tabsList = [
    {
      icon: faGavel,
      title: "Auctions",
      onClick: () => navigate("/"),
    },
    {
      icon: faUsers,
      title: "Users",
      onClick: () => navigate("/admin/user-management"),
    },
    {
      icon: faTags,
      title: "Tags",
      onClick: () => navigate("/admin/tags-management"),
    },
    {
      icon: faLayerGroup,
      title: "Categories",
      onClick: () => navigate("/admin/categories-management"),
    },
    {
      icon: faExclamationTriangle,
      title: "Reports",
      onClick: () => navigate("/admin/report-management"),
    },
    {
      icon: faFileEdit,
      title: "Edit Auctions",
      onClick: () => navigate("/admin/edit-auction-management"),
    },
    {
      icon: faEnvelope,
      title: "Messages",
      onClick: () => navigate("/admin/messages"),
    },
    adminData?.isSuperAdmin && {
      icon: faUserSecret,
      title: "Sub Admin",
      onClick: () => navigate("/admin/subadmin-management"),
    },
    {
      icon: faBell,
      title: "Notifications",
      onClick: () => navigate("/admin/notifications-management"),
    },
    {
      icon: faPen,
      title: "Edit Website Content",
      onClick: () => navigate("/admin/edit-website-content"),
    },
    {
      icon: faBellConcierge,
      title: "Notifications Logs",
      onClick: () => navigate("/admin/notifications-logs"),
    },
    {
      icon: faSignOutAlt,
      title: "Logout",
      onClick: () => navigate("/admin/logout"),
    },
  ].filter(Boolean);

  // console.log(user);

  return (
    <div className="App">

      {user && authUser ?
        <>
          <AdminDashboardSidebar tabsList={tabsList} setUser={setUser} />
          <AppRouter setUser={setUser} adminData={adminData} />
        </>
        :
        <LoginPage setUser={setUser} />
      }
      <ToastContainer />
    </div>
  );
}

export default App;
