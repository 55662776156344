import { useEffect, useState } from "react";
import UsersList from "./UsersList";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import Loading from "../Loader";
import { toast } from "react-toastify";

const UserManagement = () => {
  const [users, setUsers] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [auctions, setAuctions] = useState([]);

  const user = auth.currentUser;
  const getAdmin = async () => {
    try {
      const userDocRef = doc(db, "admins", user.uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        setAdminData(docSnap.data());
      } else {
        setAdminData(null);
      }
    } catch (error) {
      setAdminData(null);
    }
  };

  const getAllAuctions = async () => {
    try {
      // Reference to the "Auction" collection
      const auctionsColRef = collection(db, "Auction");
      const statusFilteredAuctions = query(
        auctionsColRef,
        where("status", "!=", "draft")
      );

      const querySnapshot = await getDocs(statusFilteredAuctions);

      const auctionsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAuctions(auctionsData);
    } catch (error) {
      console.error("Error getting documents: ", error);
      setAuctions([]);
      // throw error;
      // toast.error('Failed to fetch acutions');
    }
  };

  const getUsers = () => {
    const usersCollection = collection(db, "users");

    try {
      const unsubscribe = onSnapshot(usersCollection, (querySnapshot) => {
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
      });

      return unsubscribe;
    } catch (error) {
      console.error("Error fetching users: ", error);
      setUsers([]);
    }
  };

  useEffect(() => {
    const unsubscribe = getUsers();

    return () => unsubscribe(); // Clean up the subscription when the component unmounts
  }, []);

  useEffect(() => {
    getAllAuctions();
  }, []);

  useEffect(() => {
    getAdmin();
  }, [user]);

  if (!users) return <Loading />;
  if (!adminData) return <Loading />;

  return (
    <div>
      <UsersList users={users} admin={adminData} auctions={auctions} />
    </div>
  );
};

export default UserManagement;
