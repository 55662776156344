import { useState } from "react";
import { categoryValidation } from "../../utils/inputValidations";
import { RenderInput } from "./TagsCreationForm";

const CategoriesCreationForm = ({ methods, validation }) => {
  const [value, setValue] = useState("");
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div>
        {
          <RenderInput
            validation={validation}
            value={value}
            setValue={setValue}
            methods={methods}
          />
        }
      </div>
    </form>
  );
};

export default CategoriesCreationForm;
