import ReactDOM from "react-dom";
import "../styles/overlayStyles.css";

const Overlay = ({ onClose }) => {
  return ReactDOM.createPortal(
    <div onClick={onClose} className="overlay-modal"></div>,
    document.querySelector("#overlay")
  );
};

export default Overlay;
