import { useState, useEffect, useRef } from "react";
import "../styles/dropdownSelectStyles.css";

function DropdownSelect({
  options,
  heading,
  className,
  toggleActive,
  setOptions,
  setSelectedOption,
  selectedOption,
}) {
  const [isActive, setIsActive] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(
  //   defaultValues.label || options[0]
  // );
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleDocumentClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    }

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  function toggleDropdown() {
    setIsActive(!isActive);
    if (toggleActive) {
      toggleActive();
    }
  }

  function handleOptionClick(option, index) {
    setSelectedOption(option);
    if (setOptions) {
      setOptions(option);
    }
    setIsActive(false);
    if (toggleActive) {
      toggleActive();
    }
  }
  return (
    <div className="dropdown-select-container auction-form-input">
      <label>{heading}</label>
      <div
        className={`wrapper-dropdown ${isActive ? "active" : ""} ${className}`}
        ref={dropdownRef}
        onClick={toggleDropdown}
      >
        <div className={`${selectedOption.id && "flx"}`}>
          {selectedOption.id && (
            <span
              style={{ fontSize: "30px" }}
              className={`fi fi-${selectedOption.id.toLowerCase()} fis`}
            />
          )}
          <span>{selectedOption.label}</span>
        </div>
        <ul className="dropdown">
          {options.map((option, index) => (
            <li
              className="flx"
              key={index}
              onClick={() => handleOptionClick(option, index)}
            >
              {option.id && (
                <span
                  style={{ fontSize: "30px" }}
                  className={`fi fi-${option.id.toLowerCase()} fis`}
                />
              )}
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DropdownSelect;
