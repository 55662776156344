import ReactDOM from "react-dom";
import "../../styles/tagDetailsModalStyles.css";

const EditRequestDetailsModal = ({ item, onClose, btns }) => {
  return ReactDOM.createPortal(
    <div className="auctionItemDetailsModal">
      <div className="auctionItemDetailsModal-content">
        <div className="heading-aidm">
          <h2>Edit Request Details</h2>
          <button className="reject-button" onClick={onClose}>
            Close
          </button>
        </div>
        <div className="title-aidm">
          <h3>Title</h3>
          <p>{item.editReqSubject}</p>
        </div>
        <div className="title-aidm">
          <h3>Label</h3>
          <p>{item.editLabel}</p>
        </div>
        <div className="title-aidm">
          <h3>Description</h3>
          <p>{item.reqDescription ? item.reqDescription : "No Description"}</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {btns}
        </div>
      </div>
    </div>,
    document.querySelector("#tagDetailsModal")
  );
};

export default EditRequestDetailsModal;
