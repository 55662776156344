import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotificationViewModel = ({
  notification,
  onClose,
  isEmail = false,
  isSms = false,
}) => {
  return (
    <div className="auctionItemDetailsModal">
      <div className="auctionItemDetailsModal-content">
        <div className="heading-aidm">
          <h2>Notification Details</h2>
          <button className="reject-button" onClick={onClose}>
            Close
          </button>
        </div>
        {isEmail && (
          <>
            {" "}
            <div className="title-aidm">
              <h3>Subject</h3>
              <h2> {notification.subjectEnglish}</h2>
            </div>
            <div className="title-aidm">
              <h3>Email Label</h3>
              <h2>{notification.label}</h2>
            </div>
            <div className="title-aidm">
              <h3>Email English</h3>
              <p>{notification.emailEnglish}</p>
            </div>
            <div className="title-aidm">
              <h3>Email Italian</h3>
              <p>{notification.emailItalian}</p>
            </div>
          </>
        )}

        {isSms && (
          <>
            <div className="title-aidm">
              <h3>SMS Label</h3>
              <h2>{notification.label}</h2>
            </div>
            <div className="title-aidm">
              <h3>SMS English</h3>
              <p>{notification.smsEnglish}</p>
            </div>
            <div className="title-aidm">
              <h3>SMS Italian</h3>
              <p>{notification.smsItalian}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationViewModel;
