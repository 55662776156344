import ReactDOM from "react-dom";
import "../../styles/auctionItemDetailsModalStyles.css";
import ImageSlider from "../ImageSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEuro, faGavel } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";

// Function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

const AuctionItemDetailModal = ({ item, onClose, btns }) => {
  const [showOverviewTab, setOverViewTab] = useState(true);
  const [showbidderTab, setBidderTab] = useState(false);
  const [showWinner, setWinnerTab] = useState(false);
  const winner = item?.bidHistory ? item?.bidHistory[0] : null;

  const bidAmount = parseInt(winner?.bidAmount);
  const userCommission = parseInt(winner?.userCommission);
  const paidAmount = bidAmount + (bidAmount * userCommission) / 100;

  const link = `https://join.bidandgo.it/product/en/${item.auctionTitle}/${item.id}`;

  // console.log(item);
  return ReactDOM.createPortal(
    <div
      className="auctionItemDetailsModal"
      // style={{ backgroundColor: "#b1bac5" }}
      style={{ backgroundColor: "#081225" }}
    >
      <div className="details-container">
        <div className="details-nav-container">
          <div className="icon-container">
            <FontAwesomeIcon icon={faGavel} /> <h2>Auction</h2>
          </div>
          <div className="tabs-container">
            <button
              onClick={() => {
                setOverViewTab(true);
                setWinnerTab(false);
                setBidderTab(false);
              }}
              className={`${showOverviewTab && "active-btn"}`}
            >
              Overview
            </button>
            <button
              className={`${showbidderTab && "active-btn"}`}
              onClick={() => {
                setOverViewTab(false);
                setWinnerTab(false);
                setBidderTab(true);
              }}
            >
              Bidders
            </button>
            {item.status === "sold" && (
              <button
                className={`${showWinner && "active-btn"}`}
                onClick={() => {
                  setOverViewTab(false);
                  setBidderTab(false);
                  setWinnerTab(true);
                }}
              >
                Winner
              </button>
            )}
          </div>
          <div className="link-container">
            <Link target="_blank" to={link}>
              Visit
            </Link>
            <button className="close-button" onClick={onClose}>
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        </div>

        {showOverviewTab && (
          <div className="overview-container">
            <h2>Overview</h2>
            <div className="img-slider">
              <ImageSlider images={item.images} />
            </div>
            <div className="auction-overview">
              <div className="gridSpanFull tall">
                <h3>Title English</h3>
                <p className="titleCap">{item.auctionTitle}</p>
              </div>
              <div className="gridSpanFull tall ql-editor">
                <h3>Description In English</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: item.descriptionEnglish }}
                />
              </div>

              {/* <div className="gridspan"> */}
              <div className="gridSpanFull tall">
                <h3>Title Italian</h3>
                <p className="titleCap">{item.auctionTitleItalian}</p>
              </div>
              <div className="gridSpanFull tall ql-editor">
                <h3>Description In Italian</h3>
                <div
                  dangerouslySetInnerHTML={{ __html: item.descriptionItalian }}
                />
              </div>
              <div className="">
                <h3>Reserve Price</h3>
                <p>
                  <FontAwesomeIcon icon={faEuro} />
                  {item.auctionReservePrice}
                </p>
              </div>
              <div className="">
                <h3>Starting Price</h3>
                <p>
                  <FontAwesomeIcon icon={faEuro} />
                  {item.auctionStartingPrice}
                </p>
              </div>
              <div className="">
                <h3>Starting Date</h3>
                <p>{formatDate(item.startDate)}</p>
              </div>
              <div className="">
                <h3>Starting Time</h3>
                <p>
                  {format(
                    item.serverStartTimeForAuction.seconds * 1000,
                    "HH:mm"
                  )}
                </p>
                {/* <p>{formatTime(item.serverStartTimeForAuction)}</p> */}
              </div>

              <div className="">
                <h3>End Date</h3>
                <p>{formatDate(item.endDate)}</p>
              </div>

              <div className="">
                <h3>End Time</h3>
                {format(item.serverEndTimeForAuction.seconds * 1000, "HH:mm")}
                {/* <p>{formatTime(item.serverEndTimeForAuction)}</p> */}
              </div>
              <div className="gridSpanFull tall">
                <h3>Created By</h3>
                <p>{item.userEmail}</p>
              </div>
              <div className="gridSpanFull tall">
                <h3>Created At</h3>
                <p>
                  {" "}
                  {format(item.created.seconds * 1000, "MMMM d, yyyy h:mm a")}
                </p>
              </div>
              <div className="gridSpanFull tall">
                {/* <div className="gridspan tall"> */}
                <h3>Tags</h3>
                {item.tags.length > 0 && (
                  <ul className="tags-container">
                    {item.tags.map((tag, index) => (
                      <li key={index}>{tag}</li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
        {showbidderTab && (
          <>
            {" "}
            <h2 className="h2-font">Bidders</h2>
            <div className="bidders-container">
              {!item?.bidHistory?.length ? (
                <p>No Bids Yet</p>
              ) : (
                item.bidHistory.map((bid, index) => (
                  <div className="bidders-info" key={index}>
                    <div className="">
                      <h2>Email</h2>
                      <p>{bid.userEmail}</p>
                    </div>
                    <div className="">
                      <h2>Nationality</h2>
                      <p>{bid.nationality.label}</p>
                    </div>
                    <div className="">
                      <h2>Bid Amount</h2>
                      <p>
                        <FontAwesomeIcon icon={faEuro} /> {bid.bidAmount}
                      </p>
                    </div>
                    <div className="">
                      <h2>Bid Time</h2>
                      <p>
                        {format(new Date(bid.timestamp), "MMMM d, yyyy h:mm a")}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </>
        )}

        {showWinner && (
          <>
            {" "}
            <h2 className="h2-font">Winner</h2>
            <div className="bidders-container">
              {!winner ? (
                <p>No Winner</p>
              ) : (
                <div className="bidders-info">
                  <div className="">
                    <h2>Email</h2>
                    <p>{winner.userEmail}</p>
                  </div>
                  <div className="">
                    <h2>Nationality</h2>
                    <p>{winner.nationality.label}</p>
                  </div>
                  <div className="">
                    <h2>Bid Amount</h2>
                    <p>
                      <FontAwesomeIcon icon={faEuro} /> {winner.bidAmount}
                    </p>
                  </div>
                  <div className="">
                    <h2>User Commission</h2>
                    <p>{winner.userCommission}%</p>
                  </div>
                  <div className="">
                    <h2>Paid Amount</h2>
                    <p>
                      <FontAwesomeIcon icon={faEuro} /> {parseInt(paidAmount)}
                    </p>
                  </div>

                  {/* <div className="">
                    <h2>Bid Time</h2>
                    <p>
                      {format(
                        new Date(winner.timestamp),
                        "MMMM d, yyyy h:mm a"
                      )}
                    </p>
                  </div> */}
                </div>
              )}
            </div>
          </>
        )}
        {btns}
      </div>
    </div>,
    document.querySelector("#auctionItemDetailsModal")
  );
};

export default AuctionItemDetailModal;
