import ReactDOM from "react-dom";

import SubAdminForm from "./SubAdminForm";
import { useForm } from "react-hook-form";
import { db } from "../../../config/firebaseSetup";
import { doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const EditSubAdmin = ({ onClose, setLoading, getAdmins, user }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isEditing = true;

  const initialFormData = {
    auctionsActions: {
      canApprove: user.auctionsActions.canApprove,
      canDelete: user.auctionsActions.canDelete,
      canEdit: user.auctionsActions.canEdit,
      canTrash: user.auctionsActions.canTrash,
      canSuspend: user.auctionsActions.canSuspend,
    },
    tagsActions: {
      canApprove: user.tagsActions.canApprove,
      canCreate: user.tagsActions.canCreate,
      canDelete: user.tagsActions.canDelete,
      canEdit: user.tagsActions.canEdit,
      canPending: user.tagsActions.canPending,
      canTrash: user.tagsActions.canTrash,
    },
    usersActions: {
      canApprove: user.usersActions.canApprove,
      canDelete: user.usersActions.canDelete,
      canSuspend: user.usersActions.canSuspend,
    },
    categoriesActions: {
      canCreate: user.categoriesActions.canCreate,
      canDelete: user.categoriesActions.canDelete,
      canEdit: user.categoriesActions.canEdit,
    },
    adminActions: {
      canCreate: user.adminActions.canCreate,
      canDelete: user.adminActions.canDelete,
      canEdit: user.adminActions.canEdit,
    },
  };

  // const onSubmit = handleSubmit(async (data) => {
  //   setLoading(true);
  //   try {
  //     const res = await createUserWithEmailAndPassword(
  //       auth,
  //       data.email,
  //       data.password
  //     );
  //     const user = res.user;
  //     await setDoc(doc(db, "admins", user.uid), {
  //       uid: user.uid,
  //       // email: data.email,
  //       isAdmin: true,
  //       ...data,
  //       password: null,
  //     });
  //     toast.success("Sub admin created successfully");
  //     getAdmins();
  //     setLoading(false);
  //     onClose();
  //   } catch (error) {
  //     toast.success("Sub admin created successfully");
  //     setLoading(false);
  //   }
  // });

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const userRef = doc(db, "admins", user.uid);
      await updateDoc(userRef, {
        auctionsActions: {
          canApprove: data.auctionsActions.canApprove,
          canDelete: data.auctionsActions.canDelete,
          canEdit: data.auctionsActions.canEdit,
          canTrash: data.auctionsActions.canTrash,
          canSuspend: data.auctionsActions.canSuspend,
        },
        tagsActions: {
          canApprove: data.tagsActions.canApprove,
          canCreate: data.tagsActions.canCreate,
          canDelete: data.tagsActions.canDelete,
          canEdit: data.tagsActions.canEdit,
          canPending: data.tagsActions.canPending,
          canTrash: data.tagsActions.canTrash,
        },
        usersActions: {
          canApprove: data.usersActions.canApprove,
          canDelete: data.usersActions.canDelete,
          canSuspend: data.usersActions.canSuspend,
        },
        categoriesActions: {
          canCreate: data.categoriesActions.canCreate,
          canDelete: data.categoriesActions.canDelete,
          canEdit: data.categoriesActions.canEdit,
        },
      });

      toast.success("Sub admin updated successfully");
      await getAdmins();
      setLoading(false);
      onClose();
    } catch (error) {
      toast.error("Failed to update sub admin");
      setLoading(false);
    }
  });

  return ReactDOM.createPortal(
    <div className="formModal-container">
      <div className="formModal-content">
        <h2>Edit Sub Admin</h2>
        <button onClick={onClose} className="cancel-auction-creation">
          Close
        </button>
      </div>

      <div className="formModal-content">
        <h3>Email: {user.email}</h3>
      </div>

      <div className="formModal-content">
        <SubAdminForm
          onSubmit={onSubmit}
          errors={errors}
          initialFormData={initialFormData}
          control={control}
          isEditing={isEditing}
        />
      </div>
    </div>,
    document.querySelector("#subAdminFormModal")
  );
};

export default EditSubAdmin;
