import {
  faPenAlt,
  faTrashAlt,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import IconBtn from "../userAuctionsDashboard/IconBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import CreateSubAdmin from "./adminForms/CreateSubAdmin";
import Loading from "../Loader";
import { auth, db } from "../../config/firebaseSetup";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import MoreMenu from "./ActionMenu";
import EditSubAdmin from "./adminForms/EditSubAdmin";
import { toast } from "react-toastify";
import Overlay from "../Overlay";
import Modal from "../Modal";

const SubAdminManagement = () => {
  const [showCreateFormModal, setShowCreateFormModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [user, setUser] = useState(null);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const [adminData, setAdminData] = useState(null);

  let adminActions;

  if (adminData) {
    adminActions = adminData.adminActions;
  }

  const admin = auth.currentUser;
  const getUser = async () => {
    try {
      const userDocRef = doc(db, "admins", admin.uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        setAdminData(docSnap.data());
      } else {
        setAdminData(null);
      }
    } catch (error) {
      setAdminData(null);
    }
  };

  useEffect(() => {
    getUser();
  }, [admin]);

  const getAdmins = async () => {
    const adminsCollection = collection(db, "admins");

    try {
      const querySnapshot = await getDocs(adminsCollection);
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAdmins(usersData);
    } catch (error) {
      console.error("Error fetching users: ", error);
      setAdmins([]);
    }
  };

  const handleDelete = (user) => {
    // This function just show the modal to the user
    setShowDeleteUserModal(true);
    setUser(user);
  };

  const deleteUserHandler = async () => {
    // This function will be used to deleted the user
    setLoading(true);
    try {
      if (user) {
        // const userToDelete = auth.currentUser;

        const userDocRef = doc(db, "admins", user.uid);
        await deleteDoc(userDocRef);

        await getAdmins();
        setShowDeleteUserModal(false);

        setLoading(false);
        toast.success("Admin successfully deleted!");
      }
    } catch (error) {
      toast.error("Error deleting Admin");
      setShowDeleteUserModal(false);
      setLoading(false);
    }
  };

  const actionMenu = [
    adminActions?.canEdit && {
      onClick: (user) => {
        setShowEditModal(true);
        setUser(user);
      },
      icon: faPenAlt,
      color: "#ff9e00",
      text: "Edit",
    },
    adminActions?.canDelete && {
      onClick: handleDelete,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Delete",
    },
  ].filter(Boolean);

  const getActionMenu = (status) => {
    return actionMenu;
    // switch (status) {
    //   case "approved":
    //     return actionMenu;
    //   case "pending":
    //     return actionMenuNotApproved;
    //   case "trashed":
    //     return actionMenuTrashed;
    //   default:
    //     return [];
    // }
  };

  useEffect(() => {
    getAdmins();
  }, []);

  if (!admins.length) return <Loading />;

  if (!adminData) return <Loading />;

  return (
    <div>
      {loading && <Loading />}

      <h2 className="headi">Sub Admin Management</h2>
      <div
        className="create-new-auction three-items"
        style={{ marginLeft: "10px" }}
      >
        {adminActions.canCreate && (
          <IconBtn
            onClick={() => {
              // setActiveIcon(faGavel);
              setShowCreateFormModal(true);
            }}
            iconName={faUserAlt}
            // activeIcon={activeIcon}
            activeIcon={""}
          >
            <div
              className="icon-div-con"
              style={{ backgroundColor: "#e2cfea" }}
            >
              <FontAwesomeIcon icon={faUserAlt} color="purple" />
            </div>
            <span>Create Sub Admin</span>
          </IconBtn>
        )}
      </div>
      <ul className="tagsList-tagMa">
        {admins.map((admin) => (
          <div className="div" key={admin.uid}>
            <li className="li">
              <h2>{admin.email}</h2>
              <span className={`gItem aspn `}></span>
              {admins && admins.length > 1 ? (
                <span className="gItem">
                  <MoreMenu actionMenu={getActionMenu()} tag={admin} />
                </span>
              ) : null}
            </li>
          </div>
        ))}
      </ul>
      {showCreateFormModal && (
        <CreateSubAdmin
          setLoading={setLoading}
          onClose={() => setShowCreateFormModal(false)}
          getAdmins={getAdmins}
        />
      )}
      {showEditModal && (
        <EditSubAdmin
          setLoading={setLoading}
          onClose={() => setShowEditModal(false)}
          getAdmins={getAdmins}
          user={user}
        />
      )}

      {showDeleteUserModal && (
        <>
          <Overlay onClose={() => setShowDeleteUserModal(false)} />
          <Modal
            onCancel={() => {
              setShowDeleteUserModal(false);
            }}
            successText={"Delete user"}
            cancelText={"Cancel"}
            onAgree={deleteUserHandler}
          >
            <h2>Delete User</h2>
            <p>
              Are you sure you want to delete?{" "}
              <span style={{ fontWeight: "bold" }}>{user.email}</span> user
            </p>
          </Modal>
        </>
      )}
    </div>
  );
};

export default SubAdminManagement;
