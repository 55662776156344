import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "../components/InputField";
import lockIcon from "../assets/icons/lock.svg";
import emailIcon from "../assets/icons/email.svg";
import Button from "../components/Button";
import "../styles/loginPageStyle.css";
import { emailValidation, passwordValidation } from "../utils/inputValidations";
import { auth, db } from "../config/firebaseSetup";
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import Loading from "../components/Loader";
import { toast } from "react-toastify";
import { useState } from "react";

const LoginPage = ({ setUser }) => {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const methods = useForm();

  const submitHandler = methods.handleSubmit(async (formData) => {
    try {
      // console.log("form data is:", formData);
      setShowLoader(true);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      // 'userCredential.user' will give you the user object
      const user = userCredential.user;

      if (user) {
        // User logged in successfully
        // console.log("User logged in:", user.uid);
        try {
          const userDoc = await getDoc(doc(db, "admins", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            // console.log("Additional user data:", userData);
            // Do something with the additional user data
            if (userData.isAdmin === true) {
              // console.log("user is Admin ");
              localStorage.setItem("loginAdmin", JSON.stringify(user.email));
              navigate("/");
              toast.success("Login Success");
              setUser(true);
              setShowLoader(false);
              // login(user);
            } else {
              toast.error("User Not Admin");
              setShowLoader(false);
              setUser(false);
            }
          } else {
            toast.error("User not found");
            setShowLoader(false);
            // console.error("User document not found in Firestore");
          }
        } catch (error) {
          toast.error("Error logging user");
          // console.error("Error fetching user document:", error.message);
          setShowLoader(false);
        }
      } else {
        // Handle login failure
        // console.error("Error logging in: Invalid user credentials");
        toast.error("Error logging in: Invalid user credentials");
        setShowLoader(false);
      }
    } catch (err) {
      // console.error("Error during login:", err.message);
      toast.error("Invalid user credentials");
      setShowLoader(false);
    }
  });

  // const { t } = useTranslation("text");

  return (
    <div className="full-height">
      <div className="login-page">
        {/* {user ? (
        <div className="logout-tab">
          <h3>All Ready Logged In</h3>
          <Button
            onClick={() => {
              localStorage.removeItem("loginUser");
              logout();
              auth.signOut();
            }}
          >
            Log out
          </Button>
        </div>
      ) : ( */}
        <div className="logout-tab">
          <h3>Welcome back</h3>
          <p>Login to the Dashboard</p>
        </div>
        <FormProvider {...methods}>
          <form action="" onSubmit={(e) => e.preventDefault()}>
            <InputField
              type={"email"}
              placeholder={"Email"}
              name={"email"}
              id={"loginEmail"}
              icon={emailIcon}
              validationRules={emailValidation}
              label={"Email"}
            />
            <InputField
              type={"password"}
              placeholder={"Password"}
              name={"password"}
              id={"password"}
              icon={lockIcon}
              label={"Password"}
              validationRules={passwordValidation}
            />
            <Button className="login-page-btn" onClick={submitHandler}>
              Login
            </Button>
          </form>
        </FormProvider>
        {showLoader && <Loading />}
        {/* )} */}
      </div>
    </div>
  );
};

export default LoginPage;
