import { FormProvider, useForm } from "react-hook-form";
import "../../styles/auctionFormStyles.css";
import DateRangePicker from "./DatePicker";
import ImageUpload from "./ImageUpload";
import Tags from "./Tags";
import {
  descriptionEnglish,
  descriptionItalian,
  reservePrice,
  startingPrice,
  titleInput,
  titleInputItalian,
} from "../../utils/inputValidations";
import { useState } from "react";
// import { auth, db } from "../../config/firebaseSetup";
// import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import Loading from "../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CategoriesSelections from "./CategoriesSelections";
import { deleteObject, getStorage, ref } from "firebase/storage";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TextEditor from "../TextEditor";

export const renderInput = (
  { name, label, type, id, validationRules, placeholder, multiline, icon },
  methods
) => {
  const { register, formState } = methods;
  const inputErrors = formState.errors[name] || [];
  // const isInvalid = inputErrors.length > 0;

  return (
    <div className="auction-form-input">
      <label htmlFor={name}>{label}</label>
      {multiline ? (
        <textarea
          type={type}
          id={name}
          name={name}
          placeholder={placeholder}
          {...register(name, validationRules)}
        ></textarea>
      ) : (
        <div className="icon-input-contain">
          {icon && (
            <div className="iicd">
              {icon && <FontAwesomeIcon icon={icon} className="icon-input-c" />}
            </div>
          )}
          <input
            type={type}
            id={id}
            name={name}
            style={{ paddingLeft: `${icon ? "3rem" : ""}` }}
            placeholder={`Enter ${label}`}
            {...register(name, validationRules)}
          />
        </div>
      )}
      {inputErrors && <div className="inputErrors">{inputErrors?.message}</div>}
    </div>
  );
};

const AuctionForm = ({
  defaultValues,
  onCancel,
  handleSubmit,
  showLoader,
  setShowLoader,
  handleDraft,
  duplicate,
  edit = false,
}) => {
  const methods = useForm({
    defaultValues,
  });
  const [selectedfile, setSelectedFile] = useState(defaultValues.images || []);
  const [tags, setTags] = useState(defaultValues.tags || []);

  // const submitHandler = methods.handleSubmit((formData) => {
  //   handleSubmit(formData);
  // methods.reset();
  // setSelectedFile([]);
  // setTags([]);
  // });

  const submitHandler = methods.handleSubmit(async (formData) => {
    // console.log("Form data is : ", formData);
    setShowLoader(true);
    await handleSubmit(formData, methods, setSelectedFile, setTags);
    // onCancel();
    // methods.reset();
    // setSelectedFile([]);
    // setTags([]);
  });

  const saveAsDraft = async () => {
    if (!handleDraft) return;
    const draftData = methods.getValues();
    handleDraft(draftData);
    setShowLoader(true);
    methods.reset();
    setSelectedFile([]);
    setTags([]);
    onCancel();
  };

  // console.log(selectedfile);
  const handleCancelDelete = () => {
    if (edit || duplicate) return;
    const storage = getStorage();
    try {
      selectedfile.map(async (file) => {
        // console.log(file.bucket);
        const imgRef = ref(storage, `${file.bucket}/${file.filename}`);
        await deleteObject(imgRef);
      });
    } catch (error) {
      console.log(error);
    }
    // const desertRef = ref(storage, `images/${filename}`);

    // setSelectedFile(result);
    // setDeleteModal(false);
    // setValue(
    //   "images",
    //   result.map((image) => image.fileimage)
    // );
  };

  return (
    <FormProvider {...methods}>
      <form className="auction-from" onSubmit={(e) => e.preventDefault()}>
        {renderInput(titleInput, methods)}
        {/* {renderInput(descriptionEnglish, methods)} */}
        <div className="editor-container-af auction-form-input">
          <label>Description English</label>
          <TextEditor
            name={"descriptionEnglish"}
            defaultValue={defaultValues.descriptionEnglish}
          />
          {methods.formState.errors.descriptionEnglish && (
            <div className="inputErrors">
              {
                (methods.formState.errors.descriptionEnglish.message,
                {
                  ns: "errors",
                })
              }
            </div>
          )}
        </div>
        {renderInput(titleInputItalian, methods)}
        {/* {renderInput(descriptionItalian, methods)} */}
        <div className="editor-container-af auction-form-input">
          <label>Description Italian</label>
          <TextEditor
            name={"descriptionItalian"}
            defaultValue={defaultValues.descriptionItalian}
          />
          {methods.formState.errors.descriptionItalian && (
            <div className="inputErrors">
              {
                (methods.formState.errors.descriptionItalian.message,
                {
                  ns: "errors",
                })
              }
            </div>
          )}
        </div>
        {renderInput(startingPrice, methods)}
        {renderInput(reservePrice, methods)}
        <Tags tags={tags} setTags={setTags} clearErrors={methods.clearErrors} />
        <CategoriesSelections
          defaultValues={{
            subcategory: defaultValues.subcategory,
            category: defaultValues.category,
          }}
        />
        <DateRangePicker
          defaultStartDate={defaultValues.startDate}
          defaultEndDate={defaultValues.endDate}
        />
        <DndProvider backend={HTML5Backend}>
          <ImageUpload
            selectedfile={selectedfile}
            setSelectedFile={setSelectedFile}
            clearErrors={methods.clearErrors}
            setShowLoader={setShowLoader}
          />
        </DndProvider>
        {/* <ImageUpload
          selectedfile={selectedfile}
          setSelectedFile={setSelectedFile}
          clearErrors={methods.clearErrors}
        /> */}

        <div className="auction-form-buttons">
          {edit ? (
            <button onClick={submitHandler} className="submit-auction">
              Save And Send For Approval
            </button>
          ) : duplicate ? (
            <button onClick={submitHandler} className="submit-auction">
              Duplicate And Send For Approval
            </button>
          ) : (
            <>
              <button onClick={submitHandler} className="submit-auction">
                Send For Approval
              </button>
              <button onClick={saveAsDraft} className="draft-auction">
                Save As Draft
              </button>
            </>
          )}
          <button
            className="auction-cancel"
            onClick={() => {
              onCancel();
              handleCancelDelete();
            }}
          >
            Cancel
          </button>
        </div>
      </form>
      {showLoader && <Loading />}
    </FormProvider>
  );
};

export default AuctionForm;
