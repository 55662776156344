import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import "../../styles/auctionItemAdminStyles.css";
import { useEffect, useState } from "react";
import Loading from "../Loader";
import TagsManagement from "./TagManagement";

const TagsSideTab = () => {
  const [tagsFromFirebase, setTagsFromFirebase] = useState();

  const [adminData, setAdminData] = useState(null);

  const admin = auth.currentUser;
  const getUser = async () => {
    try {
      const userDocRef = doc(db, "admins", admin.uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        setAdminData(docSnap.data());
      } else {
        setAdminData(null);
      }
    } catch (error) {
      setAdminData(null);
    }
  };

  const fetchTags = () => {
    try {
      const tagsCollection = collection(db, "tags");
      const unsubscribe = onSnapshot(tagsCollection, (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTagsFromFirebase(data);
      });

      return unsubscribe;
    } catch (error) {
      setTagsFromFirebase([]);
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    const unsubscribe = fetchTags();
    return () => unsubscribe(); // Clean up the subscription when the component unmounts
  }, []);

  // useEffect(() => {
  //   fetchTags();
  // }, []);

  useEffect(() => {
    getUser();
  }, [admin]);

  if (!tagsFromFirebase) {
    return <Loading />;
  }

  if (!adminData) {
    return <Loading />;
  }

  return (
    <div>
      <TagsManagement tagsFromFirebase={tagsFromFirebase} admin={adminData} />
    </div>
  );
};

export default TagsSideTab;
