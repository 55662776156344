import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../config/firebaseSetup";
import IconBtn from "../userAuctionsDashboard/IconBtn";
import "../../styles/editAuctionStyle.css";
import {
  faCheckCircle,
  faClock,
  faList,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "rc-pagination";
import MoreMenu from "./ActionMenu";
import Loading from "../Loader";
import EditRequestDetailsModal from "./EditRequestDetailsModal";
import EditAuction from "../userAuctionsDashboard/EditAuction";
import { toast } from "react-toastify";

const Card = ({ item, clickHandler, actionMenuPending }) => {
  return (
    <div className="edit-auction-item" onClick={() => clickHandler(item)}>
      <div>
        <div
          className="bgImg-admin"
          style={{
            backgroundImage: `url(${
              item.images ? item.images[0]?.fileimage : ""
            })`,
          }}
        ></div>
      </div>
      <h3>{item?.editReqSubject}</h3>
      <p>{item?.reqDescription}</p>
      <span
        className={`span ${item?.status}Status sta`}
        style={{ textTransform: "capitalize" }}
      >
        {item?.status}
      </span>

      <div className="a-btns">
        <MoreMenu
          tag={item}
          actionMenu={item.status === "pending" && actionMenuPending}
          // actionMenu={item.data.status === '' ? actionMenu : actionMenuNotApproved}
          // tag={tag}
        />
      </div>
    </div>
  );
};

const EditAuctionManagement = () => {
  const [editRequests, setEditRequests] = useState([]);
  const [auctions, setAuctions] = useState([]);
  const [activeIcon, setActiveIcon] = useState(faList);
  const [activeTab, setActiveTab] = useState("All");
  const [displayAuctions, setDisplayAuctions] = useState(auctions);
  const [current, setCurrent] = useState(1);
  const [size, setSize] = useState(10);
  const [auctionItem, setAuctionItem] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [editAuction, setEditAuction] = useState(null);
  const [showEditFormModal, setShowEditFormModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const getData = (current, pageSize) => {
    return displayAuctions.slice((current - 1) * pageSize, current * pageSize);
  };

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  useEffect(() => {
    if (showEditFormModal || showDetailsModal) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [showEditFormModal, showDetailsModal]);

  useEffect(() => {
    if (editRequests) {
      handleTabClick(activeTab);
    }
  }, [editRequests]);

  useEffect(() => {
    const editRequestsCollection = collection(db, "editRequests");
    const unsubscribeEditRequests = onSnapshot(
      editRequestsCollection,
      (snapshot) => {
        const updatedEditRequests = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEditRequests(updatedEditRequests);
        updatedEditRequests.forEach(async (request) => {
          try {
            // Assuming 'auctionId' is a field in the edit request
            const { auctionId } = request;

            const auctionDocRef = doc(db, "Auction", auctionId);
            // Fetch data from the document reference
            const auctionDocSnapshot = await getDoc(auctionDocRef);
            // Check if the document exists
            if (auctionDocSnapshot.exists()) {
              const auctionData = {
                id: auctionId,
                ...auctionDocSnapshot.data(),
              };
              setAuctions((prevAuctions) => {
                // Check if the item already exists in the state
                if (
                  prevAuctions.some((auction) => auction.id === auctionData.id)
                ) {
                  return prevAuctions; // Item already exists, do not add again
                }
                // Item doesn't exist, add it to the state

                return [...prevAuctions, auctionData];
              });
            } else {
              console.log("Auction document does not exist");
            }
          } catch (error) {
            setShowLoader(false);
            console.error("Error processing edit request:", error);
          }
        });
      }
    );

    // Clean up the subscription when the component unmounts
    return () => unsubscribeEditRequests();
  }, []);

  // Filter tags based on status
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const filteredTags = editRequests.filter((auction) => {
      switch (tab) {
        case "completed".toLowerCase():
          return auction?.status?.toLowerCase() === "completed".toLowerCase();
        case "Pending".toLowerCase():
          return auction?.status?.toLowerCase() === "pending".toLowerCase();
        default:
          return true;
      }
    });
    setDisplayAuctions(filteredTags);
  };

  const clickHandler = (item) => {
    setAuctionItem(item);
    setShowDetailsModal(true);
  };

  const handleEdit = (auction) => {
    // This function is used to show modal only
    setAuctionItem(auction);
    const editAuctionItem = auctions.find((a) => a.id === auction.auctionId);
    setEditAuction(editAuctionItem);
    setShowEditFormModal(true);
  };

  const reqCompleteHandler = async () => {
    // This function is used to complete user edit request
    setShowLoader(true);
    try {
      // Reference to the specific document in the "editRequests" collection
      const auctionDocRef = doc(db, "editRequests", auctionItem.id);

      // Update the document with status: completed
      await updateDoc(auctionDocRef, {
        status: "completed",
      });

      toast.success("Auction Edit Request Completed");
      setShowLoader(false);
    } catch (error) {
      toast.error("Error Auction Edit Request Completion");
      setShowLoader(false);
    }
  };

  const actionMenuPending = [
    {
      onClick: handleEdit,
      icon: faPen,
      text: "Edit",
      color: "#ff9e00",
    },
  ].filter(Boolean);

  const auctionDetailsActions = (status) => {
    // console.log(status);
    switch (status) {
      case "pending":
        return (
          <div className="a-btns">
            <button
              className="approve-button"
              onClick={() => {
                handleEdit(auctionItem);
              }}
            >
              Edit
            </button>
            <button
              className="reject-button"
              onClick={() => {
                setShowDetailsModal(false);
                setAuctionItem(null);
              }}
            >
              Close
            </button>
          </div>
        );
      default:
        return (
          <button
            className="reject-button"
            onClick={() => {
              setShowDetailsModal(false);
              setAuctionItem(null);
            }}
          >
            Close
          </button>
        );
    }
  };

  if (editRequests.length < 1) {
    return <h2>No Items</h2>;
  }

  return (
    <div>
      {showLoader && <Loading />}
      <div
        className="create-new-auction"
        // style={{ paddingLeft: "1rem", margin: "1rem 0" }}
      >
        <IconBtn
          onClick={() => {
            setActiveIcon(faList);
            // setShowCreateFormModal(true);
            handleTabClick("All");
          }}
          iconName={faList}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#e2cfea" }}>
            <FontAwesomeIcon icon={faList} color="purple" />
          </div>
          <span>All</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faCheckCircle);
            handleTabClick("completed");
          }}
          iconName={faCheckCircle}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#c7f9cc" }}
          >
            <FontAwesomeIcon icon={faCheckCircle} color="#25a18e" />
          </div>
          <span>Completed</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faClock);
            handleTabClick("pending");
          }}
          iconName={faClock}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#fff2b2" }}>
            <FontAwesomeIcon icon={faClock} color="#ddd92a" />
          </div>
          <span>Pending</span>
        </IconBtn>
      </div>
      <div className="edit-req-container">
        {displayAuctions.length === 0 ? (
          <div>No items to display</div>
        ) : (
          getData(current, size).map((item, index) => (
            <div key={index} className="filtered-div">
              <div className="auction-list">
                <Card
                  item={item}
                  clickHandler={clickHandler}
                  actionMenuPending={actionMenuPending}
                />
              </div>
            </div>
          ))
        )}
      </div>
      <div className="pagination" style={{ marginTop: "1rem" }}>
        <Pagination
          showTotal={(total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total}`
          }
          onChange={PaginationChange}
          total={displayAuctions.length}
          current={current}
          pageSize={size}
          showSizeChanger={false}
          showPrevNextJumpers={true}
          jumpPrevIcon={"Prev 5"}
          jumpNextIcon={"Next 5"}
          showTitle={false}
        />
      </div>
      {showDetailsModal && (
        <EditRequestDetailsModal
          onClose={() => {
            setShowDetailsModal(false);
          }}
          item={auctionItem}
          btns={auctionDetailsActions(auctionItem.status)}
        />
      )}
      {showEditFormModal && (
        <EditAuction
          editAuction={editAuction}
          onClose={() => {
            setShowEditFormModal(false);
            // setEditAuction(null);
          }}
          getAllAuctions={reqCompleteHandler}
          setAuctionItem={setEditAuction}
        />
      )}
    </div>
  );
};

export default EditAuctionManagement;
