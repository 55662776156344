import { FormProvider, useForm } from "react-hook-form";
import { renderInput } from "./userAuctionsDashboard/AuctionForm";
import { tosValidation } from "../utils/inputValidations";
const TOSEditModal = ({ onClose, onSubmit, defaultValues }) => {
  const methods = useForm({ defaultValues: { ...defaultValues } });

  const submitHandler = methods.handleSubmit((formData) => {
    onSubmit(formData);
  });

  return (
    <FormProvider {...methods}>
      <div className="formModal-content">
        <h2>Term of Service Edit</h2>
        <button onClick={onClose} className="cancel-auction-creation">
          Close
        </button>
      </div>
      <div>
        <form className="auction-from" onSubmit={(e) => e.preventDefault()}>
          {renderInput(tosValidation, methods)}
        </form>
      </div>
      <div className="auction-form-buttons" style={{ marginRight: "2.5rem" }}>
        <button onClick={submitHandler} className="submit-auction">
          Save
        </button>
      </div>
    </FormProvider>
  );
};

export default TOSEditModal;
