import { useEffect, useState } from "react";
import Loading from "./Loader";
import MoreMenu from "./dashboardComponents/ActionMenu";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebaseSetup";
import { toast } from "react-toastify";
import TOSEditModal from "./TOSEditModal";
import PPEditModal from "./PPEditModal";

const ContentEdit = () => {
  const [loading, setLoading] = useState(false);
  const [showTOSEditModal, setTOSEditModal] = useState(false);
  const [showPPEditModal, setPPEditModal] = useState(false);
  const [ppContent, setPpContent] = useState(null);
  const [tosContent, setTosContent] = useState(null);

  useEffect(() => {
    if (showTOSEditModal || showPPEditModal) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [showTOSEditModal, showPPEditModal]);

  const fetchContent = async () => {
    setLoading(true);
    try {
      const ppDocRef = doc(db, "websiteContent", "ppContent");
      const ppDocSnap = await getDoc(ppDocRef);

      const tosDocRef = doc(db, "websiteContent", "tosContent");
      const tosDocSnap = await getDoc(tosDocRef);

      if (ppDocSnap.exists()) {
        setPpContent(ppDocSnap.data().content);
      } else {
        toast.error("No Privacy Policy content found!");
      }

      if (tosDocSnap.exists()) {
        setTosContent(tosDocSnap.data().content);
      } else {
        toast.error("No Terms of Service content found!");
      }
    } catch (error) {
      toast.error("Error fetching website content: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  const actionMenuPP = [
    {
      onClick: () => {
        setPPEditModal(true);
      },
      icon: faPen,
      color: "#80ed99",
      text: "Edit",
    },
  ];

  const actionMenuTos = [
    {
      onClick: () => {
        setTOSEditModal(true);
      },
      icon: faPen,
      color: "#80ed99",
      text: "Edit",
    },
  ];

  const handleTosEdit = async (formData) => {
    setLoading(true);
    try {
      const tosDocRef = doc(db, "websiteContent", "tosContent");
      await updateDoc(tosDocRef, {
        content: formData.termOfService,
      });

      toast.success("TOS updated successfully!");
      setTOSEditModal(false);
      fetchContent();
    } catch (error) {
      console.log(error);
      toast.error("Error updating TOS!");
    } finally {
      setLoading(false);
    }
  };

  const handlePPEdit = async (formData) => {
    setLoading(true);
    try {
      const tosDocRef = doc(db, "websiteContent", "ppContent");
      await updateDoc(tosDocRef, {
        content: formData.privacyPolicy,
      });

      toast.success("Privacy Policy updated successfully!");
      setPPEditModal(false);
      fetchContent();
    } catch (error) {
      console.log(error);
      toast.error("Error updating Privacy Policy!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loading />}
      <h2 className="headi">Website Content Management</h2>
      <div className="content-container">
        {!ppContent && !loading ? (
          <h2>No Privacy Policy Content</h2>
        ) : (
          <div
            className="content-div"
            onClick={() => {
              // setNotification(notification);
              // setShowNotification(true);
            }}
          >
            <h2>Privacy Policy</h2>
            {/* {ppContent && <div dangerouslySetInnerHTML={{ __html: ppContent }} />} */}
            <span className="gItem">
              <MoreMenu actionMenu={actionMenuPP} tag={ppContent} />
            </span>
          </div>
        )}
        {!tosContent && !loading ? (
          <h2>No Term of Service Content</h2>
        ) : (
          <div
            className="content-div"
            onClick={() => {
              // setNotification(notification);
              // setShowNotification(true);
            }}
          >
            <h2>Terms of Service</h2>
            {/* {tosContent && (
            <div dangerouslySetInnerHTML={{ __html: tosContent }} />
          )} */}
            <span className="gItem">
              <MoreMenu actionMenu={actionMenuTos} tag={ppContent} />
            </span>
          </div>
        )}
      </div>
      {showTOSEditModal && (
        <div className="formModal-container">
          <div className="auction-from">
            <TOSEditModal
              // methods={methods}
              defaultValues={{
                termOfService: tosContent,
              }}
              onClose={() => setTOSEditModal(false)}
              onSubmit={handleTosEdit}
            />
          </div>
        </div>
      )}

      {showPPEditModal && (
        <div className="formModal-container">
          <div className="auction-from">
            <PPEditModal
              // methods={methods}
              defaultValues={{
                privacyPolicy: ppContent,
              }}
              onClose={() => setPPEditModal(false)}
              onSubmit={handlePPEdit}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentEdit;
