import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../config/firebaseSetup";
import Loading from "../Loader";
import MoreMenu from "./ActionMenu";
import {
  faCheckCircle,
  faList,
  faPauseCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import "../../styles/reportsManagementStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconBtn from "../userAuctionsDashboard/IconBtn";
import Pagination from "rc-pagination";
import { toast } from "react-toastify";
import Overlay from "../Overlay";
import Modal from "../Modal";
import ReportedAuctionDetailModal from "./ReportedAuctionDetails";

const ReportManagement = () => {
  const [reports, setReports] = useState(null);
  const [activeIcon, setActiveIcon] = useState(faList);
  const [displayTags, setDisplayTags] = useState(reports || []);
  const [activeTab, setActiveTab] = useState("All");
  const [current, setCurrent] = useState(1);
  const [size, setSize] = useState(10);
  // Show Modals
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [item, setItem] = useState(null);

  // // Get All reports from firebase
  // const getAllReports = async () => {
  //   try {
  //     // Reference to the "Reports" collection
  //     const reportsColRef = collection(db, "reports");
  //     const allReports = query(reportsColRef);

  //     const querySnapshot = await getDocs(allReports);

  //     const reportsData = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     setReports(reportsData);
  //   } catch (error) {
  //     console.error("Error getting documents: ", error);
  //     setReports([]);
  //   }
  // };
  const getAllReports = () => {
    try {
      // Reference to the "Reports" collection
      const reportsColRef = collection(db, "reports");
      const allReports = query(reportsColRef);

      // Subscribe to real-time updates
      const unsubscribe = onSnapshot(allReports, (querySnapshot) => {
        const reportsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setReports(reportsData);
      });

      // Return the unsubscribe function to detach the listener when needed
      return unsubscribe;
    } catch (error) {
      console.error("Error getting documents: ", error);
      setReports([]);
    }
  };

  useEffect(() => {
    // Start real-time updates and get the unsubscribe function
    const unsubscribe = getAllReports();
    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  // call get All reports from firebase
  // useEffect(() => {
  //   getAllReports();
  // }, []);

  useEffect(() => {
    if (showDetails) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [showDetails]);

  // Set array for paginations
  const getData = (current, pageSize) => {
    return displayTags.slice((current - 1) * pageSize, current * pageSize);
  };

  // Filter reports based on auction status
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    const filteredTags = reports.filter((report) => {
      switch (tab) {
        case "Approved":
          return (
            report.auctionStatus?.toLowerCase() === "approved".toLowerCase()
          );
        case "Suspended":
          return (
            report?.auctionStatus?.toLowerCase() === "suspended".toLowerCase()
          );
        default:
          return true;
      }
    });
    setDisplayTags(filteredTags);
  };

  useEffect(() => {
    if (reports) {
      setDisplayTags(reports);
      handleTabChange(activeTab);
    }
  }, [reports]);

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const handleApprove = (auction) => {
    // This function is used to show modal only
    setShowApproveModal(true);
    setItem(auction);
  };

  const approveHandler = async () => {
    // This function is used to approve user auction
    setShowLoader(true);
    try {
      // Reference to the specific document in the "Auction" collection
      const auctionDocRef = doc(db, "Auction", item.itemId);
      // Update the document with isApproved: true
      await updateDoc(auctionDocRef, {
        status: "approved",
      });
      await updateReports("approved", item.id);
      setShowApproveModal(false);
      toast.success("Auction Approved");
      // await getAllReports();
      setShowLoader(false);
      // console.log("Approved");
    } catch (error) {
      toast.error("Error Approving Auction");
      setShowLoader(false);
      console.error("Error updating document: ", error);
    }
  };

  const updateReports = async (statusUpdate, reportId) => {
    try {
      // Reference to the specific document in the "reports" collection based on the report ID
      const reportDocRef = doc(db, "reports", reportId);
      await updateDoc(reportDocRef, {
        auctionStatus: statusUpdate,
      });
      toast.success("Report status updated");
    } catch (error) {
      toast.error("Error updating report status");
      console.error("Error updating document: ", error);
    }
  };

  const handleSuspend = (auction) => {
    // This function is used to show modal only
    setShowSuspendModal(true);
    setItem(auction);
  };

  const suspendHandler = async () => {
    // This function is used to suspend user auction
    setShowLoader(true);
    try {
      // Reference to the specific document in the "Auction" collection
      const auctionDocRef = doc(db, "Auction", item.itemId);

      // Update the document with isApproved: true
      await updateDoc(auctionDocRef, {
        status: "suspended",
      });
      await updateReports("suspended", item.id);
      setShowSuspendModal(false);
      // await getAllReports();
      toast.success("Auction Suspended");
      setShowLoader(false);
    } catch (error) {
      toast.error("Error Auction Suspension");
      setShowSuspendModal(false);
      setShowLoader(false);
    }
  };

  const handleDelete = (auction) => {
    // This function is used to show modal only
    setShowDeleteModal(true);
    setItem(auction);
  };

  const deleteHandler = async () => {
    // This function is used to delete  user auction
    setShowLoader(true);
    try {
      if (item) {
        const auctionDocRef = doc(db, "Auction", item.itemId);
        const reportDocRef = doc(db, "reports", item.id);
        await deleteDoc(auctionDocRef);
        await deleteDoc(reportDocRef);
        setShowDeleteModal(false);
        // await getAllReports();
        setShowLoader(false);
        toast.success("Auction successfully deleted!");
        toast.success("Report deleted successfully");
      }
    } catch (error) {
      toast.error("Error deleting");
      setShowDeleteModal(false);
      setShowLoader(false);
    }
  };

  if (!reports) {
    return <Loading />;
  }

  // console.log(reports);
  const actionMenuNotApproved = [
    {
      onClick: handleApprove,
      icon: faCheckCircle,
      color: "#57cc99",
      text: "Approve",
    },
    {
      onClick: handleDelete,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Delete",
    },
  ];

  const actionMenu = [
    {
      onClick: handleSuspend,
      icon: faPauseCircle,
      color: "#ff9e00",
      text: "Suspend",
    },
    {
      onClick: handleDelete,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Delete",
    },
  ];

  const reportList = getData(current, size).map((item) => (
    <div
      key={item.id}
      className="report-item"
      onClick={() => {
        setShowDetails(true);
        setItem(item);
      }}
    >
      <div>
        <div
          className="bgImg-reports"
          style={{
            backgroundImage: `url(${item?.images[0]?.fileimage})`,
          }}
        ></div>
      </div>
      <h3>{item?.auctionTitle}</h3>
      <p>Report({item?.reports.length})</p>
      <span
        className={`span ${item?.auctionStatus}Status`}
        style={{ textTransform: "capitalize" }}
      >
        {item?.auctionStatus}
      </span>
      {/* <span className="span">{item?.endDate}</span>
      <span className="span">{item?.startDate}</span> */}

      <div className="a-btns">
        <MoreMenu
          tag={item}
          // actionMenu={actionMenuNotApproved}
          actionMenu={
            item.auctionStatus === "approved"
              ? actionMenu
              : actionMenuNotApproved
          }
        />
      </div>
    </div>
  ));

  return (
    <div>
      {showLoader && <Loading />}
      <h2 className="heading">Reports Management</h2>
      <div
        className="create-new-auction"
        style={{ paddingLeft: "1rem", margin: "1rem 0" }}
      >
        <IconBtn
          onClick={() => {
            setActiveIcon(faList);
            handleTabChange("All");
          }}
          iconName={faList}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#e2cfea" }}>
            <FontAwesomeIcon icon={faList} color="purple" />
          </div>
          <span>All</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faCheckCircle);
            handleTabChange("Approved");
          }}
          iconName={faCheckCircle}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#c7f9cc" }}
          >
            <FontAwesomeIcon icon={faCheckCircle} color="#25a18e" />
          </div>
          <span>Approved</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faPauseCircle);
            handleTabChange("Suspended");
          }}
          iconName={faPauseCircle}
          activeIcon={activeIcon}
        >
          <div
            className="icon-div-con"
            style={{ backgroundColor: "#ffb72789" }}
          >
            <FontAwesomeIcon icon={faPauseCircle} color="#fb8500" />
          </div>
          <span>Suspended</span>
        </IconBtn>
      </div>
      <div className="reports-con">
        {reports ? (
          reportList.length ? (
            reportList
          ) : (
            <h2>No Items</h2>
          )
        ) : (
          <h2>No Items</h2>
        )}
      </div>
      <div className="pagination">
        <Pagination
          showTotal={(total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total}`
          }
          onChange={PaginationChange}
          total={displayTags.length}
          current={current}
          pageSize={size}
          showSizeChanger={false}
          showPrevNextJumpers={true}
          jumpPrevIcon={"Prev 5"}
          jumpNextIcon={"Next 5"}
          showTitle={false}
        />
      </div>
      {showSuspendModal && (
        <>
          <Overlay onClose={() => setShowSuspendModal(false)} />
          <Modal
            onCancel={() => {
              setShowSuspendModal(false);
            }}
            successText={"Suspend Auction"}
            cancelText={"Cancel"}
            onAgree={suspendHandler}
          >
            <h2>Suspend Auction</h2>
            <p>
              Are you sure you want to Suspend{" "}
              <span style={{ fontWeight: "bold" }}>{item?.auctionTitle}</span>{" "}
              auction
            </p>
          </Modal>
        </>
      )}

      {showApproveModal && (
        <>
          <Overlay onClose={() => setShowApproveModal(false)} />
          <Modal
            onCancel={() => {
              setShowApproveModal(false);
            }}
            successText={"Approve Auction"}
            cancelText={"Cancel"}
            onAgree={approveHandler}
          >
            <h2>Approve Auction</h2>
            <p>
              Are you sure you want to approve
              <span style={{ fontWeight: "bold" }}> {item.auctionTitle} </span>
              auction
            </p>
          </Modal>
        </>
      )}

      {showDeleteModal && (
        <>
          <Overlay onClose={() => setShowDeleteModal(false)} />
          <Modal
            onCancel={() => {
              setShowDeleteModal(false);
            }}
            successText={"Delete Auction"}
            cancelText={"Cancel"}
            onAgree={deleteHandler}
          >
            <h2>Delete Auction</h2>
            <p>
              Are you sure you want to reject
              <span style={{ fontWeight: "bold" }}> {item.auctionTitle} </span>
              auction
            </p>
          </Modal>
        </>
      )}
      {showDetails && (
        <ReportedAuctionDetailModal
          auction={item}
          onClose={() => {
            setShowDetails(false);
            setItem(null);
          }}
        />
      )}
    </div>
  );
};

export default ReportManagement;
