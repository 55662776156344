import React, { useEffect, useState } from "react";
import "../../styles/auctionsListStyles.css";
import AuctionItemDetailModal from "./AuctionItemDetailModal";
import { db } from "../../config/firebaseSetup";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import IconBtn from "../userAuctionsDashboard/IconBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../Modal";
import Overlay from "../Overlay";
import {
  faCheckCircle,
  faClock,
  faList,
  faPauseCircle,
  faTrashAlt,
  faLock,
  faPen,
  faTimesCircle,
  faCheckSquare,
  faClose,
  faHourglass,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import MoreMenu from "./ActionMenu";
import { toast } from "react-toastify";
import Loading from "../Loader";
import Pagination from "rc-pagination";
import EditAuction from "../userAuctionsDashboard/EditAuction";
import noImg from "../../assets/images/no-image.png";
import { FormProvider, useForm } from "react-hook-form";
import { renderInput } from "../userAuctionsDashboard/AuctionForm";
import { rejectReasonValidation } from "../../utils/inputValidations";
import fetchAndSendEmailTemplate, {
  fetchAndSendEmailReasonTemplate,
} from "../../utils/emailSend";
import { deleteObject, getStorage, ref } from "firebase/storage";
import Dropdown from "../DropDownFilter";

// Function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

const Card = ({
  item,
  clickHandler,
  actionMenu,
  actionMenuNotApproved,
  actionMenuSuspended,
  actionMenuTrashed,
}) => {
  // console.log(parsedImages[0].flie);

  const getActionMenu = (status) => {
    switch (status) {
      case "approved":
        return actionMenu;
      case "suspended":
      case "rejected":
        return actionMenuSuspended;
      case "trashed":
        return actionMenuTrashed;
      default:
        return actionMenuNotApproved;
    }
  };

  return (
    <div className="auction-item" onClick={() => clickHandler(item)}>
      <div>
        {/* <div
          className="bgImg-admin"
          style={{
            backgroundImage: `url(${
              item.images && item.images.length
                ? item?.images[0]?.fileimage
                : noImg
            })`,
          }}
        ></div> */}
        <img
          className="bgImg-admin"
          src={
            item.images && item.images.length
              ? item?.images[0]?.fileimage
              : noImg
          }
          alt="Auction"
        />
      </div>
      <h3>
        {item?.auctionTitle} <span className="tagSpace">#</span> {item.tag}
      </h3>
      {/* <p
        className="single-line"
        dangerouslySetInnerHTML={{ __html: item?.descriptionEnglish }}
      ></p> */}
      <span
        className={`span ${item?.status}Status sta`}
        style={{ textTransform: "capitalize" }}
      >
        {item?.status}
      </span>
      <span className="span">{formatDate(item?.startDate)}</span>
      <span className="span">{formatDate(item?.endDate)}</span>

      <div className="a-btns">
        <MoreMenu
          tag={item}
          actionMenu={getActionMenu(item?.status)}
          // actionMenu={item.data.status === '' ? actionMenu : actionMenuNotApproved}
          // tag={tag}
        />
      </div>
    </div>
  );
};

const Filter = ({ auctions, user, selectedText, setSelectedText, items }) => {
  const [activeTab, setActiveTab] = useState("All");
  // FilterTabs
  const [activeIcon, setActiveIcon] = useState(faList);
  // const [showCreateFormModal, setShowCreateFormModal] = useState(false);
  const auctionsActions = user.auctionsActions;

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  //   onTabClick(tab);
  // };

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  // FIlter div/Show modal
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditFormModal, setShowEditFormModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [auctionItem, setAuctionItem] = useState(null);
  const [item, setItem] = useState(null);
  const [current, setCurrent] = useState(1);
  const [size, setSize] = useState(20);
  const [rejectReasonModal, setRejectReasonModal] = useState(false);
  // set pagination state
  const [displayAuctions, setDisplayAuctions] = useState(auctions);

  const getData = (current, pageSize) => {
    return displayAuctions.slice((current - 1) * pageSize, current * pageSize);
  };

  const methods = useForm();

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  useEffect(() => {
    if (showEditFormModal || showDetailsModal) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [showEditFormModal, showDetailsModal]);

  // const { getAllAuctions } = useContext(Context);

  // Filter tags based on status
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    const filteredTags = auctions.filter((auction) => {
      switch (tab) {
        case "Approved".toLowerCase():
          return auction?.status?.toLowerCase() === "approved".toLowerCase();
        case "Sold".toLowerCase():
          return auction?.status?.toLowerCase() === "sold".toLowerCase();
        case "Pending".toLowerCase():
          return auction?.status?.toLowerCase() === "pending".toLowerCase();
        case "Suspended".toLowerCase():
          return auction?.status?.toLowerCase() === "suspended".toLowerCase();
        case "Closed".toLowerCase():
          return auction?.status?.toLowerCase() === "closed".toLowerCase();
        case "rejected".toLowerCase():
          return auction?.status?.toLowerCase() === "rejected".toLowerCase();
        case "Trashed".toLowerCase():
          return auction?.status?.toLowerCase() === "trashed".toLowerCase();
        case "Payment_failed".toLowerCase():
          return (
            auction?.status?.toLowerCase() === "payment_failed".toLowerCase()
          );
        case "Pending_payment".toLowerCase():
          return (
            auction?.status?.toLowerCase() === "Pending_payment".toLowerCase()
          );
        default:
          return true;
      }
    });
    setDisplayAuctions(filteredTags);
  };

  useEffect(() => {
    if (auctions) {
      setDisplayAuctions(auctions);
      handleTabClick(activeTab);
    }
  }, [auctions]);

  const clickHandler = (item) => {
    setAuctionItem(item);
    setShowDetailsModal(true);
  };

  // const filteredItems =
  //   activeTab === "All"
  //     ? filterAuctions
  //     : filterAuctions.filter(
  //         (div) => div?.data.status?.toLowerCase() === activeTab.toLowerCase()
  //       );
  // console.log(activeTab);
  // const filteredItems =
  //   activeTab === "All"
  //     ? filterAuctions
  //     : filterAuctions.filter(
  //         (div) => div.data.status.toLowerCase() === activeTab?.toLowerCase()
  //       );

  const handleApprove = (auction) => {
    // This function is used to show modal only
    setShowApproveModal(true);
    setItem(auction);
  };

  const approveHandler = async () => {
    setShowLoader(true);
    try {
      const auctionDocRef = doc(db, "Auction", item.id);
      // Update the status in the Auction collection
      await updateDoc(auctionDocRef, { status: "approved" });

      // Update the status in the Reports collection if the item exists
      const reportsSnapshot = await getDocs(collection(db, "reports"));
      const reportToUpdate = reportsSnapshot.docs.find((report) => {
        return report.data().itemId === item.id;
      });

      if (reportToUpdate) {
        const reportDocRef = doc(db, "reports", reportToUpdate.id);
        await updateDoc(reportDocRef, { auctionStatus: "approved" });
      }

      toast.success("Auction Approved");
      // await getAllAuctions();
      setShowApproveModal(false);

      const updatedAuctionSnapshot = await getDoc(auctionDocRef);
      const updatedAuctionData = updatedAuctionSnapshot.data();
      const updatedAuctionWithId = {
        ...updatedAuctionData,
        id: updatedAuctionSnapshot.id,
      };
      setAuctionItem(updatedAuctionWithId);
      setShowLoader(false);

      const userInfo = {
        email: updatedAuctionWithId.userEmail,
        uid: updatedAuctionWithId.uid,
      };
      await fetchAndSendEmailTemplate(
        "For Auction Accept",
        userInfo,
        "https://stripe-server-production-3c2c.up.railway.app/send-email"
      );
    } catch (error) {
      toast.error("Error Approving Auction");
      setShowLoader(false);
      console.error("Error updating document: ", error);
    }
  };

  // const approveHandler = async () => {
  //   // This function is used to approve user auction
  //   setShowLoader(true);
  //   try {
  //     // Reference to the specific document in the "Auction" collection
  //     const auctionDocRef = doc(db, "Auction", item.id);
  //     // Update the document with isApproved: true
  //     await updateDoc(auctionDocRef, {
  //       status: "approved",
  //     });
  //     setShowApproveModal(false);
  //     toast.success("Auction Approved");
  //     getAllAuctions();

  //     const updatedAuctionSnapshot = await getDoc(auctionDocRef);
  //     const updatedAuctionData = updatedAuctionSnapshot.data();
  //     const updatedAuctionWithId = {
  //       ...updatedAuctionData,
  //       id: updatedAuctionSnapshot.id,
  //     };

  //     setAuctionItem(updatedAuctionWithId);

  //     setShowLoader(false);

  //     // console.log("Approved");
  //   } catch (error) {
  //     toast.error("Error Approving Auction");
  //     setShowLoader(false);
  //     console.error("Error updating document: ", error);
  //   }
  // };

  const handleReject = (auction) => {
    // This function is used to show modal only
    setShowRejectModal(true);
    setItem(auction);
  };

  const rejectHandler = async (rejectReason) => {
    // This function is used to reject user auction
    setShowLoader(true);
    try {
      // Reference to the specific document in the "Auction" collection
      const auctionDocRef = doc(db, "Auction", item.id);

      // Update the document with isApproved: true
      await updateDoc(auctionDocRef, {
        status: "rejected",
        rejectReason: rejectReason,
      });

      // Update the status in the Reports collection if the item exists
      const reportsSnapshot = await getDocs(collection(db, "reports"));
      const reportToUpdate = reportsSnapshot.docs.find((report) => {
        return report.data().itemId === item.id;
      });

      if (reportToUpdate) {
        const reportDocRef = doc(db, "reports", reportToUpdate.id);
        await updateDoc(reportDocRef, { auctionStatus: "rejected" });
      }

      toast.success("Auction Rejected");
      // await getAllAuctions();
      setShowRejectModal(false);

      const updatedAuctionSnapshot = await getDoc(auctionDocRef);
      const updatedAuctionData = updatedAuctionSnapshot.data();
      const updatedAuctionWithId = {
        ...updatedAuctionData,
        id: updatedAuctionSnapshot.id,
      };

      setAuctionItem(updatedAuctionWithId);
      setShowLoader(false);
      setRejectReasonModal(false);

      const userInfo = {
        email: updatedAuctionWithId.userEmail,
        uid: updatedAuctionWithId.uid,
      };
      fetchAndSendEmailReasonTemplate(
        "For Auction Reject",
        userInfo,
        "https://stripe-server-production-3c2c.up.railway.app/send-email",
        rejectReason
      );
    } catch (error) {
      toast.error("Error Auction Rejection");
      setShowLoader(false);
    }
  };

  const handleSuspend = (auction) => {
    // This function is used to show modal only
    setShowSuspendModal(true);
    setItem(auction);
  };

  const suspendHandler = async () => {
    // This function is used to suspend user auction
    setShowLoader(true);
    try {
      // Reference to the specific document in the "Auction" collection
      const auctionDocRef = doc(db, "Auction", item.id);

      // Update the document with isApproved: true
      await updateDoc(auctionDocRef, {
        status: "suspended",
      });

      // Update the status in the Reports collection if the item exists
      const reportsSnapshot = await getDocs(collection(db, "reports"));
      const reportToUpdate = reportsSnapshot.docs.find((report) => {
        return report.data().itemId === item.id;
      });

      if (reportToUpdate) {
        const reportDocRef = doc(db, "reports", reportToUpdate.id);
        await updateDoc(reportDocRef, { auctionStatus: "suspended" });
      }

      // await getAllAuctions();
      setShowSuspendModal(false);
      toast.success("Auction Suspended");
      const updatedAuctionSnapshot = await getDoc(auctionDocRef);
      const updatedAuctionData = updatedAuctionSnapshot.data();
      const updatedAuctionWithId = {
        ...updatedAuctionData,
        id: updatedAuctionSnapshot.id,
      };

      setAuctionItem(updatedAuctionWithId);

      setShowLoader(false);
    } catch (error) {
      toast.error("Error Auction Suspension");
      setShowSuspendModal(false);
      setShowLoader(false);
    }
  };

  const handleTrash = (auction) => {
    // This function is used to show modal only
    setShowTrashModal(true);
    setItem(auction);
  };

  const trashHandler = async () => {
    // This function is used to trash  user auction
    setShowLoader(true);
    try {
      // Reference to the specific document in the "Auction" collection
      const auctionDocRef = doc(db, "Auction", item.id);
      // Update the document with isApproved: true
      await updateDoc(auctionDocRef, {
        status: "trashed",
      });

      // Update the status in the Reports collection if the item exists
      const reportsSnapshot = await getDocs(collection(db, "reports"));
      const reportToUpdate = reportsSnapshot.docs.find((report) => {
        return report.data().itemId === item.id;
      });

      if (reportToUpdate) {
        const reportDocRef = doc(db, "reports", reportToUpdate.id);
        await updateDoc(reportDocRef, { auctionStatus: "trashed" });
      }

      toast.success("Auction Moved To Trash");
      // await getAllAuctions();
      setShowTrashModal(false);

      const updatedAuctionSnapshot = await getDoc(auctionDocRef);
      const updatedAuctionData = updatedAuctionSnapshot.data();
      const updatedAuctionWithId = {
        ...updatedAuctionData,
        id: updatedAuctionSnapshot.id,
      };

      setAuctionItem(updatedAuctionWithId);

      setShowLoader(false);
    } catch (error) {
      setShowTrashModal(false);
      toast.error("Error Auction Moving to Trash");
      setShowLoader(false);
    }
  };

  const handleDelete = (auction) => {
    // This function is used to show modal only
    setShowDeleteModal(true);
    setItem(auction);
  };

  // const deleteHandler = async () => {
  // This function is used to delete  user auction
  // setShowLoader(true);
  // try {
  //   if (item) {
  //     const auctionDocRef = doc(db, "Auction", item.id);
  //     await deleteDoc(auctionDocRef);
  //     toast.success("Auction successfully deleted!");
  //     setShowDeleteModal(false);
  //     if (showDetailsModal) {
  //       setShowDetailsModal(false);
  //     }
  // Fetch the updated list of auctions after deletion
  // getAllAuctions();
  // const updatedAuctionSnapshot = await getDoc(auctionDocRef);
  // const updatedAuctionData = updatedAuctionSnapshot.data();
  // const updatedAuctionWithId = {
  //   ...updatedAuctionData,
  //   id: updatedAuctionSnapshot.id,
  // };

  // setAuctionItem(updatedAuctionWithId);

  //       setShowLoader(false);
  //     }
  //   } catch (error) {
  //     toast.error("Error deleting Auction");
  //     setShowDeleteModal(false);
  //     setShowLoader(false);
  //   }
  // };

  const deleteHandler = async () => {
    setShowLoader(true);
    try {
      if (item) {
        const auctionDocRef = doc(db, "Auction", item.id);
        const storage = getStorage();
        const docSnapshot = await getDoc(auctionDocRef);
        const images = docSnapshot.data().images;

        images.map(async (file) => {
          const imgRef = ref(storage, `images/${file.filename}`);
          await deleteObject(imgRef);
        });

        await deleteDoc(auctionDocRef);
        toast.success("Auction successfully deleted!");

        // Check if the item exists in reports collection
        const reportsSnapshot = await getDocs(collection(db, "reports"));
        const reportToDelete = reportsSnapshot.docs.find((report) => {
          return report.data().itemId === item.id;
        });

        if (reportToDelete) {
          const reportDocRef = doc(db, "reports", reportToDelete.id);
          await deleteDoc(reportDocRef);
          // toast.success("Report deleted successfully");
        }

        setShowDeleteModal(false);
        if (showDetailsModal) {
          setShowDetailsModal(false);
        }
        // await getAllAuctions();
        setShowLoader(false);
      }
    } catch (error) {
      toast.error("Error deleting Auction");
      setShowDeleteModal(false);
      setShowLoader(false);
    }
  };

  const handleEdit = (auction) => {
    // This function is used to show modal only
    setShowEditFormModal(true);
    setItem(auction);
  };

  const rejectReasonHandler = methods.handleSubmit((formData) => {
    rejectHandler(formData.rejectReason);
  });

  // console.log(item);

  const actionMenuNotApproved = [
    auctionsActions.canEdit && {
      onClick: handleEdit,
      icon: faPen,
      text: "Edit",
      color: "#ff9e00",
    },
    auctionsActions.canApprove && {
      onClick: handleApprove,
      icon: faCheckCircle,
      color: "#57cc99",
      text: "Approve",
    },
    auctionsActions.canSuspend && {
      onClick: handleReject,
      icon: faTimesCircle,
      color: "#ef233c",
      text: "Reject",
    },
    auctionsActions.canSuspend && {
      onClick: handleSuspend,
      icon: faPauseCircle,
      color: "#ff9e00",
      text: "Suspend",
    },
    auctionsActions.canTrash && {
      onClick: handleTrash,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Trash",
    },
  ].filter(Boolean);

  const actionMenuSuspended = [
    auctionsActions.canEdit && {
      onClick: handleEdit,
      icon: faPen,
      text: "Edit",
      color: "#ff9e00",
    },
    auctionsActions.canApprove && {
      onClick: handleApprove,
      icon: faCheckCircle,
      color: "#57cc99",
      text: "Approve",
    },
    auctionsActions.canTrash && {
      onClick: handleTrash,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Trash",
    },
  ].filter(Boolean);

  const actionMenuTrashed = [
    auctionsActions.canEdit && {
      onClick: handleEdit,
      icon: faPen,
      text: "Edit",
      color: "#ff9e00",
    },
    auctionsActions.canApprove && {
      onClick: handleApprove,
      icon: faCheckCircle,
      color: "#57cc99",
      text: "Approve",
    },
    auctionsActions.canSuspend && {
      onClick: handleSuspend,
      icon: faPauseCircle,
      color: "#ff9e00",
      text: "Suspend",
    },
    auctionsActions.canDelete && {
      onClick: handleDelete,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Delete",
    },
  ].filter(Boolean);

  const actionMenu = [
    auctionsActions.canEdit && {
      onClick: handleEdit,
      icon: faPen,
      text: "Edit",
      color: "#ff9e00",
    },
    auctionsActions.canSuspend && {
      onClick: handleSuspend,
      icon: faPauseCircle,
      color: "#ff9e00",
      text: "Suspend",
    },
    auctionsActions.canTrash && {
      onClick: handleTrash,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Trash",
    },
  ].filter(Boolean);

  // const getItemPre = () => {
  //   if (auctionItem) {
  //     const item = auctions.filter((auction) => auction.id === auctionItem.id);
  //     setAuctionItem(item);
  //   }
  // };

  const auctionDetailsActions = (status) => {
    // console.log(status);
    switch (status) {
      case "approved":
        return (
          <div className="actions-btns">
            {auctionsActions.canEdit && (
              <button
                className="edit-action-btn"
                onClick={() => handleEdit(auctionItem)}
              >
                Edit
                <FontAwesomeIcon icon={faPen} className="icon-auction-button" />
              </button>
            )}
            {auctionsActions.canSuspend && (
              <button
                className="suspend-action-btn"
                onClick={() => handleSuspend(auctionItem)}
              >
                Suspend
                <FontAwesomeIcon
                  icon={faPauseCircle}
                  className="icon-auction-button"
                />
              </button>
            )}
            {auctionsActions.canTrash && (
              <button
                className="delete-action-btn"
                onClick={() => handleTrash(auctionItem)}
              >
                Trash
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="icon-auction-button"
                />
              </button>
            )}
            <button
              className="delete-action-btn"
              onClick={() => {
                setShowDetailsModal(false);
                setAuctionItem(null);
              }}
            >
              Close
              <FontAwesomeIcon icon={faClose} className="icon-auction-button" />
            </button>
          </div>
        );
      case "suspended":
        return (
          <div className="actions-btns">
            {auctionsActions.canEdit && (
              <button
                className="edit-action-btn"
                onClick={() => handleEdit(auctionItem)}
              >
                Edit
                <FontAwesomeIcon icon={faPen} className="icon-auction-button" />
              </button>
            )}
            {auctionsActions.canApprove && (
              <button
                className="edit-action-btn"
                onClick={() => handleApprove(auctionItem)}
              >
                Approve
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="icon-auction-button"
                />
              </button>
            )}
            {auctionsActions.canTrash && (
              <button
                className="delete-action-btn"
                onClick={() => handleTrash(auctionItem)}
              >
                Trash
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="icon-auction-button"
                />
              </button>
            )}
            <button
              className="delete-action-btn"
              onClick={() => {
                setShowDetailsModal(false);
                setAuctionItem(null);
              }}
            >
              Close
              <FontAwesomeIcon icon={faClose} className="icon-auction-button" />
            </button>
          </div>
        );
      case "closed":
      case "pending":
        return (
          <div className="actions-btns">
            {auctionsActions.canEdit && (
              <button
                className="edit-action-btn"
                onClick={() => handleEdit(auctionItem)}
              >
                Edit
                <FontAwesomeIcon icon={faPen} className="icon-auction-button" />
              </button>
            )}
            {auctionsActions.canApprove && (
              <button
                className="edit-action-btn"
                onClick={() => handleApprove(auctionItem)}
              >
                Approve
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="icon-auction-button"
                />
              </button>
            )}
            {auctionsActions.canSuspend && (
              <button
                className=" suspended"
                onClick={() => handleSuspend(auctionItem)}
              >
                Suspend
                <FontAwesomeIcon
                  icon={faPauseCircle}
                  className="icon-auction-button"
                />
              </button>
            )}
            {auctionsActions.canTrash && (
              <button
                className="delete-action-btn"
                onClick={() => handleTrash(auctionItem)}
              >
                Trash
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="icon-auction-button"
                />
              </button>
            )}
            <button
              className="delete-action-btn"
              onClick={() => {
                setShowDetailsModal(false);
                setAuctionItem(null);
              }}
            >
              Close
              <FontAwesomeIcon icon={faClose} className="icon-auction-button" />
            </button>
          </div>
        );
      case "trashed":
        return (
          <div className="actions-btns">
            {auctionsActions.canEdit && (
              <button
                className="edit-action-btn"
                onClick={() => handleEdit(auctionItem)}
              >
                Edit
                <FontAwesomeIcon icon={faPen} className="icon-auction-button" />
              </button>
            )}
            {auctionsActions.canApprove && (
              <button
                className=" edit-action-btn"
                onClick={() => handleApprove(auctionItem)}
              >
                Approve
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="icon-auction-button"
                />
              </button>
            )}
            {auctionsActions.canSuspend && (
              <button
                className=" suspend-action-btn"
                onClick={() => handleSuspend(auctionItem)}
              >
                Suspend
                <FontAwesomeIcon
                  icon={faPauseCircle}
                  className="icon-auction-button"
                />
              </button>
            )}
            {auctionsActions.canDelete && (
              <button
                className="delete-action-btn"
                onClick={() => handleDelete(auctionItem)}
              >
                Delete
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="icon-auction-button"
                />
              </button>
            )}
            <button
              className="delete-action-btn"
              onClick={() => {
                setShowDetailsModal(false);
                setAuctionItem(null);
              }}
            >
              Close
              <FontAwesomeIcon icon={faClose} className="icon-auction-button" />
            </button>
          </div>
        );
      case "sold":
      case "payment_failed":
      case "pending_payment":
        return (
          <div className="actions-btns">
            <button
              className="delete-action-btn"
              onClick={() => {
                setShowDetailsModal(false);
                setAuctionItem(null);
              }}
            >
              Close
              <FontAwesomeIcon icon={faClose} className="icon-auction-button" />
            </button>
          </div>
        );
      default:
        return (
          <button
            className="delete-action-btn"
            onClick={() => {
              setShowDetailsModal(false);
              setAuctionItem(null);
            }}
          >
            Close
            <FontAwesomeIcon icon={faClose} className="icon-auction-button" />
          </button>
        );
    }
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase().trim();

    // Check if the searchQuery is empty
    if (searchQuery === "") {
      handleTabClick(activeTab);
      setCurrent(1);
    } else {
      // Split the searchQuery on whitespace or "#"
      const searchTerms = searchQuery.split(/\s+|#/);

      // Filter auctions based on the searchQuery
      const filteredAuctions = auctions.filter((auction) => {
        // Check if at least one search term matches the title or tag
        const titleMatch = searchTerms.some((term) =>
          auction.auctionTitle.toLowerCase().includes(term)
        );
        const tagMatch = searchTerms.some((term) =>
          auction?.tag?.toLowerCase().includes(term)
        );

        // Return true if at least one search term matches the title or tag
        return titleMatch || tagMatch;
      });

      setDisplayAuctions(filteredAuctions);
      setCurrent(1);
    }

    // Check for backspace key press (keyCode 8)
    if (event.keyCode === 8 && searchQuery === "") {
      handleTabClick(activeTab);
      setCurrent(1);
    }
  };

  return (
    <div>
      <h2 className="heading">Auctions Management</h2>
      {/* <FilterTabs onTabClick={handleTabClick} setActiveTab={setActiveTab} /> */}
      <div
        className="create-new-auction"
        // style={{ paddingLeft: "1rem", margin: "1rem 0" }}
      >
        <IconBtn
          onClick={() => {
            setActiveIcon(faList);
            // setShowCreateFormModal(true);
            handleTabClick("All");
          }}
          iconName={faList}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#e2cfea" }}>
            <FontAwesomeIcon icon={faList} color="purple" />
          </div>
          <span>All</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faCheckCircle);
            handleTabClick("approved");
          }}
          iconName={faCheckCircle}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#c7f9cc" }}
          >
            <FontAwesomeIcon icon={faCheckCircle} color="#25a18e" />
          </div>
          <span>Approved</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faTimesCircle);
            handleTabClick("rejected");
          }}
          iconName={faTimesCircle}
          activeIcon={activeIcon}
        >
          <div
            className="icon-div-con"
            style={{ backgroundColor: "#e01e386b" }}
          >
            <FontAwesomeIcon icon={faTimesCircle} color="#d90429" />
          </div>
          <span>Rejected</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faClock);
            handleTabClick("pending");
          }}
          iconName={faClock}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#fff2b2" }}>
            <FontAwesomeIcon icon={faClock} color="#ddd92a" />
          </div>
          <span>Pending</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faCheckSquare);
            handleTabClick("sold");
          }}
          iconName={faCheckSquare}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#c7f9cc" }}
          >
            <FontAwesomeIcon icon={faCheckSquare} color="#25a18e" />
          </div>
          <span>Sold Successfully</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faHourglass);
            handleTabClick("pending_payment");
          }}
          iconName={faHourglass}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#fff2b2" }}>
            <FontAwesomeIcon icon={faHourglass} color="#ddd92a" />
          </div>
          <span>Payment Pending</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faExclamationTriangle);
            handleTabClick("payment_failed");
          }}
          iconName={faExclamationTriangle}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#e01e386b" }}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} color="#d90429" />
          </div>
          <span>Payment Failed</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faPauseCircle);
            handleTabClick("suspended");
          }}
          iconName={faPauseCircle}
          activeIcon={activeIcon}
        >
          <div
            className="icon-div-con"
            style={{ backgroundColor: "#ffb72789" }}
          >
            <FontAwesomeIcon icon={faPauseCircle} color="#fb8500" />
          </div>
          <span>Suspended</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faLock);
            handleTabClick("closed");
          }}
          iconName={faLock}
          activeIcon={activeIcon}
        >
          <div
            className="icon-div-con"
            style={{ backgroundColor: "#e01e386b" }}
          >
            <FontAwesomeIcon icon={faLock} color="#d90429" />
          </div>
          <span>Closed</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faTrashAlt);
            handleTabClick("trashed");
          }}
          iconName={faTrashAlt}
          activeIcon={activeIcon}
        >
          <div
            className="icon-div-con"
            style={{ backgroundColor: "#e01e386b" }}
          >
            <FontAwesomeIcon icon={faTrashAlt} color="#d90429" />
          </div>
          <span>Trash</span>
        </IconBtn>
      </div>
      {/* <FilteredDivs
        activeTab={activeTab}
        filterAuctions={auctions}
        getAllAuctions={getAllAuctions}
      /> */}
      <div className="dropdown-container">
        <h2>Order By</h2>
        <Dropdown
          selectedText={selectedText}
          setSelectedText={setSelectedText}
          items={items}
        />
      </div>
      <div style={{ padding: "0 1rem" }}>
        <input
          type="text"
          className="user-search-field"
          onChange={handleSearch}
          placeholder="Search auctions by full name or tag"
        />
      </div>

      <>
        {showLoader && <Loading />}
        <div className="container">
          {displayAuctions.length === 0 ? (
            <div style={{ margin: "1rem 0" }}>No items to display</div>
          ) : (
            getData(current, size).map((item, index) => (
              <div key={index} className="filtered-div">
                <div className="auction-list">
                  <Card
                    item={item}
                    clickHandler={clickHandler}
                    actionMenu={actionMenu}
                    actionMenuNotApproved={actionMenuNotApproved}
                    actionMenuSuspended={actionMenuSuspended}
                    actionMenuTrashed={actionMenuTrashed}
                  />
                </div>
              </div>
            ))
          )}
        </div>
        <div className="pagination" style={{ marginTop: "1rem" }}>
          <Pagination
            showTotal={(total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total}`
            }
            onChange={PaginationChange}
            total={displayAuctions.length}
            current={current}
            pageSize={size}
            showSizeChanger={false}
            showPrevNextJumpers={true}
            jumpPrevIcon={"Prev 5"}
            jumpNextIcon={"Next 5"}
            showTitle={false}
          />
        </div>
        {showDetailsModal && (
          <AuctionItemDetailModal
            onClose={() => {
              setShowDetailsModal(false);
              setAuctionItem(null);
            }}
            btns={auctionDetailsActions(auctionItem.status.toLowerCase())}
            item={auctionItem}
          />
        )}

        {showSuspendModal && (
          <>
            <Overlay onClose={() => setShowSuspendModal(false)} />
            <Modal
              onCancel={() => {
                setShowSuspendModal(false);
              }}
              successText={"Suspend Auction"}
              cancelText={"Cancel"}
              onAgree={suspendHandler}
            >
              <h2>Suspend Auction</h2>
              <p>
                Are you sure you want to Suspend{" "}
                <span style={{ fontWeight: "bold" }}>{item?.auctionTitle}</span>{" "}
                auction
              </p>
            </Modal>
          </>
        )}
        {showTrashModal && (
          <>
            <Overlay onClose={() => setShowTrashModal(false)} />
            <Modal
              onCancel={() => {
                setShowTrashModal(false);
              }}
              successText={"Move to Trash"}
              cancelText={"Cancel"}
              onAgree={trashHandler}
            >
              <h2>Move To Trash</h2>
              <p>
                Are you sure you want to move this item
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {item.auctionTitle}
                </span>{" "}
                to trash auction
              </p>
            </Modal>
          </>
        )}
        {showApproveModal && (
          <>
            <Overlay onClose={() => setShowApproveModal(false)} />
            <Modal
              onCancel={() => {
                setShowApproveModal(false);
              }}
              successText={"Approve Auction"}
              cancelText={"Cancel"}
              onAgree={approveHandler}
            >
              <h2>Approve Auction</h2>
              <p>
                Are you sure you want to approve
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {item.auctionTitle}{" "}
                </span>
                auction
              </p>
            </Modal>
          </>
        )}

        {showRejectModal && (
          <>
            <Overlay onClose={() => setShowRejectModal(false)} />
            <Modal
              onCancel={() => {
                setShowRejectModal(false);
              }}
              successText={"Reject Auction"}
              cancelText={"Cancel"}
              onAgree={() => setRejectReasonModal(true)}
            >
              <h2>Reject Auction</h2>
              <p>
                Are you sure you want to reject
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {item.auctionTitle}{" "}
                </span>
                auction
              </p>
            </Modal>
          </>
        )}

        {showDeleteModal && (
          <>
            <Overlay onClose={() => setShowDeleteModal(false)} />
            <Modal
              onCancel={() => {
                setShowDeleteModal(false);
              }}
              successText={"Delete Auction"}
              cancelText={"Cancel"}
              onAgree={deleteHandler}
            >
              <h2>Delete Auction</h2>
              <p>
                Are you sure you want to delete
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {item.auctionTitle}{" "}
                </span>
                auction
              </p>
            </Modal>
          </>
        )}
        {showEditFormModal && (
          <EditAuction
            editAuction={item}
            // getAllAuctions={getAllAuctions}
            onClose={() => setShowEditFormModal(false)}
            setAuctionItem={setAuctionItem}
          />
        )}

        {rejectReasonModal && (
          <>
            <Overlay onClose={() => setRejectReasonModal(false)} />
            <Modal
              onCancel={() => {
                setRejectReasonModal(false);
                methods.setValue(rejectReasonValidation.name, "");
              }}
              successText={"Reject Reason"}
              cancelText={"Cancel"}
              onAgree={rejectReasonHandler}
            >
              <h2>Create Category</h2>
              <FormProvider {...methods}>
                <form
                  className="auction-from"
                  onSubmit={(e) => e.preventDefault()}
                >
                  {renderInput(rejectReasonValidation, methods)}
                </form>
              </FormProvider>
            </Modal>
          </>
        )}
      </>
    </div>
  );
};

export default Filter;
