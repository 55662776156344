import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/actionsMenuStyle.css";
import "../../styles/userAuctionsStyles.css";

function MoreMenu({ actionMenu, tag, className }) {
  const [isActive, setIsActive] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const handler = (e) => {
      if (!ref.current) {
        return;
      }
      if (!ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("click", handler, true);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  const toggleActive = (e) => {
    e.stopPropagation();
    setIsActive(!isActive);
  };

  return (
    <div ref={ref} className={`list-container  ${isActive ? "active" : ""}`}>
      <button
        className="more-button"
        aria-label="Menu Button"
        onClick={toggleActive}
      >
        <div className="menu-icon-wrapper">
          <div className="menu-icon-line half first"></div>
          <div className="menu-icon-line"></div>
          <div className="menu-icon-line half last"></div>
        </div>
      </button>
      <ul className={`more-button-list ${className}`}>
        {!actionMenu.length ? (
          <li
            onClick={(e) => e.stopPropagation()}
            className="more-button-list-item"
          >
            No Actions
          </li>
        ) : (
          actionMenu.map((action) => {
            return (
              <li
                key={action.text}
                onClick={(e) => {
                  e.stopPropagation();

                  if (tag) {
                    action.onClick(tag);
                    return;
                  }
                  action.onClick();
                }}
                className="more-button-list-item"
              >
                <FontAwesomeIcon
                  style={{ color: action.color }}
                  icon={action.icon}
                />
                <span style={{ border: "0" }}>{action.text}</span>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
}

export default MoreMenu;
