import { Routes, Route } from "react-router-dom";
import AuctionManagement from "../components/dashboardComponents/AuctionManagement.jsx";
import UserManagement from "../components/dashboardComponents/UserManagement.jsx";
import CategoriesManagement from "../components/dashboardComponents/CategoriesManagement.jsx";
import ReportManagement from "../components/dashboardComponents/ReportManagement.jsx";
import SubAdminManagement from "../components/dashboardComponents/SubAdminManagement.jsx";
import TagsSideTab from "../components/dashboardComponents/TagsSideTab.jsx";
import Logout from "../components/Logout.jsx";
import EditAuctionManagement from "../components/dashboardComponents/EditAuctionManagement.jsx";
import NotificationsManagement from "../components/dashboardComponents/NotificationsManagement.jsx";
import NotificationsLogs from "../components/dashboardComponents/NotificationsLogs.jsx";
import AdminConversationList from "../components/messagingSystem/AdminConversationList.jsx";
import AdminConversationView from "../components/messagingSystem/AdminConversationView.jsx";
import ContentEdit from "../components/ContentEdit.jsx";

const AppRouter = ({ user, setUser, adminData }) => {
  return (
    <div className="contain">
      <Routes>
        {/* <Route path="/" exact element={<LoginPage />} /> */}
        <Route path="/" exact element={<AuctionManagement />} />
        <Route path="/admin/user-management" element={<UserManagement />} />
        <Route path="/admin/tags-management" element={<TagsSideTab />} />
        <Route
          path="/admin/categories-management"
          element={<CategoriesManagement />}
        />
        <Route path="/admin/report-management" element={<ReportManagement />} />
        <Route
          path="/admin/edit-auction-management"
          element={<EditAuctionManagement />}
        />
        <Route
          path="/admin/notifications-management"
          element={<NotificationsManagement />}
        />
        <Route
          path="/admin/notifications-logs"
          element={<NotificationsLogs />}
        />
        <Route path="/admin/messages" element={<AdminConversationList />} />
        <Route
          path="/admin/conversation/:conversationId"
          element={<AdminConversationView />}
        />
        <Route path="/admin/edit-website-content" element={<ContentEdit />} />
        {adminData?.isSuperAdmin && (
          <Route
            path="admin/subadmin-management"
            element={<SubAdminManagement />}
          />
        )}

        <Route path="admin/logout" element={<Logout setUser={setUser} />} />
      </Routes>
    </div>
  );
};

export default AppRouter;
