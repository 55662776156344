import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import "../../styles/adminDashboardSidebar.css";
import { auth, db } from "../../config/firebaseSetup";
import { collection, onSnapshot } from "firebase/firestore";

const AdminDashboardSidebar = ({ tabsList, setUser }) => {
  const [isActive, setIsActive] = useState(false);
  const [auctionsHasPending, setAuctionsHasPending] = useState(false);
  const [usersHasPending, setUsersHasPending] = useState(false);
  const [tagsHasPending, setTagsHasPending] = useState(false);
  const [reportsHasPending, setReportsHasPending] = useState(false);
  const [editReqHasPending, setEditReqHasPending] = useState(false);

  const auctionsCollection = () => {
    try {
      const collectionRef = collection(db, "Auction");
      const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
        let hasPending = false;
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.status === "pending") {
            hasPending = true;
          }
        });
        setAuctionsHasPending(hasPending);
      });
      return unsubscribe;
    } catch (error) {
      console.error("Error checking for pending items in Auction ", error);
    }
  };

  const tagsCollection = () => {
    try {
      const collectionRef = collection(db, "tags");
      const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
        let hasPending = false;
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.status === "pending") {
            hasPending = true;
          }
        });
        setTagsHasPending(hasPending);
      });
      return unsubscribe;
    } catch (error) {
      console.error("Error checking for pending items in Tags ", error);
    }
  };

  const usersCollection = () => {
    try {
      const collectionRef = collection(db, "users");
      const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
        let hasPending = false;
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.status === "pending" || data.status === "suspended") {
            hasPending = true;
          }
        });
        setUsersHasPending(hasPending);
      });
      return unsubscribe;
    } catch (error) {
      console.error("Error checking for pending items in Users ", error);
    }
  };

  const editsCollection = () => {
    try {
      const collectionRef = collection(db, "editRequests");
      const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
        let hasPending = false;
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.status === "pending") {
            hasPending = true;
          }
        });
        setEditReqHasPending(hasPending);
      });
      return unsubscribe;
    } catch (error) {
      console.error("Error checking for pending items in editRequests ", error);
    }
  };

  const reportsCollection = () => {
    try {
      const collectionRef = collection(db, "reports");
      const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
        let hasPending = false;
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.auctionStatus === "approved") {
            hasPending = true;
          }
        });
        setReportsHasPending(hasPending);
      });
      return unsubscribe;
    } catch (error) {
      console.error("Error checking for pending items in reports ", error);
    }
  };

  useEffect(() => {
    const unsubscribeAuctionsCollection = auctionsCollection();
    const unsubscribeEditsCollection = editsCollection();
    const unsubscribeUsersCollection = usersCollection();
    const unsubscribeReportsCollection = reportsCollection();
    const unsubscribeTagsCollection = tagsCollection();

    return () => {
      if (unsubscribeAuctionsCollection) unsubscribeAuctionsCollection();
      if (unsubscribeEditsCollection) unsubscribeEditsCollection();
      if (unsubscribeUsersCollection) unsubscribeUsersCollection();
      if (unsubscribeReportsCollection) unsubscribeReportsCollection();
      if (unsubscribeTagsCollection) unsubscribeTagsCollection();
    };
  }, []);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  // useEffect(() => {
  //   if (isActive) {
  //     document.body.classList.add("body-overflow");
  //   } else {
  //     document.body.classList.remove("body-overflow");
  //   }
  // }, [isActive]);

  return (
    <div>
      <header className="adminHeader">
        <div className="toggle" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <h3>Dashboard</h3>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            localStorage.removeItem("loginAdmin");
            // logout();
            setUser(null);
            auth.signOut();
          }}
        >
          <FontAwesomeIcon icon={faSignOutAlt} />
        </div>
      </header>
      <nav className={`nav ${isActive ? "active" : ""}`}>
        <ul>
          <li>
            <div className="toggle" onClick={toggleSidebar}>
              <span className="icon">
                <FontAwesomeIcon icon={faBars} />
              </span>
              <span className="title">Menu</span>
            </div>
          </li>
          {tabsList.map((tab, idx) => (
            <li
              key={idx}
              onClick={(e) => {
                e.preventDefault();
                // setCurrentActiveTab(idx);
                tab.onClick();
                setIsActive(false);
              }}
            >
              <div>
                <span className="icon">
                  {idx === 0 && auctionsHasPending && (
                    <div className="smallDot"></div>
                  )}
                  {idx === 1 && usersHasPending && (
                    <div className="smallDot"></div>
                  )}
                  {idx === 2 && tagsHasPending && (
                    <div className="smallDot"></div>
                  )}
                  {idx === 4 && reportsHasPending && (
                    <div className="smallDot"></div>
                  )}
                  {idx === 5 && editReqHasPending && (
                    <div className="smallDot"></div>
                  )}
                  <FontAwesomeIcon icon={tab.icon} />
                </span>
                <span className="title">{tab.title}</span>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default AdminDashboardSidebar;
