import {
  faCheckCircle,
  faClock,
  faClose,
  faList,
  faPenAlt,
  faTags,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import IconBtn from "../userAuctionsDashboard/IconBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import MoreMenu from "./ActionMenu";
import "../../styles/tagManagementStyle.css";
import Pagination from "rc-pagination";
import { renderInput } from "../userAuctionsDashboard/AuctionForm";
import { FormProvider, useForm } from "react-hook-form";
import { editTagValidation, tagValidation } from "../../utils/inputValidations";
import Modal from "../Modal";
import Overlay from "../Overlay";
import { toast } from "react-toastify";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import Loading from "../Loader";
import TagDetailModal from "./TagDetailsModal";
import TagsCreationForm from "./TagsCreationForm";

const TagsManagement = ({ tagsFromFirebase, admin }) => {
  //Show  Modals
  const [showCreateFormModal, setShowCreateFormModal] = useState(false);
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);
  const [showApproveTagModal, setShowApproveTagModal] = useState(false);
  const [showPendingTagModal, setShowPendingTagModal] = useState(false);
  const [showTrashModal, setShowTrashModal] = useState(false);
  const [showTagDetailsModal, setShowTagDetailsModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const adminActions = admin.tagsActions;

  //
  const [isEditing, setIsEditing] = useState(false);
  const [activeIcon, setActiveIcon] = useState(faList);
  const [current, setCurrent] = useState(1);
  const [size, setSize] = useState(30);
  const [editingTagId, setEditingTagId] = useState(null);
  const [tag, setTag] = useState(null);

  // Filter tags
  // const pendingTags = tagsFromFirebase?.filter((tag) => !tag?.approved);
  // const approvedTags = tagsFromFirebase?.filter((tag) => tag?.approved);

  const [activeTab, setActiveTab] = useState("approved");

  // set pagination state
  const [displayTags, setDisplayTags] = useState(tagsFromFirebase);

  const methods = useForm();
  const inputErrors = methods.formState.errors[editTagValidation.name] || [];

  const getData = (current, pageSize) => {
    return displayTags.slice((current - 1) * pageSize, current * pageSize);
  };

  useEffect(() => {
    if (showTagDetailsModal) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [showTagDetailsModal]);

  // Filter tags based on status
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    const filteredTags = tagsFromFirebase.filter((tag) => {
      switch (tab) {
        case "Approved":
          return tag?.status?.toLowerCase() === "approved".toLowerCase();
        case "Pending":
          return tag?.status?.toLowerCase() === "pending".toLowerCase();
        case "Trashed":
          return tag?.status?.toLowerCase() === "trashed".toLowerCase();
        default:
          return true;
      }
    });
    setDisplayTags(filteredTags);
  };

  useEffect(() => {
    if (tagsFromFirebase) {
      setDisplayTags(tagsFromFirebase);
      handleTabChange(activeTab);
    }
  }, [tagsFromFirebase]);

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const handleApprove = (tag) => {
    // This function is used to show the model to the user
    setShowApproveTagModal(true);
    setTag(tag);
  };

  const approveTagHandler = async () => {
    // This function will be used to approve user requested tag
    setShowLoader(true);
    try {
      // Reference to the specific document in the "Tags" collection
      const tagDocRef = doc(db, "tags", tag.id);
      // Update the document with status: approved
      await updateDoc(tagDocRef, {
        status: "approved",
      });
      toast.success("Tag Approved");
      setShowApproveTagModal(false);

      // await fetchTags();
      setShowLoader(false);
    } catch (error) {
      setShowApproveTagModal(false);
      toast.error("Error Auction Approving");
      setShowLoader(false);
    }
  };

  const handlePending = (tag) => {
    // This function is used to show the reject model to the user
    setShowPendingTagModal(true);
    setTag(tag);
  };

  const pendingTagHandler = async () => {
    // This function will be used to reject user requested tag
    setShowLoader(true);
    try {
      // Reference to the specific document in the "Tags" collection
      const tagDocRef = doc(db, "tags", tag.id);
      // Update the document with status: pending
      await updateDoc(tagDocRef, {
        status: "pending",
      });
      setShowPendingTagModal(false);
      toast.success("Tag Pended");
      // await fetchTags();
      setShowLoader(false);
    } catch (error) {
      setShowPendingTagModal(false);
      toast.error("Error Auction Pending");
      setShowLoader(false);
    }
  };

  const handleDelete = (tag) => {
    // This function just show the modal to the user
    setShowDeleteTagModal(true);
    setTag(tag);
  };

  const deleteTagHandler = async () => {
    // This function will be used to deleted the tag
    setShowLoader(true);
    try {
      if (tag) {
        const auctionDocRef = doc(db, "tags", tag.id);
        await deleteDoc(auctionDocRef);
        toast.success("Tag successfully deleted!");
        setShowDeleteTagModal(false);
        // await fetchTags();
        setShowLoader(false);
      }
    } catch (error) {
      toast.error("Error deleting Tag");
      setShowDeleteTagModal(false);
      setShowLoader(false);
    }
  };

  const handleEdit = (tag) => {
    // This function just shows the edit form to the user
    setEditingTagId(tag.id);
    setIsEditing(true);
    methods.setValue(editTagValidation.name, tag.name);
  };

  const handleTrash = (auction) => {
    // This function is used to show modal only
    setShowTrashModal(true);
    setTag(auction);
  };

  const trashTagHandler = async () => {
    // This function is used to trash  user auction
    setShowLoader(true);
    try {
      // Reference to the specific document in the "Tags" collection
      const tagDocRef = doc(db, "tags", tag.id);
      // Update the document with status: trashed
      await updateDoc(tagDocRef, {
        status: "trashed",
      });
      toast.success("Tag Moved To Trash");
      setShowTrashModal(false);
      // await fetchTags();
      setShowLoader(false);
    } catch (error) {
      setShowTrashModal(false);
      toast.error("Error Auction Moving to Trash");
      setShowLoader(false);
    }
  };

  const submitEditHandler = methods.handleSubmit(async (formData) => {
    // This function will be used to edit the tag in the database

    const tagName = formData.editTagInput;

    if (!tagName) {
      // Handle empty or undefined tagName

      toast.error("Tag name cannot be empty");
      return;
    }
    setShowLoader(true);
    try {
      const tagDocRef = doc(db, "tags", editingTagId);
      await updateDoc(tagDocRef, { name: tagName });

      toast.success("Tag updated successfully!");
      // await fetchTags();
      setIsEditing(false);
      setShowLoader(false);
    } catch (error) {
      setIsEditing(false);
      setShowLoader(false);
      toast.error("Error updating Tag");
    }
  });

  // const createTagHandler = methods.handleSubmit(async (formData) => {
  //   // This function will be used to create tag in database
  //   console.log(formData);
  //   const user = auth.currentUser;
  //   setShowLoader(true);
  //   try {
  //     // Reference to the "Auction" collection
  //     const tagsColRef = collection(db, "tags");
  //     // Create a new document with the entire formData
  //     const docRef = await addDoc(tagsColRef, {
  //       created: serverTimestamp(),
  //       name: formData.tagInput.toUpperCase(),
  //       tagComment: formData.commentTag,
  //       uid: user.uid,
  //       status: "approved",
  //     });
  //     toast.success("Tag Created By Admin");
  //     setShowCreateFormModal(false);
  //     methods.reset();
  //     await fetchTags();
  //     setShowLoader(false);
  //   } catch (error) {
  //     setShowLoader(false);
  //     setShowCreateFormModal(false);
  //     toast.error("An Error Occurred During Creating Your Tag");
  //   }
  // });

  const createTagHandler = methods.handleSubmit(async (formData) => {
    setShowLoader(true);
    try {
      const existingTagsQuery = query(
        collection(db, "tags"),
        where("name", "==", formData.tagInput.toUpperCase())
      );

      const existingTagsSnapshot = await getDocs(existingTagsQuery);

      if (!existingTagsSnapshot.empty) {
        // Tag already exists, inform the user and prevent submission
        toast.error("Tag already exists. Please try a different tag.");
        setShowLoader(false);
        return;
      }

      // If tag doesn't exist, proceed with submitting the new tag
      const tagsColRef = collection(db, "tags");

      const user = auth.currentUser;
      const docRef = await addDoc(tagsColRef, {
        created: serverTimestamp(),
        name: formData.tagInput.toUpperCase(),
        tagComment: formData.commentTag,
        uid: user.uid,
        status: "approved",
      });

      toast.success("Tag Created By Admin");
      methods.reset();
      setShowCreateFormModal(false);
      // await fetchTags();
      setShowLoader(false);
    } catch (error) {
      methods.reset();
      setShowLoader(true);
      toast.error("An Error Occurred During Submitting Your Tag");
    }
  });

  const actionMenuNotApproved = [
    adminActions.canApprove && {
      onClick: handleApprove,
      icon: faCheckCircle,
      color: "#57cc99",
      text: "Approve",
    },
    adminActions.canTrash && {
      onClick: handleTrash,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Trash",
    },
  ].filter(Boolean);

  const actionMenu = [
    adminActions.canEdit && {
      onClick: handleEdit,
      icon: faPenAlt,
      color: "#ff9e00",
      text: "Edit",
    },
    adminActions.canTrash && {
      onClick: handleTrash,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Trash",
    },
  ].filter(Boolean);

  const actionMenuTrashed = [
    adminActions.canApprove && {
      onClick: handleApprove,
      icon: faCheckCircle,
      color: "#57cc99",
      text: "Approve",
    },
    adminActions.canPending && {
      onClick: handlePending,
      icon: faClock,
      color: "#ff9e00",
      text: "Pending",
    },
    adminActions.canDelete && {
      onClick: handleDelete,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Delete",
    },
  ].filter(Boolean);

  const getActionMenu = (status) => {
    switch (status) {
      case "approved":
        return actionMenu;
      case "pending":
        return actionMenuNotApproved;
      case "trashed":
        return actionMenuTrashed;
      default:
        return [];
    }
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();

    // Check if the searchQuery is empty
    if (searchQuery.trim() === "") {
      handleTabChange(activeTab);
      setCurrent(1);
    } else {
      // Filter tags based on the searchQuery
      const filteredTags = tagsFromFirebase.filter((tag) =>
        tag.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setDisplayTags(filteredTags);
      setCurrent(1);
    }

    // Check for backspace key press (keyCode 8)
    if (event.keyCode === 8 && searchQuery === "") {
      handleTabChange(activeTab);
      setCurrent(1);
    }
  };

  if (!tagsFromFirebase) {
    return <Loading />;
  }

  return (
    <div>
      {showLoader && <Loading />}
      <h2 className="headi">Tags Management</h2>
      <div
        className="create-new-auction"
        // style={{ marginLeft: "10px" }}
      >
        {adminActions.canCreate && (
          <IconBtn
            onClick={() => {
              // setActiveIcon(faGavel);
              setShowCreateFormModal(true);
            }}
            iconName={faTags}
            activeIcon={activeIcon}
          >
            <div
              className="icon-div-con"
              style={{ backgroundColor: "#e2cfea" }}
            >
              <FontAwesomeIcon icon={faTags} color="purple" />
            </div>
            <span>Create</span>
          </IconBtn>
        )}
        <IconBtn
          onClick={() => {
            setActiveIcon(faList);
            // setShowCreateFormModal(true);
            handleTabChange("All");
          }}
          iconName={faList}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#e2cfea" }}>
            <FontAwesomeIcon icon={faList} color="purple" />
          </div>
          <span>All</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faCheckCircle);
            handleTabChange("Approved");
          }}
          iconName={faCheckCircle}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#c7f9cc" }}
          >
            <FontAwesomeIcon icon={faCheckCircle} color="#25a18e" />
          </div>
          <span>Approved</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faClock);
            handleTabChange("Pending");
          }}
          iconName={faClock}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#fff2b2" }}>
            <FontAwesomeIcon icon={faClock} color="#ddd92a" />
          </div>
          <span>Pending</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faTrashAlt);
            handleTabChange("Trashed");
          }}
          iconName={faTrashAlt}
          activeIcon={activeIcon}
        >
          <div
            className="icon-div-con"
            style={{ backgroundColor: "#e01e386b" }}
          >
            <FontAwesomeIcon icon={faTrashAlt} color="#d90429" />
          </div>
          <span>Trash</span>
        </IconBtn>
      </div>
      <div style={{ width: "98%", margin: "0 auto" }}>
        <input
          type="text"
          className="user-search-field"
          onChange={handleSearch}
          placeholder="Search Tags By Name"
        />
      </div>
      <ul className="tagsList-tagMa">
        {getData(current, size).length === 0 ? (
          <p>No items available</p>
        ) : (
          getData(current, size).map((tag, index) => (
            // <li className="li">
            <div className="div" key={tag.id}>
              {isEditing && editingTagId === tag.id ? (
                <FormProvider {...methods}>
                  <form
                    className="tagFlexCont"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <span className="a">current value: {tag.name}</span>
                    <div className="flex">
                      <input
                        type={editTagValidation.type}
                        id={editTagValidation.id}
                        name={editTagValidation.name}
                        // defaultValue={tag.name}
                        placeholder={`Edit Tag`}
                        {...methods.register(
                          editTagValidation.name,
                          editTagValidation.validationRules
                        )}
                      />
                      {inputErrors && (
                        <div className="ier">{inputErrors?.message}</div>
                      )}

                      <div className="fl">
                        <button className="s" onClick={submitEditHandler}>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            color="#57cc99"
                          />
                          Save
                        </button>
                        <button
                          className="c"
                          onClick={() => setIsEditing(false)}
                        >
                          <FontAwesomeIcon icon={faClose} color="#ef233c" />
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              ) : (
                <li
                  className="li"
                  onClick={() => {
                    setTag(tag);
                    setShowTagDetailsModal(true);
                  }}
                >
                  <h2>{tag.name}</h2>
                  <span
                    className={`gItem aspn ${tag.status.toLowerCase()}Status`}
                  >
                    {tag.status}
                  </span>
                  <span className="gItem">
                    <MoreMenu
                      actionMenu={getActionMenu(tag.status)}
                      tag={tag}
                    />
                  </span>
                </li>
              )}
            </div>
            // </li>
          ))
        )}
      </ul>
      <div className="pagination">
        <Pagination
          showTotal={(total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total}`
          }
          onChange={PaginationChange}
          total={displayTags.length}
          current={current}
          pageSize={size}
          showSizeChanger={false}
          showPrevNextJumpers={true}
          jumpPrevIcon={"Prev 5"}
          jumpNextIcon={"Next 5"}
          showTitle={false}
        />
      </div>
      {showCreateFormModal && (
        <>
          <Overlay onClose={() => setShowCreateFormModal(false)} />
          <Modal
            onCancel={() => {
              setShowCreateFormModal(false);
              methods.setValue(editTagValidation.name, "");
            }}
            successText={"Create Tag"}
            cancelText={"Cancel"}
            onAgree={createTagHandler}
          >
            <h2>Create tag</h2>
            <FormProvider {...methods}>
              <TagsCreationForm methods={methods} />
              {/* <form
                className="auction-from"
                onSubmit={(e) => e.preventDefault()}
                {/* {renderInput(tagValidation, methods)} 
              >
            </form> */}
            </FormProvider>
          </Modal>
        </>
      )}
      {showDeleteTagModal && (
        <>
          <Overlay onClose={() => setShowDeleteTagModal(false)} />
          <Modal
            onCancel={() => {
              setShowDeleteTagModal(false);
            }}
            successText={"Delete Tag"}
            cancelText={"Cancel"}
            onAgree={deleteTagHandler}
          >
            <h2>Delete tag</h2>
            <p>
              Are you sure you want to delete{" "}
              <span style={{ fontWeight: "bold" }}>{tag.name}</span> tag
            </p>
          </Modal>
        </>
      )}
      {showApproveTagModal && (
        <>
          <Overlay onClose={() => setShowApproveTagModal(false)} />
          <Modal
            onCancel={() => {
              setShowApproveTagModal(false);
            }}
            successText={"Approve Tag"}
            cancelText={"Cancel"}
            onAgree={approveTagHandler}
          >
            <h2>Approve tag</h2>
            <p>
              Are you sure you want to approve
              <span style={{ fontWeight: "bold" }}> {tag.name} </span>
              tag
            </p>
          </Modal>
        </>
      )}

      {showTrashModal && (
        <>
          <Overlay onClose={() => setShowTrashModal(false)} />
          <Modal
            onCancel={() => {
              setShowTrashModal(false);
            }}
            successText={"Move to Trash"}
            cancelText={"Cancel"}
            onAgree={trashTagHandler}
          >
            <h2>Move To Trash</h2>
            <p>
              Are you sure you want to move this item
              <span style={{ fontWeight: "bold" }}> {tag.name}</span> to trash
            </p>
          </Modal>
        </>
      )}

      {showPendingTagModal && (
        <>
          <Overlay onClose={() => setShowPendingTagModal(false)} />
          <Modal
            onCancel={() => {
              setShowPendingTagModal(false);
            }}
            successText={"Pending Tag"}
            cancelText={"Cancel"}
            onAgree={pendingTagHandler}
          >
            <h2>Pending tag</h2>
            <p>
              Are you sure you want to add this
              <span style={{ fontWeight: "bold" }}> {tag.name} </span>tag to
              Pending
            </p>
          </Modal>
        </>
      )}

      {showTagDetailsModal && (
        <TagDetailModal
          tag={tag}
          onClose={() => setShowTagDetailsModal(false)}
        />
      )}
    </div>
  );
};

export default TagsManagement;

// const tagsFromFirebase = [
//   { id: 1, name: "JavaScript", approved: false },
//   { id: 2, name: "React", approved: true },
//   { id: 3, name: "CSS", approved: true },
//   { id: 4, name: "HTML", approved: true },
//   { id: 5, name: "Node.js", approved: true },
//   { id: 6, name: "Python", approved: false },
//   { id: 7, name: "Java", approved: false },
//   { id: 8, name: "TypeScript", approved: true },
//   { id: 9, name: "Angular", approved: false },
//   { id: 10, name: "Vue.js", approved: true },
//   { id: 11, name: "Sass", approved: true },
//   { id: 12, name: "Bootstrap", approved: true },
//   { id: 13, name: "MongoDB", approved: true },
//   { id: 14, name: "Express.js", approved: false },
//   { id: 15, name: "Django", approved: true },
//   { id: 16, name: "Spring", approved: false },
//   { id: 17, name: "C++", approved: false },
//   { id: 18, name: "Ruby", approved: true },
//   { id: 19, name: "PHP", approved: true },
//   { id: 20, name: "WordPress", approved: true },
//   { id: 21, name: "Laravel", approved: false },
//   { id: 22, name: "Ruby on Rails", approved: true },
//   { id: 23, name: "Swift", approved: false },
//   { id: 24, name: "Kotlin", approved: false },
//   { id: 25, name: "TensorFlow", approved: true },
//   { id: 26, name: "PyTorch", approved: true },
//   { id: 27, name: "Machine Learning", approved: true },
//   { id: 28, name: "Artificial Intelligence", approved: false },
//   { id: 29, name: "Blockchain", approved: true },
//   { id: 30, name: "Cloud Computing", approved: false },
// ];
