import React, { useEffect } from "react";
import "quill/dist/quill.snow.css";
import { useFormContext } from "react-hook-form";
import { useQuill } from "react-quilljs";

import Bold from "quill/formats/bold";
import Italic from "quill/formats/italic";
import Underline from "quill/formats/underline";
import Strike from "quill/formats/strike";
import List from "quill/formats/list";
import Indent from "quill/formats/indent";
import Header from "quill/formats/header";
import Link from "quill/formats/link";
import Image from "quill/formats/image";
import Video from "quill/formats/video";
import Quill from "quill";

Quill.register({
  "formats/bold": Bold,
  "formats/italic": Italic,
  "formats/underline": Underline,
  "formats/strike": Strike,
  "formats/list": List,
  "formats/indent": Indent,
  "formats/header": Header,
  "formats/link": Link,
  "formats/image": Image,
  "formats/video": Video,
});

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "list",
  "indent",
  "size",
  "header",
  "link",
  "image",
  "video",
  "color",
  "background",
];

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    // [{ align: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const TextEditor = ({ name, defaultValue }) => {
  const { setValue, trigger, register } = useFormContext();
  // const [range, setRange] = useState();
  // const [lastChange, setLastChange] = useState();
  // const [readOnly, setReadOnly] = useState(false);

  // Use a ref to access the quill instance directly
  useEffect(() => {
    register(name, { required: "Description is required" });
  }, [name]);

  const { quill, quillRef } = useQuill({ formats, modules });

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        const editorContentHtml = quillRef.current.firstChild.innerHTML;
        setValue(name, editorContentHtml);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (quill && defaultValue) {
      quill.clipboard.dangerouslyPasteHTML(defaultValue);
    }
  }, [quill, defaultValue]);

  return (
    <div>
      <div ref={quillRef} />
    </div>
  );
};

export default TextEditor;
