
export const options = [
  { label: "For Login" },
  { label: "For Sign Up" },
  { label: "For Auction Accept" },
  { label: "For Auction Request" },
  { label: "For Auction Reject" },
  { label: "For Auction Won" },
  { label: "For Tag Request" },
  { label: "For Tag Reject" },
  { label: "For Seller Accept" },
  { label: "For Seller Request" },
  { label: "For Seller Reject" },
  { label: "For Seller Suspend" },
  { label: "Payment Reminder" },
  { label: "Pay Now" },
  { label: "For Credit Added Success" },
  { label: "For Credit Deleted Success" },
  { label: "For Seller Onboarded Success" },
  { label: "For Payment Success" },
  { label: "Seller Item Sold" },
  { label: "Auction Result Notification" },
  { label: "For First Bid Of User" },
  { label: "For User Bid" },
  { label: "For User Out Bid" },
  { label: "For 12 Hours left" },
  { label: "For 6 Hours left" },
  { label: "For 1 Hours left" },
];
