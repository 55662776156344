import { useState } from "react";
import {
  smsMessageItValidation,
  smsMessageValidation,
  userProfileMSGValidation,
} from "../../utils/inputValidations";
import { renderInput } from "../userAuctionsDashboard/AuctionForm";
import DropdownSelect from "../DropdownSelect";
import { FormProvider, useForm } from "react-hook-form";
import { options } from "../../utils/notificationsLabels";

const ComposeSMS = ({ onClose, onSubmit, defaultValues }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const methods = useForm({ defaultValues });

  const submitHandler = methods.handleSubmit((formData) => {
    onSubmit(formData, selectedOption);
  });
  return (
    <FormProvider {...methods}>
      <div className="formModal-content">
        <h2> {defaultValues.keys ? "SMS Template" : "SMS Template"}</h2>

        <button onClick={onClose} className="cancel-auction-creation">
          Close
        </button>
      </div>
      <div>
        <form className="auction-from" onSubmit={(e) => e.preventDefault()}>
          {renderInput(smsMessageValidation, methods)}
          {renderInput(userProfileMSGValidation, methods)}
          <DropdownSelect
            setOptions={setSelectedOption}
            options={options}
            heading={"Select Purpose"}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          {renderInput(smsMessageItValidation, methods)}
        </form>
      </div>
      <div className="auction-form-buttons" style={{ marginRight: "2.5rem" }}>
        <button onClick={submitHandler} className="submit-auction">
          Save
        </button>
      </div>
    </FormProvider>
  );
};

export default ComposeSMS;
