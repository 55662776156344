import React, { useEffect, useState } from "react";
import Pagination from "rc-pagination";
// import { dummyUsers } from "../../utils/dummyUsers";
import "../../styles/userlistStyles.css";
import MoreMenu from "./ActionMenu";
import UserDetailsModal from "./UserDeatilsModal";
import {
  faCheckCircle,
  faClock,
  faTimesCircle,
  faUser,
  faStore,
  faPauseCircle,
  faTrashAlt,
  faClose,
  faPenAlt,
  faPenFancy,
} from "@fortawesome/free-solid-svg-icons";
import IconBtn from "../userAuctionsDashboard/IconBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebaseSetup";
import Loading from "../Loader";
import { toast } from "react-toastify";
import Overlay from "../Overlay";
import Modal from "../Modal";
import fetchAndSendEmailTemplate, {
  fetchAndSendEmailReasonTemplate,
} from "../../utils/emailSend";
import { FormProvider, useForm } from "react-hook-form";
import {
  rejectReasonValidation,
  sellerComissionValidation,
  userComissionValidation,
} from "../../utils/inputValidations";
import { renderInput } from "../userAuctionsDashboard/AuctionForm";

const UsersList = ({ users, admin, auctions }) => {
  const [displayedUsers, setDisplayedUsers] = useState(users);
  const [userDetails, setUserDetails] = useState(null);
  const [current, setCurrent] = useState(1);
  const [size, setSize] = useState(10);
  const [activeIcon, setActiveIcon] = useState(faUser);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("normal");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectReasonModal, setRejectReasonModal] = useState(false);
  const adminActions = admin.usersActions;
  const [showUserCommission, setShowUserCommission] = useState(false);
  const [showSellerCommission, setShowSellerCommission] = useState(false);

  const methods = useForm();

  useEffect(() => {
    if (users) {
      setDisplayedUsers(users);
      handleTabChange(activeTab);
    }
  }, [users]);

  useEffect(() => {
    if (showUserDetails) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  }, [showUserDetails]);

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    // Check if the searchQuery is empty
    if (searchQuery.trim() === "") {
      handleTabChange(activeTab);
      setCurrent(1);
    } else {
      // Filter users based on the searchQuery
      const filteredUsers = users.filter(
        (user) =>
          user?.email?.toLowerCase().includes(searchQuery) ||
          user?.firstName?.toLowerCase().includes(searchQuery) ||
          user?.lastName?.toLowerCase().includes(searchQuery) ||
          user?.nickname?.toLowerCase().includes(searchQuery)
      );
      setDisplayedUsers(filteredUsers);
      setCurrent(1);
    }

    // Check for backspace key press (keyCode 8)
    if (event.keyCode === 8 && searchQuery === "") {
      handleTabChange(activeTab);
      setCurrent(1);
    }
  };

  const getData = (current, pageSize) => {
    return displayedUsers.slice((current - 1) * pageSize, current * pageSize);
  };

  const PaginationChange = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const handleApprove = (user) => {
    // This function is used to show modal only
    setShowApproveModal(true);
    setUser(user);
  };

  // show reject modal
  const handleReject = (user) => {
    // This function is used to show modal only
    setShowRejectModal(true);
    setUser(user);
  };

  // Show seller Commission model to user
  const handleSellerCommission = (user) => {
    setUser(user);
    setShowSellerCommission(true);
    methods.setValue(userComissionValidation.name, user.commissionPercent);
    methods.setValue(
      sellerComissionValidation.name,
      user.sellerCommissionPercent
    );
  };

  // Show normal user Commission model to user
  const handleUserCommission = (user) => {
    setUser(user);
    methods.setValue(userComissionValidation.name, user.commissionPercent);
    methods.setValue(
      sellerComissionValidation.name,
      user.sellerCommissionPercent
    );
    setShowUserCommission(true);
  };

  const approveHandler = async () => {
    // This function is used to approve user
    setShowLoader(true);
    try {
      // Reference to the specific document in the "users" collection
      const userDocRef = doc(db, "users", user.uid);
      // Update the document with isApproved: true
      await updateDoc(userDocRef, {
        status: "seller",
        isASeller: true,
      });

      setShowApproveModal(false);
      toast.success("User Approved As Seller");

      // await getUsers();

      const updatedUserSnapshot = await getDoc(userDocRef);
      const updatedUserData = updatedUserSnapshot.data();
      const updatedUserWithId = {
        ...updatedUserData,
        id: updatedUserSnapshot.id,
      };

      setUserDetails(updatedUserWithId);
      setShowLoader(false);

      const userInfo = {
        email: updatedUserWithId.email,
        uid: updatedUserWithId.uid,
      };

      fetchAndSendEmailTemplate(
        "For Seller Accept",
        userInfo,
        "https://stripe-server-production-3c2c.up.railway.app/send-email"
      );
      // console.log("Approved");
    } catch (error) {
      toast.error("Error Approving User");
      setShowLoader(false);
    }
  };

  const handleSuspend = (user) => {
    // This function is used to show modal only
    setShowSuspendModal(true);
    setUser(user);
  };

  const suspendHandler = async () => {
    // This function is used to approve user
    setShowLoader(true);
    try {
      // Reference to the specific document in the "users" collection
      const userDocRef = doc(db, "users", user.uid);
      // Update the document with isApproved: true
      await updateDoc(userDocRef, {
        status: "suspended",
        isASeller: false,
      });
      setShowSuspendModal(false);
      toast.success("User Suspended As Seller");
      // await getUsers();
      const updatedUserSnapshot = await getDoc(userDocRef);
      const updatedUserData = updatedUserSnapshot.data();
      const updatedUserWithId = {
        ...updatedUserData,
        id: updatedUserSnapshot.id,
      };

      setUserDetails(updatedUserWithId);
      setShowLoader(false);

      const userInfo = {
        email: updatedUserWithId.email,
        uid: updatedUserWithId.uid,
      };

      fetchAndSendEmailTemplate(
        "For Seller Suspend",
        userInfo,
        "https://stripe-server-production-3c2c.up.railway.app/send-email"
      );

      // console.log("Approved");
    } catch (error) {
      toast.error("Error Suspending User");
      setShowLoader(false);
    }
  };

  const handleDelete = (user) => {
    // This function just show the modal to the user
    setShowDeleteUserModal(true);
    setUser(user);
  };

  const deleteUserHandler = async () => {
    // This function will be used to deleted the user
    setShowLoader(true);
    try {
      if (user) {
        // const userToDelete = auth.currentUser;

        const userDocRef = doc(db, "users", user.uid);
        await deleteDoc(userDocRef);

        // await getUsers();
        setShowDeleteUserModal(false);
        if (showUserDetails) {
          setShowUserDetails(false);
        }
        setShowLoader(false);
        toast.success("User successfully deleted!");
      }
    } catch (error) {
      toast.error("Error deleting User");
      setShowDeleteUserModal(false);
      setShowLoader(false);
      console.log(error);
    }
  };

  // Rejetc user with reason
  const rejectReasonHandler = methods.handleSubmit((formData) => {
    rejectUserHandler(formData.rejectReason);
  });

  const rejectUserHandler = async (rejectReason) => {
    // This function is used to approve user
    setShowLoader(true);
    try {
      // Reference to the specific document in the "users" collection
      const userDocRef = doc(db, "users", user.uid);
      // Update the document with isASeller: true
      await updateDoc(userDocRef, {
        status: "rejected",
        isASeller: false,
        rejectReason,
      });
      setShowSuspendModal(false);
      toast.success("User Rejected As Seller");
      // await getUsers();
      const updatedUserSnapshot = await getDoc(userDocRef);
      const updatedUserData = updatedUserSnapshot.data();
      const updatedUserWithId = {
        ...updatedUserData,
        id: updatedUserSnapshot.id,
      };

      setUserDetails(updatedUserWithId);
      setShowLoader(false);
      setRejectReasonModal(false);
      setShowRejectModal(false);

      fetchAndSendEmailReasonTemplate(
        "For Seller Reject",
        user,
        "https://stripe-server-production-3c2c.up.railway.app/send-email",
        rejectReason
      );

      // console.log("Approved");
    } catch (error) {
      toast.error("Error Rejecting User");
      setShowLoader(false);
    }
  };

  // Edit or Add user commission
  const addUserCommission = methods.handleSubmit((formData) => {
    handleCommissionUpdate(
      parseInt(formData.userCommission),
      parseInt(formData.sellerCommission)
    );
  });

  // Firebase function to add user or seller or both commissions
  const handleCommissionUpdate = async (userCommission, sellerCommission) => {
    setShowLoader(true);
    try {
      // Reference to the specific document in the "users" collection
      const userDocRef = doc(db, "users", user.uid);
      if (userCommission && sellerCommission) {
        await updateDoc(userDocRef, {
          commissionPercent: userCommission,
          sellerCommissionPercent: sellerCommission,
        });
        toast.success("Added Commission for Seller");
        setShowSellerCommission(false);
        setShowUserCommission(false);
      } else {
        await updateDoc(userDocRef, {
          commissionPercent: userCommission,
        });
        toast.success("Added Commission for User");
        setShowUserCommission(false);
        setShowSellerCommission(false);
      }
      methods.reset();
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.log(error);
      toast.error("Error Adding Commission for Seller");
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    const filteredUsers = users.filter((user) => {
      switch (tab) {
        case "pending":
          return user.status?.toLowerCase() === "pending".toLowerCase();
        case "seller":
          return user.status?.toLowerCase() === "seller".toLowerCase();
        case "rejected":
          return user.status?.toLowerCase() === "rejected".toLowerCase();
        case "suspended":
          return user.status?.toLowerCase() === "suspended".toLowerCase();
        default:
          return user.status?.toLowerCase() === "normal".toLowerCase();
      }
    });
    // console.log(filteredUsers);
    setDisplayedUsers(filteredUsers);
  };

  const userMenu = [
    adminActions.canApprove && {
      onClick: handleUserCommission,
      icon: faPenAlt,
      color: "#ff9e00",
      text: "Commission",
    },
    adminActions.canDelete && {
      onClick: handleDelete,
      icon: faTimesCircle,
      color: "#ef233c",
      text: "Delete",
    },
  ].filter(Boolean);

  const userPending = [
    adminActions.canApprove && {
      onClick: handleApprove,
      icon: faCheckCircle,
      color: "#57cc99",
      text: "Approve",
    },
    adminActions.canApprove && {
      onClick: handleUserCommission,
      icon: faPenAlt,
      color: "#ff9e00",
      text: "Commission",
    },
    adminActions.canApprove && {
      onClick: handleReject,
      icon: faTimesCircle,
      color: "#ef233c",
      text: "Reject",
    },
    adminActions.canDelete && {
      onClick: handleDelete,
      icon: faTrashAlt,
      color: "#ef233c",
      text: "Delete",
    },
  ].filter(Boolean);

  const userSeller = [
    adminActions.canApprove && {
      onClick: handleSellerCommission,
      icon: faPenAlt,
      color: "#ff9e00",
      text: "Commission",
    },
    adminActions.canSuspend && {
      onClick: handleSuspend,
      icon: faPauseCircle,
      color: "#ff9e00",
      text: "Suspend",
    },
    adminActions.canDelete && {
      onClick: handleDelete,
      icon: faTimesCircle,
      color: "#ef233c",
      text: "Delete",
    },
  ].filter(Boolean);

  const getActionMenu = (status) => {
    switch (status) {
      case "normal":
        return userMenu;
      case "pending":
      case "suspended":
        return userPending;
      case "seller":
        return userSeller;
      default:
        return [];
    }
  };

  const auctionDetailsActions = (status) => {
    // console.log(status);
    switch (status) {
      case "normal":
      case "rejected":
        return (
          <div className="actions-btns">
            {adminActions.canDelete && (
              <button
                className="delete-action-btn"
                onClick={() => handleDelete(userDetails)}
              >
                Delete
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
            <button
              className="delete-action-btn"
              onClick={() => {
                setShowUserDetails(false);
                setUserDetails(null);
              }}
            >
              Close
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        );
      case "seller":
        return (
          <div className="actions-btns">
            {adminActions.canSuspend && (
              <button
                className="suspend-action-btn"
                onClick={() => handleSuspend(userDetails)}
              >
                Suspend
                <FontAwesomeIcon icon={faPauseCircle} />
              </button>
            )}
            {adminActions.canDelete && (
              <button
                className="delete-action-btn"
                onClick={() => handleDelete(userDetails)}
              >
                Delete
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
            <button
              className="delete-action-btn"
              onClick={() => {
                setShowUserDetails(false);
                setUserDetails(null);
              }}
            >
              Close
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        );
      case "pending":
        return (
          <div className="actions-btns">
            {adminActions.canApprove && (
              <button
                className="edit-action-btn"
                onClick={() => handleApprove(userDetails)}
              >
                Approve
                <FontAwesomeIcon icon={faCheckCircle} />
              </button>
            )}
            {adminActions.canSuspend && (
              <button
                className="approve-button suspended"
                onClick={() => handleSuspend(userDetails)}
              >
                Suspend
                <FontAwesomeIcon icon={faPauseCircle} />
              </button>
            )}
            {adminActions.canApprove && (
              <button
                className="delete-action-btn"
                onClick={() => handleReject(userDetails)}
              >
                Reject
                <FontAwesomeIcon icon={faTimesCircle} />
              </button>
            )}
            {adminActions.canDelete && (
              <button
                className="delete-action-btn"
                onClick={() => handleDelete(userDetails)}
              >
                Delete
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
            <button
              className="delete-action-btn"
              onClick={() => {
                setShowUserDetails(false);
                setUserDetails(null);
              }}
            >
              Close
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        );

      default:
        return (
          <button
            className="delete-action-btn"
            onClick={() => {
              setShowUserDetails(false);
              setUserDetails(null);
            }}
          >
            Close
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        );
    }
  };

  return (
    <div className="users-list">
      {showLoader && <Loading />}
      {/* <h2 className="">Users Management</h2> */}
      <div
        className="create-new-auction three-items"
        style={{ marginBottom: "1.5rem" }}
      >
        <IconBtn
          onClick={() => {
            setActiveIcon(faUser);
            // setShowCreateFormModal(true);
            handleTabChange("normal");
          }}
          iconName={faUser}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#e2cfea" }}>
            <FontAwesomeIcon icon={faUser} color="purple" />
          </div>
          <span>Normal Users</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faClock);
            handleTabChange("pending");
          }}
          iconName={faClock}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#fff2b2" }}>
            <FontAwesomeIcon icon={faClock} color="#ddd92a" />
          </div>
          <span>Pending</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faPauseCircle);
            handleTabChange("suspended");
          }}
          iconName={faPauseCircle}
          activeIcon={activeIcon}
        >
          <div className="icon-div-con" style={{ backgroundColor: "#fff2b2" }}>
            <FontAwesomeIcon icon={faPauseCircle} color="#ddd92a" />
          </div>
          <span>Suspended</span>
        </IconBtn>

        <IconBtn
          onClick={() => {
            setActiveIcon(faTimesCircle);
            handleTabChange("rejected");
          }}
          iconName={faTimesCircle}
          activeIcon={activeIcon}
        >
          <div
            className="icon-div-con"
            style={{ backgroundColor: "#e01e386b" }}
          >
            <FontAwesomeIcon icon={faTimesCircle} color="#d90429" />
          </div>
          <span>Rejected</span>
        </IconBtn>
        <IconBtn
          onClick={() => {
            setActiveIcon(faStore);
            handleTabChange("seller");
          }}
          iconName={faStore}
          activeIcon={activeIcon}
        >
          <div
            className={`icon-div-con`}
            style={{ backgroundColor: "#c7f9cc" }}
          >
            <FontAwesomeIcon icon={faStore} color="#25a18e" />
          </div>
          <span>Sellers</span>
        </IconBtn>
      </div>
      <h2 className="user-h3">Users({displayedUsers.length})</h2>
      <input
        type="text"
        className="user-search-field"
        onChange={handleSearch}
        placeholder="Search Users By Real or System Name Email"
      />

      <div className="container">
        <table className="responsive-table">
          <thead className="responsive-table__head">
            <tr className="responsive-table__row">
              <th className="responsive-table__head__title responsive-table__head__title--name">
                Nick Name
              </th>
              <th className="responsive-table__head__title responsive-table__head__title--name">
                Full Name
              </th>
              <th className="responsive-table__head__title responsive-table__head__title--status">
                Status
              </th>
              <th className="responsive-table__head__title responsive-table__head__title--types">
                Email
              </th>
              {/* <th className="responsive-table__head__title responsive-table__head__title--update">
                Something
              </th> */}
              <th className="responsive-table__head__title responsive-table__head__title--country grid-item-users">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="responsive-table__body">
            {getData(current, size).map((user, index) => (
              <tr
                onClick={() => {
                  setShowUserDetails(true);
                  setUserDetails(user);
                }}
                className="responsive-table__row"
                key={index}
              >
                <td className="responsive-table__body__text responsive-table__body__text--name">
                  {/* <img src={user.image} alt="" width={"50px"} /> */}
                  <h2 style={{ marginLeft: "6px" }}>
                    {user.nickname ? user.nickname : "No nickname"}
                  </h2>
                </td>
                <td className="responsive-table__body__text responsive-table__body__text--name">
                  {/* <img src={user.image} alt="" width={"50px"} /> */}
                  <h2 style={{ marginLeft: "6px" }}>
                    {user.firstName ? (
                      <span>{`${user.firstName} ${user.lastName}`}</span>
                    ) : (
                      "No full name"
                    )}
                  </h2>
                </td>
                <td
                  className={`responsive-table__body__text responsive-table__body__text--status ${user.status.toLowerCase()}-user`}
                >
                  {user.status}
                </td>
                <td className="responsive-table__body__text responsive-table__body__text--types">
                  {user.email}
                </td>
                {/* <td className="responsive-table__body__text responsive-table__body__text--update">
                  SOmething else
                </td> */}
                <td className="responsive-table__body__text responsive-table__body__text--country grid-item-users">
                  <MoreMenu
                    actionMenu={getActionMenu(user.status)}
                    tag={user}
                    className={"extra-class"}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showUserDetails && (
        <UserDetailsModal
          user={userDetails}
          onClose={() => {
            setShowUserDetails(false);
            setUserDetails(null);
          }}
          btns={auctionDetailsActions(userDetails.status)}
          auctions={auctions}
        />
      )}
      <div className="pagination">
        <Pagination
          showTotal={(total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total}`
          }
          onChange={PaginationChange}
          total={displayedUsers.length}
          current={current}
          pageSize={size}
          showSizeChanger={false}
          showPrevNextJumpers={true}
          jumpPrevIcon={"Prev 5"}
          jumpNextIcon={"Next 5"}
          showTitle={false}
        />
      </div>
      {showSuspendModal && (
        <>
          <Overlay onClose={() => setShowSuspendModal(false)} />
          <Modal
            onCancel={() => {
              setShowSuspendModal(false);
            }}
            successText={"Suspend User"}
            cancelText={"Cancel"}
            onAgree={suspendHandler}
          >
            <h2>Suspend User</h2>
            <p>
              Are you sure you want to Suspend{" "}
              <span style={{ fontWeight: "bold" }}>{user?.email}</span> user
            </p>
          </Modal>
        </>
      )}
      {showApproveModal && (
        <>
          <Overlay onClose={() => setShowApproveModal(false)} />
          <Modal
            onCancel={() => {
              setShowApproveModal(false);
            }}
            successText={"Approve user as seller"}
            cancelText={"Cancel"}
            onAgree={approveHandler}
          >
            <h2>Approve User</h2>
            <p>
              Are you sure you want to approve
              <span style={{ fontWeight: "bold" }}> {user.email} </span>
              user
            </p>
          </Modal>
        </>
      )}
      {showDeleteUserModal && (
        <>
          <Overlay onClose={() => setShowDeleteUserModal(false)} />
          <Modal
            onCancel={() => {
              setShowDeleteUserModal(false);
            }}
            successText={"Delete user"}
            cancelText={"Cancel"}
            onAgree={deleteUserHandler}
          >
            <h2>Delete User</h2>
            <p>
              Are you sure you want to delete?{" "}
              <span style={{ fontWeight: "bold" }}>{user.email}</span> user
            </p>
          </Modal>
        </>
      )}

      {showRejectModal && (
        <>
          <Overlay onClose={() => setShowRejectModal(false)} />
          <Modal
            onCancel={() => {
              setShowRejectModal(false);
            }}
            successText={"Reject User"}
            cancelText={"Cancel"}
            onAgree={() => setRejectReasonModal(true)}
          >
            <h2>Reject User</h2>
            <p>
              Are you sure you want to reject
              <span style={{ fontWeight: "bold" }}> {user.email} </span>
              auction
            </p>
          </Modal>
        </>
      )}

      {rejectReasonModal && (
        <>
          <Overlay onClose={() => setRejectReasonModal(false)} />
          <Modal
            onCancel={() => {
              setRejectReasonModal(false);
              methods.setValue(rejectReasonValidation.name, "");
            }}
            successText={"Reject Reason"}
            cancelText={"Cancel"}
            onAgree={rejectReasonHandler}
          >
            <h2>Reject Reason</h2>
            <FormProvider {...methods}>
              <form
                className="auction-from"
                onSubmit={(e) => e.preventDefault()}
              >
                {renderInput(rejectReasonValidation, methods)}
              </form>
            </FormProvider>
          </Modal>
        </>
      )}

      {showUserCommission && (
        <>
          <Overlay onClose={() => setShowUserCommission(false)} />
          <Modal
            onCancel={() => {
              setShowUserCommission(false);
              methods.setValue(userComissionValidation.name, "");
            }}
            successText={"Add Commission"}
            cancelText={"Cancel"}
            onAgree={addUserCommission}
          >
            <h2>Commission</h2>
            <FormProvider {...methods}>
              <form
                className="auction-from"
                onSubmit={(e) => e.preventDefault()}
              >
                {renderInput(userComissionValidation, methods)}
                {/* {renderInput(comissionValidation, methods)} */}
              </form>
            </FormProvider>
          </Modal>
        </>
      )}
      {showSellerCommission && (
        <>
          <Overlay onClose={() => setShowSellerCommission(false)} />
          <Modal
            onCancel={() => {
              setShowSellerCommission(false);
              methods.setValue(sellerComissionValidation.name, "");
            }}
            successText={"Add Commission"}
            cancelText={"Cancel"}
            onAgree={addUserCommission}
          >
            <h2>Commission</h2>
            <FormProvider {...methods}>
              <form
                className="auction-from"
                onSubmit={(e) => e.preventDefault()}
              >
                {renderInput(userComissionValidation, methods)}
                {renderInput(sellerComissionValidation, methods)}
                {/* {renderInput(comissionValidation, methods)} */}
              </form>
            </FormProvider>
          </Modal>
        </>
      )}
    </div>
  );
};

export default UsersList;
