import Filter from "./AuctionFilterTabs";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../config/firebaseSetup";
import "../../styles/auctionItemAdminStyles.css";
import { useCallback, useEffect, useState } from "react";
import Loading from "../Loader";
import { toast } from "react-toastify";
import Dropdown from "../DropDownFilter";

const filters = [
  { key: "serverEndTimeForAuction", label: "By Ending Date (desc)" },
  { key: "serverEndTimeForAuction", label: "By Ending Date (asc)" },
  { key: "created", label: "By Creation Time (desc)" },
  { key: "created", label: "By Creation Time(asc)" },
];

const AuctionManagement = () => {
  const [auctions, setAuctions] = useState(null);
  const [userData, setUser] = useState(null);
  const [selectedText, setSelectedText] = useState(filters[0]);

  const user = auth.currentUser;
  const getUser = async () => {
    try {
      const userDocRef = doc(db, "admins", user.uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        setUser(docSnap.data());
      } else {
        setUser(null);
      }
    } catch (error) {
      setUser(null);
    }
  };

  // const getAllAuctions = async () => {
  //   try {
  //     // Reference to the "Auction" collection
  //     const auctionsColRef = collection(db, "Auction");
  //     const statusFilteredAuctions = query(
  //       auctionsColRef,
  //       where("status", "!=", "draft")
  //     );

  //     const querySnapshot = await getDocs(statusFilteredAuctions);

  //     const auctionsData = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     setAuctions(auctionsData);
  //   } catch (error) {
  //     console.error("Error getting documents: ", error);
  //     throw error;
  //   }
  // };

  // const getAllAuctions = () => {
  //   try {
  //     const auctionsColRef = collection(db, "Auction");
  //     const statusFilteredAuctions = query(
  //       auctionsColRef,
  //       where("status", "!=", "draft"),
  //       orderBy(selectedText, "asc")
  //       // orderBy("serverEndTimeForAuction", "asc")
  //       // orderBy("serverEndTimeForAuction", "desc")
  //     );
  //     // Subscribe to real-time updates
  //     const unsubscribe = onSnapshot(
  //       statusFilteredAuctions,
  //       (querySnapshot) => {
  //         const auctionsData = querySnapshot.docs.map((doc) => ({
  //           id: doc.id,
  //           ...doc.data(),
  //         }));
  //         setAuctions(auctionsData);
  //       }
  //     );
  //     return unsubscribe;
  //   } catch (error) {
  //     console.error("Error getting documents: ", error);
  //     // throw error;
  //     toast.error("Error getting auctions");
  //   }
  // };
  const getAllAuctions = useCallback(() => {
    try {
      const auctionsColRef = collection(db, "Auction");
      let statusFilteredAuctions;

      if (selectedText.label === "By Creation Time (desc)") {
        statusFilteredAuctions = query(
          auctionsColRef,
          where("status", "!=", "draft"),
          orderBy(selectedText.key, "desc")
        );
      } else if (selectedText.label === "By Creation Time(asc)") {
        statusFilteredAuctions = query(
          auctionsColRef,
          where("status", "!=", "draft"),
          orderBy(selectedText.key, "asc")
        );
      } else if (selectedText.label === "By Ending Date (desc)") {
        statusFilteredAuctions = query(
          auctionsColRef,
          where("status", "!=", "draft"),
          orderBy(selectedText.key, "desc")
        );
      } else {
        statusFilteredAuctions = query(
          auctionsColRef,
          where("status", "!=", "draft"),
          orderBy(selectedText.key, "asc")
        );
      }

      const unsubscribe = onSnapshot(
        statusFilteredAuctions,
        (querySnapshot) => {
          const auctionsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAuctions(auctionsData);
        }
      );

      return unsubscribe;
    } catch (error) {
      console.error("Error getting documents: ", error);
      toast.error("Error getting auctions");
    }
  }, [selectedText]);

  useEffect(() => {
    // Start real-time updates and get the unsubscribe function
    const unsubscribe = getAllAuctions();

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [getAllAuctions]);

  useEffect(() => {
    getUser();
  }, [user]);

  if (!auctions) {
    return <Loading />;
  }
  if (!userData) {
    return <Loading />;
  }
  return (
    <div>
      <Filter
        user={userData}
        auctions={auctions}
        selectedText={selectedText}
        setSelectedText={setSelectedText}
        items={filters}
        // getAllAuctions={getAllAuctions}
      />
    </div>
  );
};

export default AuctionManagement;
